import React from "react";
import type { ChipProps } from "@mui/material";
import { Chip, Skeleton, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import invariant from "invariant";
import prettyMilliseconds from "pretty-ms";
import type { Log } from "../../../../../../../services/datastore";
import type { Group } from "../../../../../../../services/datastore";
import type { DerivedIngestionStatus } from "../../../../../../../utils/logs";
import {
  calculateLogBoundsInMs,
  deriveIngestionStatus,
} from "../../../../../../../utils/logs";

export function renderLogDuration(log: Log) {
  const { startTimeMs, endTimeMs } = calculateLogBoundsInMs(log);

  invariant(
    startTimeMs !== null && endTimeMs !== null,
    "Log must have defined bounds to render duration"
  );

  return prettyMilliseconds(endTimeMs - startTimeMs);
}

export function renderLogIngestionStatusChip(
  log: Log,
  size?: ChipProps["size"]
) {
  let chipPropsMap = new Map<DerivedIngestionStatus, ChipProps>([
    ["empty", { label: "No Ingestions", color: "default" }],
    ["error", { label: "Errored", color: "error" }],
    ["processing", { label: "Processing", color: "info" }],
    ["complete", { label: "Complete", color: "success" }],
    ["unknown", { label: "Unknown", color: "warning" }],
  ]);

  const derivedStatus = deriveIngestionStatus(log);

  return <Chip {...chipPropsMap.get(derivedStatus)!} size={size} />;
}

export function renderGroupName(
  groupQuery: UseQueryResult<Group>,
  errorElement: React.ReactNode = null
) {
  if (groupQuery.isLoading) {
    return <Skeleton />;
  } else if (groupQuery.isError) {
    return errorElement;
  } else {
    return (
      <Typography component="span" sx={{ display: "block" }}>
        {groupQuery.data.name}
      </Typography>
    );
  }
}
