/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  GroupCreateRequest,
  GroupCreateRequestFromJSON,
  GroupCreateRequestToJSON,
  GroupFetchResponse,
  GroupFetchResponseFromJSON,
  GroupFetchResponseToJSON,
  GroupListResponse,
  GroupListResponseFromJSON,
  GroupListResponseToJSON,
  GroupUpdateRequest,
  GroupUpdateRequestFromJSON,
  GroupUpdateRequestToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateGroupRequest {
  groupCreateRequest: GroupCreateRequest;
}

export interface DeleteGroupRequest {
  groupId: string;
}

export interface GetGroupRequest {
  groupId: string;
}

export interface ListGroupsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  name?: string;
  nameLike?: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateGroupRequest {
  groupId: string;
  groupUpdateRequest: GroupUpdateRequest;
}

/**
 *
 */
export class GroupApi extends runtime.BaseAPI {
  /**
   * Required role: [admin]
   * Create Group
   */
  async createGroupRaw(
    this: GroupApi,
    requestParameters: CreateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupFetchResponse>> {
    if (
      requestParameters.groupCreateRequest === null ||
      requestParameters.groupCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "groupCreateRequest",
        "Required parameter requestParameters.groupCreateRequest was null or undefined when calling createGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groups`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GroupCreateRequestToJSON(requestParameters.groupCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [admin]
   * Create Group
   */
  async createGroup(
    this: GroupApi,
    requestParameters: CreateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupFetchResponse> {
    const response = await this.createGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [admin]
   * Delete Group
   */
  async deleteGroupRaw(
    this: GroupApi,
    requestParameters: DeleteGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.groupId === null ||
      requestParameters.groupId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupId",
        "Required parameter requestParameters.groupId was null or undefined when calling deleteGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [admin]
   * Delete Group
   */
  async deleteGroup(
    this: GroupApi,
    requestParameters: DeleteGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteGroupRaw(requestParameters, initOverrides);
  }

  /**
   * Get Group
   */
  async getGroupRaw(
    this: GroupApi,
    requestParameters: GetGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupFetchResponse>> {
    if (
      requestParameters.groupId === null ||
      requestParameters.groupId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupId",
        "Required parameter requestParameters.groupId was null or undefined when calling getGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Group
   */
  async getGroup(
    this: GroupApi,
    requestParameters: GetGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupFetchResponse> {
    const response = await this.getGroupRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * List Groups
   */
  async listGroupsRaw(
    this: GroupApi,
    requestParameters: ListGroupsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groups`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Groups
   */
  async listGroups(
    this: GroupApi,
    requestParameters: ListGroupsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupListResponse> {
    const response = await this.listGroupsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Group
   */
  async updateGroupRaw(
    this: GroupApi,
    requestParameters: UpdateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupFetchResponse>> {
    if (
      requestParameters.groupId === null ||
      requestParameters.groupId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupId",
        "Required parameter requestParameters.groupId was null or undefined when calling updateGroup."
      );
    }

    if (
      requestParameters.groupUpdateRequest === null ||
      requestParameters.groupUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "groupUpdateRequest",
        "Required parameter requestParameters.groupUpdateRequest was null or undefined when calling updateGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groups/{group_id}`.replace(
          `{${"group_id"}}`,
          encodeURIComponent(String(requestParameters.groupId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: GroupUpdateRequestToJSON(requestParameters.groupUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Group
   */
  async updateGroup(
    this: GroupApi,
    requestParameters: UpdateGroupRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupFetchResponse> {
    const response = await this.updateGroupRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
