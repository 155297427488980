import React from "react";
import type { HandlerProps as ResizeEvent } from "react-reflex";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useSessionStorage } from "../../../../../hooks";

interface BaseProps {
  storageKey: string;
}

interface VerticalResizeablePanelProps extends BaseProps {
  splitOrientation: "vertical";
  left: React.ReactNode;
  right: React.ReactNode;
}

interface HorizontalResizeablePanelProps extends BaseProps {
  splitOrientation: "horizontal";
  top: React.ReactNode;
  bottom: React.ReactNode;
}

export type ResizeablePanelsProps =
  | VerticalResizeablePanelProps
  | HorizontalResizeablePanelProps;

export default function ResizeablePanels(props: ResizeablePanelsProps) {
  const resizeablePanels = useResizeablePanels(props.storageKey);

  let firstPanel: React.ReactNode;
  let secondPanel: React.ReactNode;
  if (props.splitOrientation === "vertical") {
    firstPanel = props.left;
    secondPanel = props.right;
  } else {
    firstPanel = props.top;
    secondPanel = props.bottom;
  }

  return (
    <ReflexContainer orientation={props.splitOrientation}>
      <ReflexElement
        flex={resizeablePanels.first}
        onStopResize={resizeablePanels.setFirstPanelSize}
      >
        {firstPanel}
      </ReflexElement>
      <ReflexSplitter />
      <ReflexElement
        flex={resizeablePanels.second}
        onStopResize={resizeablePanels.setSecondPanelSize}
      >
        {secondPanel}
      </ReflexElement>
    </ReflexContainer>
  );
}

function useResizeablePanels(storageKey: string) {
  const [panelSizes, setPanelSizes] = useSessionStorage<[number, number]>(
    storageKey,
    [0.5, 0.5]
  );

  function makeResizeHandler(panelIndex: 0 | 1) {
    return function resizeHandler(e: ResizeEvent) {
      const {
        component: {
          props: { flex = 0 },
        },
      } = e;

      setPanelSizes((prevPanelSizes) => {
        const copy: typeof panelSizes = [...prevPanelSizes];
        copy[panelIndex] = flex;
        return copy;
      });
    };
  }

  return {
    first: panelSizes[0],
    setFirstPanelSize: makeResizeHandler(0),
    second: panelSizes[1],
    setSecondPanelSize: makeResizeHandler(1),
  };
}
