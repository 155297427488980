import { daysInWeek } from "date-fns";
import type { ValueOf } from "ts-essentials";
import type { Maybe } from "../../types";
import type { Group } from "../datastore";
import { getAmplifyIdToken } from "../logrus";
import { NoMetricsError } from "./errors";

const API_URL =
  "https://o6ye4tkemc6lv5gzzcr5vi4eqy0diczw.lambda-url.us-east-1.on.aws/";

export const ReportDuration = {
  Month: "month",
  Week: "week",
  Day: "day",
  Hour: "hour",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReportDuration = ValueOf<typeof ReportDuration>;

export interface FetchMetricsRequest {
  groupId?: Maybe<Group["id"]>;
  date: Date;
  duration: ReportDuration;
}

export interface Metric {
  period: number;
  bot_metrics_count_max: number;
  delivery_times_count_sum: number;
  pick_times_count_sum: number;
  total_scratches_count_sum: number;
  total_onboard_halts_count_sum: number;
}

export interface FetchMetricsResponse {
  data: Metric[];
  groupId: Group["id"];
  period: string;
}

export async function fetchMetrics({
  groupId,
  date,
  duration,
}: FetchMetricsRequest): Promise<FetchMetricsResponse> {
  const idToken = await getAmplifyIdToken();

  const requestUrl = new URL(API_URL);

  if (groupId != null) {
    requestUrl.searchParams.set("group_id", groupId);
  }

  const { timestampGte, timestampLt } = calculateTimestampFilters(
    date,
    duration
  );

  requestUrl.searchParams.set("timestamp_gte", String(timestampGte));

  requestUrl.searchParams.set("timestamp_lt", String(timestampLt));

  requestUrl.searchParams.set("period", getApiPeriodForDuration(duration));

  const response = await fetch(requestUrl, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });

  if (!response.ok && response.status !== 400) {
    throw new Error(response.statusText);
  }

  const body = await response.json();

  if (response.status === 400 && body.error === "NO_METRICS_LOG") {
    throw new NoMetricsError(body.message);
  }

  const { group_id, data, period } = body;

  return {
    data,
    groupId: group_id,
    period,
  };
}

function calculateTimestampFilters(
  date: Date,
  duration: ReportDuration
): {
  timestampGte: number;
  timestampLt: number;
} {
  const startDate = new Date(date);
  const endDate = new Date(date);

  switch (duration) {
    case "month": {
      // Since month durations use weeks as periods, the start and end date
      // need to correspond to the start of a week, i.e. Sunday
      startDate.setUTCDate(startDate.getUTCDate() - startDate.getUTCDay());

      endDate.setUTCMonth(endDate.getUTCMonth() + 1);
      if (endDate.getUTCDay() !== 0) {
        endDate.setUTCDate(
          endDate.getUTCDate() + (daysInWeek - endDate.getUTCDay())
        );
      }

      break;
    }
    case "week": {
      endDate.setUTCDate(endDate.getUTCDate() + daysInWeek);

      break;
    }
    case "day": {
      endDate.setUTCDate(endDate.getUTCDate() + 1);

      break;
    }
    case "hour": {
      endDate.setUTCHours(endDate.getUTCHours() + 1);

      break;
    }
  }

  const timestampGte = Number(startDate) / 1_000;
  const timestampLt = Number(endDate) / 1_000;

  return {
    timestampGte,
    timestampLt,
  };
}

function getApiPeriodForDuration(
  duration: ReportDuration
): "week" | "day" | "hour" | "minute" {
  switch (duration) {
    case "month":
      return "week";
    case "week":
      return "day";
    case "day":
      return "hour";
    case "hour":
      return "minute";
  }
}
