/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  Record,
  RecordFromJSON,
  RecordFromJSONTyped,
  RecordToJSON,
} from "./Record";

/**
 *
 * @export
 * @interface RecordListResponse
 */
export interface RecordListResponse {
  /**
   *
   * @type {number}
   * @memberof RecordListResponse
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof RecordListResponse
   */
  limit: number;
  /**
   *
   * @type {string}
   * @memberof RecordListResponse
   */
  order: string;
  /**
   *
   * @type {string}
   * @memberof RecordListResponse
   */
  sort: string;
  /**
   *
   * @type {number}
   * @memberof RecordListResponse
   */
  count: number;
  /**
   *
   * @type {Array<Record>}
   * @memberof RecordListResponse
   */
  data: Array<Record>;
}

export function RecordListResponseFromJSON(json: any): RecordListResponse {
  return RecordListResponseFromJSONTyped(json, false);
}

export function RecordListResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordListResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offset: json["offset"],
    limit: json["limit"],
    order: json["order"],
    sort: json["sort"],
    count: json["count"],
    data: (json["data"] as Array<any>).map(RecordFromJSON),
  };
}

export function RecordListResponseToJSON(
  value?: RecordListResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offset: value.offset,
    limit: value.limit,
    order: value.order,
    sort: value.sort,
    count: value.count,
    data: (value.data as Array<any>).map(RecordToJSON),
  };
}
