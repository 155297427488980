/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import { Group, GroupFromJSON, GroupFromJSONTyped, GroupToJSON } from "./Group";

/**
 *
 * @export
 * @interface GroupFetchResponse
 */
export interface GroupFetchResponse {
  /**
   *
   * @type {Group}
   * @memberof GroupFetchResponse
   */
  data: Group;
}

export function GroupFetchResponseFromJSON(json: any): GroupFetchResponse {
  return GroupFetchResponseFromJSONTyped(json, false);
}

export function GroupFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: GroupFromJSON(json["data"]),
  };
}

export function GroupFetchResponseToJSON(
  value?: GroupFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: GroupToJSON(value.data),
  };
}
