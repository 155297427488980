import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import invariant from "invariant";
import { getClients } from "../domain/datastores";
import type { User, UserFetchResponse } from "../services/datastore";
import type { Maybe, ResolvedKeyFactory } from "../types";
import { mergeEnabledOption } from "./utils";

export function useUserKeys() {
  const factory = {
    all: ["users"] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (userId: Maybe<User["id"]>) =>
      [...factory.details(), userId] as const,
  } as const;

  return factory;
}

export type UserKeys = ResolvedKeyFactory<typeof useUserKeys>;

export function useUser<TData = UserFetchResponse>(
  userId: Maybe<User["id"]>,
  options?: UseQueryOptions<
    UserFetchResponse,
    unknown,
    TData,
    UserKeys["detail"]
  >
) {
  return useQuery({
    queryKey: useUserKeys().detail(userId),
    queryFn(context) {
      invariant(userId != null, "User ID must be defined");

      const { userApi } = getClients();

      return userApi.getUser({ userId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, userId != null),
  });
}
