/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  ClientMethod,
  ClientMethodFromJSON,
  ClientMethodFromJSONTyped,
  ClientMethodToJSON,
} from "./ClientMethod";
import {
  PresignedURLParams,
  PresignedURLParamsFromJSON,
  PresignedURLParamsFromJSONTyped,
  PresignedURLParamsToJSON,
} from "./PresignedURLParams";

/**
 *
 * @export
 * @interface CreatePresignedURLResponse
 */
export interface CreatePresignedURLResponse {
  /**
   *
   * @type {ClientMethod}
   * @memberof CreatePresignedURLResponse
   */
  method: ClientMethod;
  /**
   *
   * @type {PresignedURLParams}
   * @memberof CreatePresignedURLResponse
   */
  params: PresignedURLParams;
  /**
   *
   * @type {string}
   * @memberof CreatePresignedURLResponse
   */
  url: string;
}

export function CreatePresignedURLResponseFromJSON(
  json: any
): CreatePresignedURLResponse {
  return CreatePresignedURLResponseFromJSONTyped(json, false);
}

export function CreatePresignedURLResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePresignedURLResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    method: ClientMethodFromJSON(json["method"]),
    params: PresignedURLParamsFromJSON(json["params"]),
    url: json["url"],
  };
}

export function CreatePresignedURLResponseToJSON(
  value?: CreatePresignedURLResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    method: ClientMethodToJSON(value.method),
    params: PresignedURLParamsToJSON(value.params),
    url: value.url,
  };
}
