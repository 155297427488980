import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
// @ts-ignore
import * as ROS3D from "ros3d";
import { Group } from "three";
import useResizeObserver from "use-resize-observer";
import type { Maybe } from "../../../../../../types";
import { usePlaybackTimerPause } from "../../../../playback";
import type { MinibotLog } from "../../types";
import DebugControls from "./DebugControls";
import MarkerTopicSelect from "./MarkerTopicSelect";
import PlaybackMarkerTopic from "./PlaybackMarkerTopic";
import StaleNotification from "./StaleNotification";
import { useBotMarkers, useMarkerTopics } from "./hooks";
import { disableLogs, requireViewer } from "./utils";

export interface BotViewerProps {
  routerLog: MinibotLog | null;
  botName?: Maybe<string>;
}

export default function BotViewer({ routerLog, botName }: BotViewerProps) {
  const [showSettings, setShowSettings] = useState(false);

  const containerRef = useRef<HTMLElement | null>(null);
  const { height, width } = useResizeObserver({ ref: containerRef });

  const viewerRef = useRef<any>(null);

  useEffect(function initializeViewer() {
    if (containerRef.current === null) {
      return;
    }

    if (viewerRef.current == null) {
      const { clientHeight, clientWidth } = containerRef.current;

      viewerRef.current = disableLogs(
        () =>
          new ROS3D.Viewer({
            elem: containerRef.current,
            height: clientHeight,
            width: clientWidth,
            background: "black",
            // Reverse the zoom direction. ros3d uses 0.5 as the default so
            // this just reverses it while keeping everything else the same
            cameraZoomSpeed: -0.5,
          })
      );
    }

    const { current: viewer } = viewerRef;

    const originalDraw = viewer.draw.bind(viewer);
    viewer.draw = function patchedDraw() {
      disableLogs(() => originalDraw());
    };

    viewer.camera.position.set(30.0, 30.0, 20.0);
    viewer.camera.quaternion.set(0.37, 0.2, 0.44, 0.8);
    viewer.camera.scale.set(1, 1, 1);

    return function disposeOfAssets() {
      // I'm not entirely confident in this memory-management code, especially
      // since there aren't any types for ros3d. Just to ensure this doesn't
      // crash Studio I'll log it to the console.
      try {
        viewer.scene.children.forEach((child: any) => {
          if (child instanceof Group) {
            child.children.forEach((object: any) => {
              if (object instanceof ROS3D.Marker) {
                object.dispose();
              }
            });
          }
        });

        viewer.renderer.dispose();
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  useEffect(
    function synchronizeViewerSize() {
      if (height === undefined || width === undefined) {
        return;
      }

      const viewer = requireViewer(viewerRef);

      viewer.resize(width, height);
    },
    [height, width]
  );

  const markerTopics = useMarkerTopics(routerLog, viewerRef);

  return (
    <Box
      sx={{
        height: 1,
        width: 1,
        position: "relative",
        // Without `overflow: hidden` scrollbars will flicker during resizing
        overflow: "hidden",
        // If the <canvas> isn't `display: block` there will be slight vertical
        // overflow due to its line-height (I think)
        "& canvas": { display: "block" },
      }}
      ref={containerRef}
    >
      <MarkerTopicSelect
        open={showSettings}
        setOpen={setShowSettings}
        markerTopics={markerTopics}
      />
      <DebugControls
        key={String(markerTopics.inDebugMode)}
        markerTopics={markerTopics}
      />
      {markerTopics.inDebugMode ? (
        <DebugMode markerTopics={markerTopics} />
      ) : (
        <PlaybackMode
          routerLog={routerLog}
          botName={botName}
          viewerRef={viewerRef}
          markerTopics={markerTopics}
        />
      )}
    </Box>
  );
}

interface PlaybackModeProps extends BotViewerProps {
  viewerRef: React.MutableRefObject<any>;
  markerTopics: ReturnType<typeof useMarkerTopics>;
}

function PlaybackMode({
  routerLog,
  botName,
  viewerRef,
  markerTopics,
}: PlaybackModeProps) {
  const botMarkers = useBotMarkers(routerLog, viewerRef, botName);

  const isLoading = botMarkers.status === "loading" || markerTopics.isLoading;
  usePlaybackTimerPause(isLoading);

  const markerTopicIds = [...markerTopics.selected.keys()].filter(
    (topicId) => topicId !== markerTopics.routerPlaybackTopicId
  );

  return (
    <>
      {botMarkers.status === "stale" && (
        <StaleNotification staleTimeMs={botMarkers.staleTimeMs} />
      )}
      <Backdrop sx={{ position: "absolute" }} open={isLoading}>
        <CircularProgress />
      </Backdrop>
      {markerTopicIds.map((topicId) => (
        <PlaybackMarkerTopic
          key={topicId}
          routerLog={routerLog}
          topicId={topicId}
          viewerRef={viewerRef}
          setStatus={markerTopics.setStatus}
        />
      ))}
    </>
  );
}

interface DebugModeProps {
  markerTopics: ReturnType<typeof useMarkerTopics>;
}

function DebugMode({ markerTopics }: DebugModeProps) {
  return (
    <>
      <Backdrop sx={{ position: "absolute" }} open={markerTopics.isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}
