import React from "react";

export interface BreakableProps {
  /**
   * The text string that should be broken
   */
  children?: string;
  /**
   * The RegEx used to determine where to insert <wbr> elements. The
   * separator should include a RegEx capturing group so the matched parts
   * will be included in the final string
   */
  separator: RegExp;
  /**
   * Where the <wbr> element should be inserted relative to parts matching
   * the `separator`
   */
  insertBreak?: "before" | "after";
}

export default function BreakableText({
  children,
  separator,
  insertBreak = "before",
}: BreakableProps) {
  if (children == null) {
    return null;
  }

  return (
    <>
      {children.split(separator).map((part, index) => (
        <React.Fragment key={index}>
          {insertBreak === "before" && separator.test(part) && <wbr />}
          {part}
          {insertBreak === "after" && separator.test(part) && <wbr />}
        </React.Fragment>
      ))}
    </>
  );
}
