import React from "react";
import { Album, CloudDownload } from "@mui/icons-material";
import {
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { Auth } from "aws-amplify";
import type { To } from "react-router-dom";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { useCurrentDataStore } from "../domain/datastores";
import * as paths from "../paths";
import {
  ScreenConfiguration,
  useLayoutStateContext,
  useScreenConfiguration,
} from "./Layout";
import Logo from "./Logo";

function useIsActivePath() {
  const location = useLocation();

  return function isActivePath(path: string, end = false): boolean {
    return matchPath({ path, end }, location.pathname) !== null;
  };
}

export default function GlobalNavigation() {
  const dataStore = useCurrentDataStore();

  const { isGlobalNavigationOpen } = useLayoutStateContext();
  const screenConfiguration = useScreenConfiguration();

  const isActivePath = useIsActivePath();

  const useMiniDrawerStyles = !(
    screenConfiguration === ScreenConfiguration.Mobile || isGlobalNavigationOpen
  );

  const pathQuery = { url: dataStore };

  function renderLink(
    icon: React.ReactNode,
    text: string,
    path: string,
    to: To
  ) {
    const isSelected = isActivePath(path);

    return (
      <ListItem component="div" disablePadding>
        <ListItemButton
          component={RouterLink}
          to={to}
          selected={isSelected}
          sx={{
            ...(useMiniDrawerStyles && {
              px: 0,
              justifyContent: "center",
            }),
          }}
        >
          {useMiniDrawerStyles ? (
            <Tooltip title={text} placement="right">
              <span>{icon}</span>
            </Tooltip>
          ) : (
            <>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "body2",
                  fontWeight: isSelected ? 600 : undefined,
                }}
              >
                {text}
              </ListItemText>
            </>
          )}
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Stack
      sx={{
        flexGrow: 1,
        minHeight: 0,
        overflowY: "auto",
      }}
    >
      <List
        component="nav"
        aria-label="global"
        sx={{
          "& .MuiListItemButton-root": {
            my: 0.5,
            borderRadius: "8px",
          },
        }}
      >
        <ListItem
          component="div"
          disablePadding
          sx={{
            justifyContent: "center",
            "& .homepage-link": {
              display: "flex",
              justifyContent: "center",
            },
            "& .logqs-logo": {
              display: "block",
              width: "auto",
              height: 40,
              mb: 2,
            },
          }}
        >
          {isActivePath(paths.INDEX, true) ? (
            <Logo alt="LogQS logo" icon={useMiniDrawerStyles} />
          ) : (
            <RouterLink
              className="homepage-link"
              to={paths.makeIndexLocation(pathQuery)}
            >
              <Logo alt="Homepage" icon={useMiniDrawerStyles} />
            </RouterLink>
          )}
        </ListItem>
        {renderLink(
          <Album />,
          "Player",
          paths.PLAYER,
          paths.makePlayerLocation(pathQuery)
        )}
        {renderLink(
          <CloudDownload />,
          "Extractions",
          paths.EXTRACTIONS,
          paths.makeExtractionsLocation(pathQuery)
        )}
      </List>
      {!useMiniDrawerStyles && (
        <Stack spacing={4} mt="auto" alignItems="center">
          <Link
            component="button"
            variant="body1"
            onClick={() => Auth.signOut()}
            underline="none"
            fontWeight="bold"
          >
            Sign Out
          </Link>
        </Stack>
      )}
    </Stack>
  );
}
