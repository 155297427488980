/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserUpdateRequest
 */
export interface UserUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdateRequest
   */
  password?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserUpdateRequest
   */
  isAdmin?: boolean;
  /**
   *
   * @type {Date}
   * @memberof UserUpdateRequest
   */
  deletedAt?: Date;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
  return UserUpdateRequestFromJSONTyped(json, false);
}

export function UserUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: !exists(json, "username") ? undefined : json["username"],
    password: !exists(json, "password") ? undefined : json["password"],
    isAdmin: !exists(json, "is_admin") ? undefined : json["is_admin"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function UserUpdateRequestToJSON(value?: UserUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    password: value.password,
    is_admin: value.isAdmin,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
