import React from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { TreeItem, TreeView } from "@mui/lab";
import { Typography } from "@mui/material";
import { isPlainObject } from "lodash";
import type { JsonObject } from "type-fest";
import type { Maybe } from "../../../types";
import { pluralize } from "../../../utils";

export interface JsonTreeProps {
  src: Maybe<JsonObject>;
  onSelect: (path: string) => void;
}

interface EntryProps {
  entry: [string | number, unknown];
  path?: (number | string)[];
}

export default function JsonTree({ src, onSelect }: JsonTreeProps) {
  if (src == null) {
    return null;
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      onNodeSelect={(e: React.SyntheticEvent, nodeId: string) =>
        onSelect(nodeId)
      }
    >
      {Object.entries(src).map((entry) => (
        <Entry key={entry[0]} entry={entry} />
      ))}
    </TreeView>
  );
}

function Entry({ entry: [key, value], path = [] }: EntryProps) {
  const currentPath = [...path, key];
  const nodeId = currentPath.join(".");

  let label;
  let children;
  if (isPlainObject(value) || Array.isArray(value)) {
    const entries = Object.entries(value as any);

    label = (
      <>
        <Typography component="span">
          {Array.isArray(value) ? "[ ... ]" : "{ ... }"}
        </Typography>{" "}
        <Typography component="span" variant="body2" color="text.secondary">
          {pluralize(entries.length, "item")}
        </Typography>
      </>
    );

    children = entries.map((entry) => (
      <Entry key={entry[0]} entry={entry} path={currentPath} />
    ));
  } else {
    label = (
      <>
        <Typography component="span">{JSON.stringify(value)}</Typography>{" "}
        <Typography component="span" variant="body2" color="text.secondary">
          {typeof value}
        </Typography>
      </>
    );

    children = null;
  }

  return (
    <TreeItem
      nodeId={nodeId}
      label={
        <>
          <b>{Number.isFinite(Number(key)) ? key : JSON.stringify(key)}</b>:{" "}
          {label}
        </>
      }
    >
      {children}
    </TreeItem>
  );
}
