import React, { useEffect, useRef } from "react";
import {
  alpha,
  Box,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { findLastIndex, first, last } from "lodash";
import type { VirtuosoHandle } from "react-virtuoso";
import { Virtuoso } from "react-virtuoso";
import type { Maybe } from "../../../../../types";
import { useFormatPlaybackTimestamp } from "../../../PlaybackProvider";
import type { InitializedPanelNode } from "../../../panels";
import { isSpdlogTopic } from "../../../panels";
import SpdlogMessage from "./SpdlogMessage";
import type { TerminalRecord } from "./types";

export interface TerminalListProps {
  panel: InitializedPanelNode;
  timestampMs: number;
  globalOffsetIndex: number;
  records: TerminalRecord[];
  selectedRowId: Maybe<TerminalRecord["id"]>;
  onRowClick: (
    e: React.MouseEvent<HTMLDivElement>,
    record: TerminalRecord
  ) => void;
}

export default function TerminalList({
  panel,
  timestampMs,
  globalOffsetIndex,
  records,
  selectedRowId,
  onRowClick,
}: TerminalListProps) {
  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  let mostRecentRecordIndex: number | null = findLastIndex(
    records,
    (record) => record.timestampMs <= timestampMs
  );

  if (mostRecentRecordIndex === -1) {
    mostRecentRecordIndex = null;
  }

  const virtuosoRef = useRef<VirtuosoHandle>(null);
  useEffect(
    function performAutoScroll() {
      if (!panel.terminalAutoScroll) {
        return;
      }

      if (mostRecentRecordIndex === null) {
        return;
      }

      virtuosoRef.current?.scrollToIndex({
        index: mostRecentRecordIndex,
        align: "end",
      });
    },
    [timestampMs, panel.terminalAutoScroll, mostRecentRecordIndex]
  );

  const earliestRecord = first(records);
  const latestRecord = last(records);

  return (
    <Virtuoso
      ref={virtuosoRef}
      {...(mostRecentRecordIndex !== null && {
        initialTopMostItemIndex: {
          index: mostRecentRecordIndex,
          align: "end",
        },
      })}
      increaseViewportBy={200}
      firstItemIndex={globalOffsetIndex}
      data={records}
      components={{
        List: List as any,
        Item: ListItem,
        Header() {
          if (earliestRecord === undefined) {
            return null;
          }

          return (
            <Box sx={{ py: 2 }}>
              <Typography fontStyle="italic" align="center">
                Showing records after{" "}
                {formatPlaybackTimestamp(earliestRecord.timestampMs)}
              </Typography>
            </Box>
          );
        },
        Footer() {
          if (latestRecord === undefined) {
            return null;
          }

          return (
            <Box sx={{ py: 2 }}>
              <Typography fontStyle="italic" align="center">
                Showing records before{" "}
                {formatPlaybackTimestamp(latestRecord.timestampMs)}
              </Typography>
            </Box>
          );
        },
      }}
      computeItemKey={(_, terminalRecord) => terminalRecord.id}
      itemContent={(itemIndex, terminalRecord) => (
        <ListItemButton
          sx={{
            p: 0.5,
            ...(mostRecentRecordIndex !== null &&
              itemIndex === mostRecentRecordIndex + globalOffsetIndex && {
                bgcolor: (theme) => alpha(theme.palette.success.dark, 0.25),
              }),
          }}
          selected={terminalRecord.id === selectedRowId}
          onClick={(e) => onRowClick(e, terminalRecord)}
        >
          {isSpdlogTopic(panel.messageTypeName) ? (
            <SpdlogMessage message={terminalRecord.payload} />
          ) : (
            <>
              <Typography sx={{ flex: "none", mr: 1 }} color="text.secondary">
                {formatPlaybackTimestamp(terminalRecord.timestampMs)}
              </Typography>
              {terminalRecord.filteredPayload == null ? (
                <Typography sx={{ fontStyle: "italic" }} noWrap>
                  No field matching filter
                </Typography>
              ) : (
                <Typography noWrap>
                  {JSON.stringify(terminalRecord.filteredPayload)}
                </Typography>
              )}
            </>
          )}
        </ListItemButton>
      )}
    />
  );
}
