import React, { useEffect, useState } from "react";
import { createSafeContext } from "../../contexts";
import { isUserAuthenticated } from "./auth";

export type LogrusAuthStatus = "guest" | "authenticated";

export type LogrusContextValue = LogrusAuthStatus;

export const [useLogrus, LogrusContext] =
  createSafeContext<LogrusContextValue>("Logrus");

export interface LogrusProviderProps {
  loadingFallback?: React.ReactNode;
  children: React.ReactNode;
}

export default function LogrusProvider({
  loadingFallback = null,
  children,
}: LogrusProviderProps) {
  const [authStatus, setAuthStatus] = useState<LogrusAuthStatus | null>(null);

  useEffect(function determineAuthStatus() {
    (async function performStatusCheck() {
      setAuthStatus((await isUserAuthenticated()) ? "authenticated" : "guest");
    })();
  }, []);

  if (authStatus !== null) {
    return (
      <LogrusContext.Provider value={authStatus}>
        {children}
      </LogrusContext.Provider>
    );
  } else {
    return <>{loadingFallback}</>;
  }
}
