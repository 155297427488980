/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface MessageType
 */
export interface MessageType {
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof MessageType
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof MessageType
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof MessageType
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  md5: string;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  definition: string;
  /**
   *
   * @type {string}
   * @memberof MessageType
   */
  note: string | null;
  /**
   *
   * @type {object}
   * @memberof MessageType
   */
  context: object | null;
}

export function MessageTypeFromJSON(json: any): MessageType {
  return MessageTypeFromJSONTyped(json, false);
}

export function MessageTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MessageType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    groupId: json["group_id"],
    name: json["name"],
    md5: json["md5"],
    definition: json["definition"],
    note: json["note"],
    context: json["context"],
  };
}

export function MessageTypeToJSON(value?: MessageType | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    group_id: value.groupId,
    name: value.name,
    md5: value.md5,
    definition: value.definition,
    note: value.note,
    context: value.context,
  };
}
