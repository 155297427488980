import { useEffect, useRef } from "react";
import { findLast } from "lodash";
import { usePlaybackSource } from "../PlaybackProvider";
import type { PlayerRecord } from "../types";
import type { UseRecordChunksResult } from "./useRecordChunks";

export default function useCurrentWindowRecord(
  windowQuery: UseRecordChunksResult,
  currentDurationMs: number = Infinity
): PlayerRecord | undefined {
  const playbackSource = usePlaybackSource();

  // If the panel is in a "standard" state (data loaded, not showing
  // placeholder data), then the most recent record (if any) should be
  // retrieved.
  const candidateRecord =
    windowQuery.data === undefined ||
    windowQuery.isPlaceholderData ||
    playbackSource.timestampMs === undefined
      ? undefined
      : findLast(
          windowQuery.data,
          (record) =>
            record.timestampMs <= playbackSource.timestampMs &&
            playbackSource.timestampMs - record.timestampMs <= currentDurationMs
        );

  const previousRecordRef = useRef<PlayerRecord | undefined>(undefined);
  useEffect(function storeCandidateRecord() {
    // Only store the candidate record if it's from real data,
    // not placeholder data. Specifically, if the candidate is `undefined`
    // because we're showing placeholder data, then the previous record
    // ref's value shouldn't be overwritten
    if (windowQuery.isPlaceholderData) {
      return;
    }

    previousRecordRef.current = candidateRecord;
  });

  // When displaying placeholder data, the last record that was on screen
  // (or lack thereof if there was no recent candidate record) should continue
  // being displayed until real data is available.
  return windowQuery.isPlaceholderData
    ? previousRecordRef.current
    : candidateRecord;
}
