/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface UserCreateRequest
 */
export interface UserCreateRequest {
  /**
   *
   * @type {string}
   * @memberof UserCreateRequest
   */
  username: string;
  /**
   *
   * @type {boolean}
   * @memberof UserCreateRequest
   */
  isAdmin?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserCreateRequest
   */
  password?: string;
}

export function UserCreateRequestFromJSON(json: any): UserCreateRequest {
  return UserCreateRequestFromJSONTyped(json, false);
}

export function UserCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    username: json["username"],
    isAdmin: !exists(json, "is_admin") ? undefined : json["is_admin"],
    password: !exists(json, "password") ? undefined : json["password"],
  };
}

export function UserCreateRequestToJSON(value?: UserCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    username: value.username,
    is_admin: value.isAdmin,
    password: value.password,
  };
}
