/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  Extraction,
  ExtractionFromJSON,
  ExtractionFromJSONTyped,
  ExtractionToJSON,
} from "./Extraction";

/**
 *
 * @export
 * @interface ExtractionFetchResponse
 */
export interface ExtractionFetchResponse {
  /**
   *
   * @type {Extraction}
   * @memberof ExtractionFetchResponse
   */
  data: Extraction;
}

export function ExtractionFetchResponseFromJSON(
  json: any
): ExtractionFetchResponse {
  return ExtractionFetchResponseFromJSONTyped(json, false);
}

export function ExtractionFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtractionFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: ExtractionFromJSON(json["data"]),
  };
}

export function ExtractionFetchResponseToJSON(
  value?: ExtractionFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ExtractionToJSON(value.data),
  };
}
