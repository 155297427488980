import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { getClients } from "../domain/datastores";
import type {
  GroupAssociationListResponse,
  ListGroupAssociationsRequest,
} from "../services/datastore";
import type { ResolvedKeyFactory } from "../types";

export function useGroupAssociationKeys() {
  const factory = {
    all: ["group-associations"] as const,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListGroupAssociationsRequest) =>
      [...factory.lists(), request] as const,
  } as const;

  return factory;
}

export type GroupAssociationKeys = ResolvedKeyFactory<
  typeof useGroupAssociationKeys
>;

export function useGroupAssociations<TData = GroupAssociationListResponse>(
  request: ListGroupAssociationsRequest,
  options?: UseQueryOptions<
    GroupAssociationListResponse,
    unknown,
    TData,
    GroupAssociationKeys["list"]
  >
) {
  return useQuery({
    queryKey: useGroupAssociationKeys().list(request),
    queryFn(context) {
      const { groupAssociationApi } = getClients();

      return groupAssociationApi.listGroupAssociations(request, context);
    },
    ...options,
  });
}
