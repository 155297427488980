import React from "react";
import { Box, Typography } from "@mui/material";
import "react-reflex/styles.css";
import Loading from "../../../components/Loading";
import { usePlayerLog, usePlayerTopics } from "../hooks";
import { PanelLayoutRoot } from "../panels";
import { VisualizationSwitch } from "./visualizations";

export default function TopicSection() {
  const logQuery = usePlayerLog();

  const topicsQuery = usePlayerTopics();

  return (
    <Box flex={1} minHeight={0}>
      {!logQuery.isSuccess || !topicsQuery.isSuccess ? (
        <Loading type="circular">
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        </Loading>
      ) : (
        <PanelLayoutRoot>
          <VisualizationSwitch />
        </PanelLayoutRoot>
      )}
    </Box>
  );
}
