/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ExtractionTopicCreateRequest
 */
export interface ExtractionTopicCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ExtractionTopicCreateRequest
   */
  topicId: string;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicCreateRequest
   */
  startTime?: number;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicCreateRequest
   */
  endTime?: number;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicCreateRequest
   */
  frequency?: number;
}

export function ExtractionTopicCreateRequestFromJSON(
  json: any
): ExtractionTopicCreateRequest {
  return ExtractionTopicCreateRequestFromJSONTyped(json, false);
}

export function ExtractionTopicCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtractionTopicCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    topicId: json["topic_id"],
    startTime: !exists(json, "start_time") ? undefined : json["start_time"],
    endTime: !exists(json, "end_time") ? undefined : json["end_time"],
    frequency: !exists(json, "frequency") ? undefined : json["frequency"],
  };
}

export function ExtractionTopicCreateRequestToJSON(
  value?: ExtractionTopicCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    topic_id: value.topicId,
    start_time: value.startTime,
    end_time: value.endTime,
    frequency: value.frequency,
  };
}
