import React, { useCallback, useMemo, useState } from "react";
import { clamp } from "lodash";
import { useImmerReducer } from "use-immer";
import { createSafeContext } from "../../contexts";
import type { Maybe } from "../../types";
import { formatTimestamp } from "../../utils";
import { usePlayerConfig, usePlayerLog } from "./hooks";
import type { BasePlaybackSource, PlaybackSource } from "./playbackReducer";
import { initialState, makeReducer } from "./playbackReducer";
import type { PlaybackSpeedValue, TimestepValue } from "./types";
import { PlaybackSpeed, Timestep } from "./types";

export type DisplayFormat = "elapsed" | "original" | "utc";

export interface PlaybackSettings {
  displayFormat: DisplayFormat;
  setDisplayFormat: (displayFormat: DisplayFormat) => void;
  speed: PlaybackSpeedValue;
  setSpeed: (speed: PlaybackSpeedValue) => void;
  timestep: TimestepValue;
  setTimestep: (timestep: TimestepValue) => void;
}

export const [usePlaybackSettings, PlaybackSettingsContext] =
  createSafeContext<PlaybackSettings>("PlaybackSettings");

export interface PlaybackSettingsProviderProps {
  children: React.ReactNode;
}

export function PlaybackSettingsProvider({
  children,
}: PlaybackSettingsProviderProps) {
  const [displayFormat, setDisplayFormat] = useState<DisplayFormat>("utc");
  const [speed, setSpeed] = useState<PlaybackSpeedValue>(
    PlaybackSpeed.TimesTen
  );
  const [timestep, setTimestep] = useState<TimestepValue>(Timestep.Second);

  const contextValue: PlaybackSettings = useMemo(
    () => ({
      displayFormat,
      setDisplayFormat,
      speed,
      setSpeed,
      timestep,
      setTimestep,
    }),
    [displayFormat, speed, timestep]
  );

  return (
    <PlaybackSettingsContext.Provider value={contextValue}>
      {children}
    </PlaybackSettingsContext.Provider>
  );
}

export const [usePlaybackSource, PlaybackSourceContext] =
  createSafeContext<PlaybackSource>("PlaybackSource");

const utcTimeFormatter = new Intl.DateTimeFormat(undefined, {
  timeZone: "UTC",
  timeStyle: "long",
  hourCycle: "h23",
});

export function useFormatPlaybackTimestamp() {
  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const lowerBoundMs = playbackSource.boundsMs?.[0];

  return useCallback(
    (timestampMs: number) => {
      switch (playbackSettings.displayFormat) {
        case "elapsed": {
          return formatTimestamp(timestampMs, {
            precision: 1,
            relativeToMs: lowerBoundMs,
          });
        }
        case "utc": {
          return utcTimeFormatter.format(timestampMs);
        }
        case "original": {
          return (timestampMs / 1_000).toFixed(1);
        }
        default: {
          const _exhaustiveCheck: never = playbackSettings.displayFormat;
          throw new Error(`Unknown display format: ${_exhaustiveCheck}`);
        }
      }
    },
    [playbackSettings.displayFormat, lowerBoundMs]
  );
}

export interface PlaybackSourceProviderProps {
  /**
   * UTC timestamps representing the upper and lower playback bounds in
   * milliseconds. All timestamps will be clamped to within these bounds.
   * Conceptually, the lower bound can be considered t = 0 and can be used for
   * displaying UTC timestamps relative to how long after this time they
   * occurred. Set this value to undefined if it needs to be fetched
   * asynchronously. Trying to perform playback operations while this is
   * undefined will result in an error.
   */
  boundsMs?: [number, number];
  /**
   * A UTC timestamp in milliseconds representing the default time at which
   * playback should start. Useful if loading a time from an external source
   * like a URL query param. If not given the default time will be the
   * lower playback bound. Will be clamped within `boundMs` before being
   * passed through the context
   */
  initialTimeMs?: Maybe<number>;
  children: React.ReactNode;
}

export function PlaybackSourceProvider({
  boundsMs,
  initialTimeMs,
  children,
}: PlaybackSourceProviderProps) {
  const clampedInitialTimeMs =
    boundsMs !== undefined
      ? // Since initial time comes from an outside source like a URL, there's
        // no guarantee it's within playback bounds
        clamp(initialTimeMs ?? boundsMs[0], ...boundsMs)
      : undefined;

  const playbackSettings = usePlaybackSettings();

  const [playbackSourceState, dispatch] = useImmerReducer(
    makeReducer(boundsMs, clampedInitialTimeMs, playbackSettings.timestep),
    initialState
  );

  const {
    timestampMs: effectiveTimestampMs = clampedInitialTimeMs,
    rangeMs: effectiveRangeMs = boundsMs,
  } = playbackSourceState;

  const isLoading =
    boundsMs === undefined || effectiveTimestampMs === undefined;

  const value: BasePlaybackSource = {
    isLoading,
    boundsMs: isLoading ? undefined : boundsMs,
    mode: playbackSourceState.status === "range-mode" ? "range" : "single",
    inRangeMode: playbackSourceState.status === "range-mode",
    isPlaying: playbackSourceState.status === "playing",
    rangeMs: isLoading ? undefined : effectiveRangeMs,
    timestampMs: isLoading ? undefined : effectiveTimestampMs,
    dispatch,
  };

  return (
    <PlaybackSourceContext.Provider value={value as PlaybackSource}>
      {children}
    </PlaybackSourceContext.Provider>
  );
}

export function LogPlaybackSourceProvider({
  children,
}: PlaybackSourceProviderProps) {
  const { logId, initialTimeMs } = usePlayerConfig();
  const playerLogQuery = usePlayerLog();

  let boundsMs: [number, number] | undefined = undefined;
  if (
    playerLogQuery.isSuccess &&
    playerLogQuery.data.startTimeMs !== null &&
    playerLogQuery.data.endTimeMs !== null
  ) {
    boundsMs = [playerLogQuery.data.startTimeMs, playerLogQuery.data.endTimeMs];
  }

  return (
    <PlaybackSourceProvider
      key={logId}
      boundsMs={boundsMs}
      initialTimeMs={initialTimeMs}
    >
      {children}
    </PlaybackSourceProvider>
  );
}
