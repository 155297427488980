import { useCallback } from "react";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

const LogIdParam = withDefault(StringParam, null);
const InitialTimeParam = withDefault(NumberParam, null);
const config = {
  logId: LogIdParam,
  t: InitialTimeParam,
};

type UsePlayerConfigReturn = {
  logId: ReturnType<typeof LogIdParam["decode"]>;
  initialTimeMs: ReturnType<typeof InitialTimeParam["decode"]>;
  setLogId: (logId: string) => void;
};

export default function usePlayerConfig(): UsePlayerConfigReturn {
  const [params, setParams] = useQueryParams(config);

  const setLogId = useCallback(
    (logId: string) => {
      // The initial timestamp param is specific to a given log ID, so changing
      // the log ID requires the initial timestamp param to be stripped from
      // the URL if present
      setParams({ logId, t: undefined });
    },
    [setParams]
  );

  return {
    ...params,
    initialTimeMs: params.t,
    setLogId,
  };
}
