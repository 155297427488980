/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  LogFormat,
  LogFormatFromJSON,
  LogFormatFromJSONTyped,
  LogFormatToJSON,
} from "./LogFormat";

/**
 *
 * @export
 * @interface IngestionCreateRequest
 */
export interface IngestionCreateRequest {
  /**
   *
   * @type {string}
   * @memberof IngestionCreateRequest
   */
  logId: string;
  /**
   *
   * @type {string}
   * @memberof IngestionCreateRequest
   */
  s3Bucket?: string;
  /**
   *
   * @type {string}
   * @memberof IngestionCreateRequest
   */
  s3Key?: string;
  /**
   *
   * @type {LogFormat}
   * @memberof IngestionCreateRequest
   */
  format: LogFormat;
  /**
   *
   * @type {string}
   * @memberof IngestionCreateRequest
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof IngestionCreateRequest
   */
  startOffset?: number;
  /**
   *
   * @type {number}
   * @memberof IngestionCreateRequest
   */
  endOffset?: number;
  /**
   *
   * @type {string}
   * @memberof IngestionCreateRequest
   */
  note?: string;
  /**
   *
   * @type {boolean}
   * @memberof IngestionCreateRequest
   */
  queued?: boolean;
  /**
   *
   * @type {object}
   * @memberof IngestionCreateRequest
   */
  context?: object;
}

export function IngestionCreateRequestFromJSON(
  json: any
): IngestionCreateRequest {
  return IngestionCreateRequestFromJSONTyped(json, false);
}

export function IngestionCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IngestionCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    logId: json["log_id"],
    s3Bucket: !exists(json, "s3_bucket") ? undefined : json["s3_bucket"],
    s3Key: !exists(json, "s3_key") ? undefined : json["s3_key"],
    format: LogFormatFromJSON(json["format"]),
    name: !exists(json, "name") ? undefined : json["name"],
    startOffset: !exists(json, "start_offset")
      ? undefined
      : json["start_offset"],
    endOffset: !exists(json, "end_offset") ? undefined : json["end_offset"],
    note: !exists(json, "note") ? undefined : json["note"],
    queued: !exists(json, "queued") ? undefined : json["queued"],
    context: !exists(json, "context") ? undefined : json["context"],
  };
}

export function IngestionCreateRequestToJSON(
  value?: IngestionCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    log_id: value.logId,
    s3_bucket: value.s3Bucket,
    s3_key: value.s3Key,
    format: LogFormatToJSON(value.format),
    name: value.name,
    start_offset: value.startOffset,
    end_offset: value.endOffset,
    note: value.note,
    queued: value.queued,
    context: value.context,
  };
}
