import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import invariant from "invariant";
import { getClients } from "../../../../domain/datastores";
import type { ListResponse } from "../../../../queries";
import { circumventPagination, useLog, useLogKeys } from "../../../../queries";
import type { Log } from "../../../../services/datastore";
import { usePlayerConfig } from "../../hooks";
import { BOT_LOGS_FETCH_LIMIT } from "./constants";
import type { MinibotLog } from "./types";
import { selectMinibotLog } from "./utils";

export function useEnsuredPlayerLogId() {
  const { logId } = usePlayerConfig();

  invariant(logId !== null, "Expected log ID not to be null");

  return logId;
}

export function useBotLogs(routerLogId: Log["id"]) {
  const logKeys = useLogKeys();

  const routerLogQuery = useLog(routerLogId, {
    select(response) {
      if (response.data.startTime === null || response.data.endTime === null) {
        return undefined;
      } else {
        return {
          limit: -1,
          offset: 0,
          nameLike: "bot_",
          groupId: response.data.groupId,
          // A bot log is associated with a router log if the bot log's start
          // time is contained by the router log's bounds
          startTimeGte: response.data.startTime,
          startTimeLte: response.data.endTime,
        };
      }
    },
  });

  const enabled = routerLogQuery.data !== undefined;
  const request = routerLogQuery.data ?? {};
  const select = useCallback(
    (response: ListResponse<Log>): ListResponse<MinibotLog> => {
      return {
        ...response,
        data: response.data.map((log) => selectMinibotLog(log)),
      };
    },
    []
  );

  return useQuery({
    queryKey: logKeys.list(request),
    queryFn(context) {
      invariant(enabled, "Query shouldn't be running");

      const { logApi } = getClients();

      return circumventPagination(
        logApi.listLogs.bind(logApi),
        BOT_LOGS_FETCH_LIMIT,
        request,
        context
      );
    },
    enabled,
    select,
  });
}

/**
 * Given a bot log's ID, find it's corresponding router log
 */
export function useBotRouterLog(botLogId: Log["id"]) {
  const logKeys = useLogKeys();

  const botLogQuery = useLog(botLogId, {
    select(response) {
      if (response.data.startTime === null) {
        return undefined;
      } else {
        return {
          limit: 1,
          offset: 0,
          nameLike: "router_",
          groupId: response.data.groupId,
          // A router log is associated with a bot log if the router log's
          // bounds contain the bot log's start time
          startTimeLte: response.data.startTime,
          endTimeGte: response.data.startTime,
        };
      }
    },
  });

  const enabled = botLogQuery.data !== undefined;
  const request = botLogQuery.data ?? {};

  return useQuery({
    queryKey: logKeys.list(request),
    queryFn(context) {
      invariant(enabled, "Query shouldn't be running");

      const { logApi } = getClients();

      return logApi.listLogs(request, context);
    },
    enabled,
    select(response): MinibotLog | null {
      const maybeLog = response.data.at(0);

      if (maybeLog === undefined) {
        return null;
      }

      return selectMinibotLog(maybeLog);
    },
  });
}
