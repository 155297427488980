/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface GroupCreateRequest
 */
export interface GroupCreateRequest {
  /**
   *
   * @type {string}
   * @memberof GroupCreateRequest
   */
  name: string;
}

export function GroupCreateRequestFromJSON(json: any): GroupCreateRequest {
  return GroupCreateRequestFromJSONTyped(json, false);
}

export function GroupCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
  };
}

export function GroupCreateRequestToJSON(
  value?: GroupCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
  };
}
