/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  UserRole,
  UserRoleFromJSON,
  UserRoleFromJSONTyped,
  UserRoleToJSON,
} from "./UserRole";

/**
 *
 * @export
 * @interface GroupAssociation
 */
export interface GroupAssociation {
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof GroupAssociation
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof GroupAssociation
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof GroupAssociation
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof GroupAssociation
   */
  groupId: string;
  /**
   *
   * @type {UserRole}
   * @memberof GroupAssociation
   */
  role: UserRole;
}

export function GroupAssociationFromJSON(json: any): GroupAssociation {
  return GroupAssociationFromJSONTyped(json, false);
}

export function GroupAssociationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupAssociation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    userId: json["user_id"],
    groupId: json["group_id"],
    role: UserRoleFromJSON(json["role"]),
  };
}

export function GroupAssociationToJSON(value?: GroupAssociation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    user_id: value.userId,
    group_id: value.groupId,
    role: UserRoleToJSON(value.role),
  };
}
