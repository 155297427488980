/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ProcessStatus = {
  Ready: "ready",
  PreProcessing: "pre-processing",
  PreProcessed: "pre-processed",
  Processing: "processing",
  Processed: "processed",
  PostProcessing: "post-processing",
  Complete: "complete",
} as const;
export type ProcessStatus = typeof ProcessStatus[keyof typeof ProcessStatus];

export function ProcessStatusFromJSON(json: any): ProcessStatus {
  return ProcessStatusFromJSONTyped(json, false);
}

export function ProcessStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProcessStatus {
  return json as ProcessStatus;
}

export function ProcessStatusToJSON(value?: ProcessStatus | null): any {
  return value as any;
}
