import { omit } from "lodash";
import queryString from "query-string";
import type { Location } from "react-router-dom";
import type { DeepPartial } from "ts-essentials";
import type { RequireExactlyOne } from "type-fest";
import type { Extraction, Log } from "./services/datastore";
import type { Maybe } from "./types";

interface BaseQuery {
  url?: Maybe<string>;
}

export const INDEX = "/";

export interface IndexState {
  invalidOrigin?: string;
  unknownPlatformOrigin?: string;
  unmatchedPath?: string;
}

export function makeIndexLocation(
  query?: BaseQuery,
  state?: RequireExactlyOne<IndexState>
): Partial<Location> {
  return {
    pathname: INDEX,
    search: makeSearchString(query),
    state,
  };
}

export const PLAYER = "/player" as const;

export type PlayerQuery = BaseQuery &
  DeepPartial<{ logId: Log["id"]; t: number }>;

export function makePlayerLocation(query?: PlayerQuery): Partial<Location> {
  return {
    pathname: PLAYER,
    search: makeSearchString(query),
  };
}

export const EXTRACTIONS = "/extractions" as const;

export type ExtractionsQuery = BaseQuery &
  DeepPartial<{
    extractionId: Extraction["id"];
  }>;

export function makeExtractionsLocation(
  query?: ExtractionsQuery
): Partial<Location> {
  return {
    pathname: EXTRACTIONS,
    search: makeSearchString(query),
  };
}

function makeSearchString(query: Record<string, any> | undefined = {}): string {
  // Hack for static DataStore deployments: remove the `url` query param if
  // present so it's never stored in the URL. Keeps the rest of Studio from
  // needing to update how it generates paths
  const stringifiedQuery = queryString.stringify(omit(query, ["url"]), {
    skipNull: true,
    skipEmptyString: true,
  });

  return `?${stringifiedQuery}`;
}
