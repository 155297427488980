/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  UserFetchResponse,
  UserFetchResponseFromJSON,
  UserFetchResponseToJSON,
} from "../models";
import * as runtime from "../runtime";

/**
 *
 */
export class MeApi extends runtime.BaseAPI {
  /**
   * Get Me
   */
  async getMeRaw(
    this: MeApi,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserFetchResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/me`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Me
   */
  async getMe(
    this: MeApi,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserFetchResponse> {
    const response = await this.getMeRaw(initOverrides);
    return await response.value();
  }
}
