import React from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import Center from "./Center";

interface ErrorProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export default function Error({ icon, children }: ErrorProps) {
  return (
    <Center>
      {icon == null ? <ErrorIcon fontSize="large" color="error" /> : icon}
      {children}
    </Center>
  );
}
