import React, { useState } from "react";
import { Error, ExpandMore, Warning } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import { matchSorter } from "match-sorter";
import BreakableText from "../../../../../../components/BreakableText";
import CircularProgressIcon from "../../../../../../components/CircularProgressIcon";
import type { useMarkerTopics } from "./hooks";
import type { StaticMarkerQueryStatus, SummaryTopic } from "./types";

export type MarkerTopicSelectProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  markerTopics: ReturnType<typeof useMarkerTopics>;
};

export default function MarkerTopicSelect({
  open,
  setOpen,
  markerTopics: { topics, selected, handleTopicClick },
}: MarkerTopicSelectProps) {
  const [filter, setFilter] = useState("");

  const filteredTopics =
    filter === ""
      ? topics
      : matchSorter(topics, filter, {
          keys: ["name"],
          threshold: matchSorter.rankings.CONTAINS,
          sorter: (matchItems) => matchItems,
        });

  const sortedTopics = orderBy(
    filteredTopics,
    [(topic) => (selected.has(topic.id) ? 1 : 0), "name"],
    ["desc", "asc"]
  );

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value);
  }

  function makeClickHandler(topic: SummaryTopic) {
    return function handleClick() {
      handleTopicClick(topic);
    };
  }

  return (
    <Box
      sx={{
        // Position above backdrop
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        height: 1,
        overflowY: "auto",
        width: "min(80%, 300px)",
      }}
    >
      <Accordion
        square
        disableGutters
        expanded={open}
        onChange={(e, expanded) => setOpen(expanded)}
        TransitionProps={{
          timeout: 0,
        }}
      >
        <AccordionSummary
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper": {
              transitionDuration: "0s",
            },
          }}
          expandIcon={<ExpandMore />}
        >
          <Typography>Marker Topics</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List
            subheader={
              <ListSubheader
                sx={{ bgcolor: "inherit" }}
                disableSticky
                disableGutters
              >
                <TextField
                  label="Filter topics"
                  fullWidth
                  size="small"
                  value={filter}
                  onChange={handleFilterChange}
                />
              </ListSubheader>
            }
          >
            {sortedTopics.map((topic) => {
              const status = selected.get(topic.id);
              const isSelected = status !== undefined;

              return (
                <ListItem key={topic.id} disablePadding>
                  <ListItemButton
                    sx={{ px: 1 }}
                    selected={isSelected}
                    onClick={makeClickHandler(topic)}
                  >
                    {status !== "success" && status !== undefined && (
                      <ListItemIcon sx={{ minWidth: "auto", mr: 1 }}>
                        {renderStatusIcon(status)}
                      </ListItemIcon>
                    )}
                    <ListItemText>
                      <BreakableText separator={/(\/)/}>
                        {topic.name}
                      </BreakableText>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function renderStatusIcon(status: StaticMarkerQueryStatus) {
  if (status === "loading") {
    return <CircularProgressIcon />;
  } else if (status === "error") {
    return <Error color="error" titleAccess="Error loading markers" />;
  } else if (status === "absent") {
    return <Warning color="warning" titleAccess="No markers to show" />;
  } else {
    return null;
  }
}
