import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { configureAuth, configureGeo, initAppConfig } from "./config";

const rootElement = document.getElementById("root");

initAppConfig().then(() => {
  configureAuth();
  configureGeo();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    rootElement
  );
});
