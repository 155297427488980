import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import { some } from "lodash";
import { useHasPermission } from "../../domain/auth";
import { getClients } from "../../domain/datastores";
import { mergeEnabledOption, selectData, useLog } from "../../queries";
import type {
  Extraction,
  ExtractionFetchResponse,
  ExtractionListResponse,
  ExtractionUpdateRequest,
  ListExtractionsRequest,
} from "../../services/datastore";
import type { Maybe, ResolvedKeyFactory } from "../../types";

export function useExtractionKeys() {
  const factory = {
    all: ["extractions"] as const,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListExtractionsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (extractionId: Maybe<Extraction["id"]>) =>
      [...factory.details(), extractionId] as const,
  } as const;

  return factory;
}

export type ExtractionKeys = ResolvedKeyFactory<typeof useExtractionKeys>;

export function useExtractions<TData = ExtractionListResponse>(
  request: ListExtractionsRequest,
  options?: UseQueryOptions<
    ExtractionListResponse,
    unknown,
    TData,
    ExtractionKeys["list"]
  >
) {
  return useQuery({
    queryKey: useExtractionKeys().list(request),
    queryFn(context) {
      const { extractionApi } = getClients();

      return extractionApi.listExtractions(request, context);
    },
    ...options,
  });
}

export function useExtraction<TData = ExtractionFetchResponse>(
  extractionId: Maybe<Extraction["id"]>,
  options?: Omit<
    UseQueryOptions<
      ExtractionFetchResponse,
      unknown,
      TData,
      ExtractionKeys["detail"]
    >,
    "initialData"
  >
) {
  const queryClient = useQueryClient();

  const extractionKeys = useExtractionKeys();

  return useQuery({
    queryKey: extractionKeys.detail(extractionId),
    queryFn(context) {
      invariant(extractionId != null, "Extraction ID must be defined");

      const { extractionApi } = getClients();

      return extractionApi.getExtraction({ extractionId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, extractionId != null),
    initialData() {
      const listQueries = queryClient.getQueriesData<ExtractionListResponse>({
        queryKey: extractionKeys.lists(),
        predicate: (query) => query.state.status === "success",
      });

      for (const [, query] of listQueries) {
        if (query === undefined) {
          continue;
        }

        for (const extraction of query.data) {
          if (extraction.id === extractionId) {
            return { data: extraction };
          }
        }
      }
    },
  });
}

export function useCreateExtractionPresignedUrl() {
  return useMutation({
    async mutationFn({ id, status, s3Key }: Extraction) {
      invariant(
        status === "complete" && s3Key !== null,
        "Extraction is not complete or S3 key is missing"
      );

      const { extractionApi } = getClients();

      return extractionApi.createExtractionPresignedUrl({
        extractionId: id,
        createPresignedURLRequest: {
          method: "get_object",
          params: {
            key: s3Key,
          },
        },
      });
    },
  });
}

export function useUpdateExtraction(extractionId: Extraction["id"]) {
  const extractionKeys = useExtractionKeys();

  const extractionQuery = useExtraction(extractionId, { select: selectData });
  const logQuery = useLog(extractionQuery.data?.logId, { select: selectData });
  const hasPermission = useHasPermission(logQuery, ["editor", "owner"]);

  const queryClient = useQueryClient();

  return {
    hasPermission,
    ...useMutation({
      async mutationFn(request: ExtractionUpdateRequest) {
        invariant(
          hasPermission,
          "User does not have permission to update extraction"
        );

        const { extractionApi } = getClients();

        return extractionApi.updateExtraction({
          extractionId,
          extractionUpdateRequest: request,
        });
      },
      onSuccess(response) {
        queryClient.setQueryData<ExtractionFetchResponse>(
          extractionKeys.detail(response.data.id),
          response
        );

        queryClient.setQueriesData<ExtractionListResponse>(
          extractionKeys.lists(),
          (listResponse) => {
            if (listResponse === undefined) {
              return undefined;
            }

            const containsExtraction = some(listResponse.data, {
              id: response.data.id,
            });

            if (!containsExtraction) {
              return undefined;
            }

            return {
              ...listResponse,
              data: listResponse.data.map((extraction) =>
                extraction.id === response.data.id ? response.data : extraction
              ),
            };
          }
        );
      },
    }),
  };
}
