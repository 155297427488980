import type {
  QueryObserverSuccessResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { findLast, findLastIndex, range, sortBy } from "lodash";
import type { Record, Topic } from "../../../../../../services/datastore";
import { floorToNearestMultiple } from "../../../../../../utils";
import type { TimestampPageResponse } from "../../../../hooks";
import { createTimestampPageQueryFn } from "../../../../hooks";
import type { useRecordKeys } from "../../../../queries";
import type { StaticMarkerQueryStatus } from "./types";

type TopicRequestsSummary =
  | {
      topicId: Topic["id"];
      hasRequests: false;
    }
  | {
      topicId: Topic["id"];
      hasRequests: true;
      requestCount: number;
      totalCount: number;
      isStatic: boolean;
      debugOffset: number;
    };

type DebugLocationQueryResult =
  | { topicId: Topic["id"]; status: "loading" }
  | { topicId: Topic["id"]; status: "error" }
  | {
      topicId: Topic["id"];
      status: "success";
      data:
        | { isStatic: true; record: Record }
        | { isStatic: false; records: Record[] };
    };

export const FRAME_REQUEST_LIMIT = 25;

export function makeFrameRequests(
  selectedTopics: ReadonlyArray<Topic["id"]>,
  recordCountQueries: ReadonlyArray<UseQueryResult<number>>,
  debugTime: number | null,
  priorRecordsCountQueries: ReadonlyArray<UseQueryResult<number>>,
  recordKeys: ReturnType<typeof useRecordKeys>
) {
  invariant(
    selectedTopics.length === recordCountQueries.length &&
      selectedTopics.length === priorRecordsCountQueries.length,
    "Array lengths are not equal"
  );

  const requestsOptions = {
    summaries: new Array<TopicRequestsSummary>(),
    requiredRequests: new Array<UseQueryOptions<TimestampPageResponse>>(),
    preloadRequests: new Array<UseQueryOptions<TimestampPageResponse>>(),
  };

  selectedTopics.forEach((topicId, index) => {
    const recordCountQuery = recordCountQueries[index];
    const priorRecordsCountQuery = priorRecordsCountQueries[index];
    const priorRecordsCount = priorRecordsCountQuery.data;

    if (
      !recordCountQuery.isSuccess ||
      debugTime === null ||
      priorRecordsCount === undefined
    ) {
      requestsOptions.summaries.push({
        topicId,
        hasRequests: false,
      });

      return;
    }

    // The offset of the most recent record at or prior to the debug time
    const debugOffset = Math.max(0, priorRecordsCount - 1);

    const totalCount = recordCountQuery.data;
    const isStatic = recordCountQuery.data === 1;

    // Offset of this topic's previous record relative to the current record
    const lowerOffset = Math.max(0, debugOffset - 1);
    // Offset of this topic's next record relative to the current record
    const upperOffset = debugOffset + 1;

    const stableLowerOffset = floorToNearestMultiple(
      lowerOffset,
      FRAME_REQUEST_LIMIT
    );

    const requiredRequestOffsets = range(
      stableLowerOffset,
      // Needs to be inclusive of upper offset
      upperOffset + 1,
      FRAME_REQUEST_LIMIT
    );

    const requiredTopicRequests = requiredRequestOffsets.map((offset) =>
      makeRequest(debugTime, priorRecordsCount, topicId, offset, recordKeys)
    );

    requestsOptions.summaries.push({
      topicId,
      hasRequests: true,
      requestCount: requiredTopicRequests.length,
      totalCount,
      isStatic,
      debugOffset,
    });
    requestsOptions.requiredRequests.push(...requiredTopicRequests);

    if (stableLowerOffset !== 0) {
      requestsOptions.preloadRequests.push(
        makeRequest(
          debugTime,
          priorRecordsCount,
          topicId,
          stableLowerOffset - FRAME_REQUEST_LIMIT,
          recordKeys
        )
      );
    }

    const stableLastOffset = requiredRequestOffsets.at(-1);
    if (stableLastOffset !== undefined) {
      requestsOptions.preloadRequests.push(
        makeRequest(
          debugTime,
          priorRecordsCount,
          topicId,
          stableLastOffset + FRAME_REQUEST_LIMIT,
          recordKeys
        )
      );
    }
  });

  return requestsOptions;
}

export function makeFrameQueriesResults(
  requestsSummaries: ReadonlyArray<TopicRequestsSummary>,
  frameQueries: ReadonlyArray<UseQueryResult<TimestampPageResponse>>
): DebugLocationQueryResult[] {
  let queryIndex = 0;

  return requestsSummaries.map((summary) => {
    const { topicId } = summary;

    if (!summary.hasRequests) {
      return {
        topicId,
        status: "loading",
      };
    }

    const { requestCount, totalCount, isStatic, debugOffset } = summary;

    const queries = frameQueries.slice(queryIndex, queryIndex + requestCount);
    queryIndex += requestCount;

    if (queries.every(isSuccessfulQuery)) {
      const [
        {
          data: { offset: lowestOffset },
        },
      ] = queries;
      const mergedRecords = queries.flatMap((query) => query.data.data);

      if (isStatic) {
        return {
          topicId,
          status: "success",
          data: {
            isStatic: true,
            record: mergedRecords[0],
          },
        };
      }

      const localDebugOffset = debugOffset - lowestOffset;

      const records = new Array<Record>();

      if (debugOffset !== 0) {
        records.push(mergedRecords[localDebugOffset - 1]);
      }

      records.push(mergedRecords[localDebugOffset]);

      if (debugOffset !== totalCount - 1) {
        records.push(mergedRecords[localDebugOffset + 1]);
      }

      return {
        topicId,
        status: "success",
        data: {
          isStatic: false,
          records,
        },
      };
    }

    if (queries.some((query) => query.isError)) {
      return {
        topicId,
        status: "error",
      };
    }

    return {
      topicId,
      status: "loading",
    };
  });
}

export function deriveSelectionStatuses(
  selections: ReadonlyArray<Topic["id"]>,
  inDebugMode: boolean,
  playbackTopicStatuses: Map<Topic["id"], StaticMarkerQueryStatus>,
  recordCountQueries: ReadonlyArray<UseQueryResult<number>>,
  priorRecordsCountQueries: ReadonlyArray<UseQueryResult<number>>,
  frameQueries: ReadonlyArray<DebugLocationQueryResult>
): Map<Topic["id"], StaticMarkerQueryStatus> {
  invariant(
    selections.length === recordCountQueries.length &&
      selections.length === priorRecordsCountQueries.length &&
      selections.length === frameQueries.length,
    "Expected all arrays to have matching lengths"
  );

  return new Map(
    selections.map((topicId, index) => {
      const recordCountQueryResult = recordCountQueries[index];
      const priorRecordsCountQuery = priorRecordsCountQueries[index];
      const frameQueryResult = frameQueries[index];

      let status: StaticMarkerQueryStatus;
      if (!inDebugMode) {
        const playbackStatus = playbackTopicStatuses.get(topicId);

        if (playbackStatus === undefined) {
          status = recordCountQueryResult.status;
        } else if (playbackStatus === "success") {
          status = "success";
        } else if (
          playbackStatus === "error" ||
          recordCountQueryResult.status === "error"
        ) {
          status = "error";
        } else {
          status = "loading";
        }
      } else {
        if (frameQueryResult.status === "success") {
          status = "success";
        } else if (
          recordCountQueryResult.status === "error" ||
          priorRecordsCountQuery.status === "error" ||
          frameQueryResult.status === "error"
        ) {
          status = "error";
        } else {
          status = "loading";
        }
      }

      return [topicId, status];
    })
  );
}

export function computeDebugValues(
  debugTime: number | null,
  results: readonly DebugLocationQueryResult[]
) {
  if (debugTime === null || !results.every(isSuccessfulDebugLocationResult)) {
    return {
      previousTimestamp: undefined,
      currentTimestamp: undefined,
      nextTimestamp: undefined,
      records: [],
    };
  }

  const mergedRecords = results.flatMap((result) =>
    result.data.isStatic ? [] : result.data.records
  );

  if (mergedRecords.length === 0) {
    return {
      previousTimestamp: undefined,
      currentTimestamp: undefined,
      nextTimestamp: undefined,
      records: [],
    };
  }

  const sortedRecords = sortBy(mergedRecords, "timestamp");

  const currentRecordIndex = Math.max(
    0,
    findLastIndex(sortedRecords, (record) => record.timestamp <= debugTime)
  );

  const previousRecord =
    currentRecordIndex === 0
      ? undefined
      : sortedRecords[currentRecordIndex - 1];
  const currentRecord = sortedRecords[currentRecordIndex];
  const nextRecord =
    currentRecordIndex === sortedRecords.length - 1
      ? undefined
      : sortedRecords[currentRecordIndex + 1];

  const currentRecords = results.flatMap((result) => {
    if (result.data.isStatic) {
      return result.data.record;
    }

    const currentRecord = findLast(
      result.data.records,
      (record) => record.timestamp <= debugTime
    );

    if (currentRecord === undefined) {
      return [];
    } else {
      return currentRecord;
    }
  });

  return {
    previousTimestamp: previousRecord?.timestamp,
    currentTimestamp: currentRecord.timestamp,
    nextTimestamp: nextRecord?.timestamp,
    records: currentRecords,
  };
}

function makeRequest(
  debugTime: number,
  priorRecordsCount: number,
  topicId: Topic["id"],
  // Should be a multiple of frame request limit
  offset: number,
  recordKeys: ReturnType<typeof useRecordKeys>
): UseQueryOptions<TimestampPageResponse> {
  const request = {
    topicId,
    limit: FRAME_REQUEST_LIMIT,
    offset,
    sort: "asc",
    order: "timestamp",
  };

  return {
    queryKey: recordKeys.list(request),
    queryFn: createTimestampPageQueryFn(
      debugTime,
      priorRecordsCount,
      topicId,
      FRAME_REQUEST_LIMIT,
      offset
    ),
    staleTime: Infinity,
    cacheTime: secondsToMilliseconds(20),
    meta: {
      isMarkerTopic: true,
    },
  };
}

function isSuccessfulQuery(
  query: UseQueryResult<TimestampPageResponse>
): query is QueryObserverSuccessResult<TimestampPageResponse> {
  return query.isSuccess;
}

function isSuccessfulDebugLocationResult(
  result: DebugLocationQueryResult
): result is Extract<DebugLocationQueryResult, { status: "success" }> {
  return result.status === "success";
}
