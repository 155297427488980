/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CreatePresignedURLRequest,
  CreatePresignedURLRequestFromJSON,
  CreatePresignedURLRequestToJSON,
  CreatePresignedURLResponse,
  CreatePresignedURLResponseFromJSON,
  CreatePresignedURLResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LogCreateRequest,
  LogCreateRequestFromJSON,
  LogCreateRequestToJSON,
  LogFetchResponse,
  LogFetchResponseFromJSON,
  LogFetchResponseToJSON,
  LogListResponse,
  LogListResponseFromJSON,
  LogListResponseToJSON,
  LogUpdateRequest,
  LogUpdateRequestFromJSON,
  LogUpdateRequestToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateLogRequest {
  logCreateRequest: LogCreateRequest;
}

export interface CreateLogPresignedUrlRequest {
  logId: string;
  createPresignedURLRequest: CreatePresignedURLRequest;
}

export interface DeleteLogRequest {
  logId: string;
}

export interface GetLogRequest {
  logId: string;
}

export interface ListLogsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  groupId?: string;
  startTimeNull?: boolean;
  startTimeGte?: number;
  startTimeLte?: number;
  endTimeNull?: boolean;
  endTimeGte?: number;
  endTimeLte?: number;
  locked?: boolean;
  name?: string;
  nameLike?: string;
  noteLike?: string;
  contextFilter?: any;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateLogRequest {
  logId: string;
  logUpdateRequest: LogUpdateRequest;
}

/**
 *
 */
export class LogApi extends runtime.BaseAPI {
  /**
   * Required role: [editor, owner, admin]
   * Create Log
   */
  async createLogRaw(
    this: LogApi,
    requestParameters: CreateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LogFetchResponse>> {
    if (
      requestParameters.logCreateRequest === null ||
      requestParameters.logCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "logCreateRequest",
        "Required parameter requestParameters.logCreateRequest was null or undefined when calling createLog."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LogCreateRequestToJSON(requestParameters.logCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [editor, owner, admin]
   * Create Log
   */
  async createLog(
    this: LogApi,
    requestParameters: CreateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LogFetchResponse> {
    const response = await this.createLogRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Log Presigned Url
   */
  async createLogPresignedUrlRaw(
    this: LogApi,
    requestParameters: CreateLogPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreatePresignedURLResponse>> {
    if (
      requestParameters.logId === null ||
      requestParameters.logId === undefined
    ) {
      throw new runtime.RequiredError(
        "logId",
        "Required parameter requestParameters.logId was null or undefined when calling createLogPresignedUrl."
      );
    }

    if (
      requestParameters.createPresignedURLRequest === null ||
      requestParameters.createPresignedURLRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "createPresignedURLRequest",
        "Required parameter requestParameters.createPresignedURLRequest was null or undefined when calling createLogPresignedUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs/{log_id}/presignedUrls`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePresignedURLRequestToJSON(
          requestParameters.createPresignedURLRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePresignedURLResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Log Presigned Url
   */
  async createLogPresignedUrl(
    this: LogApi,
    requestParameters: CreateLogPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreatePresignedURLResponse> {
    const response = await this.createLogPresignedUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Log
   */
  async deleteLogRaw(
    this: LogApi,
    requestParameters: DeleteLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.logId === null ||
      requestParameters.logId === undefined
    ) {
      throw new runtime.RequiredError(
        "logId",
        "Required parameter requestParameters.logId was null or undefined when calling deleteLog."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Log
   */
  async deleteLog(
    this: LogApi,
    requestParameters: DeleteLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteLogRaw(requestParameters, initOverrides);
  }

  /**
   * Get Log
   */
  async getLogRaw(
    this: LogApi,
    requestParameters: GetLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LogFetchResponse>> {
    if (
      requestParameters.logId === null ||
      requestParameters.logId === undefined
    ) {
      throw new runtime.RequiredError(
        "logId",
        "Required parameter requestParameters.logId was null or undefined when calling getLog."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Log
   */
  async getLog(
    this: LogApi,
    requestParameters: GetLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LogFetchResponse> {
    const response = await this.getLogRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * List Logs
   */
  async listLogsRaw(
    this: LogApi,
    requestParameters: ListLogsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LogListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.startTimeNull !== undefined) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeGte !== undefined) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.startTimeLte !== undefined) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.endTimeNull !== undefined) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeGte !== undefined) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.endTimeLte !== undefined) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.locked !== undefined) {
      queryParameters["locked"] = requestParameters.locked;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.noteLike !== undefined) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Logs
   */
  async listLogs(
    this: LogApi,
    requestParameters: ListLogsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LogListResponse> {
    const response = await this.listLogsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Log
   */
  async updateLogRaw(
    this: LogApi,
    requestParameters: UpdateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<LogFetchResponse>> {
    if (
      requestParameters.logId === null ||
      requestParameters.logId === undefined
    ) {
      throw new runtime.RequiredError(
        "logId",
        "Required parameter requestParameters.logId was null or undefined when calling updateLog."
      );
    }

    if (
      requestParameters.logUpdateRequest === null ||
      requestParameters.logUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "logUpdateRequest",
        "Required parameter requestParameters.logUpdateRequest was null or undefined when calling updateLog."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/logs/{log_id}`.replace(
          `{${"log_id"}}`,
          encodeURIComponent(String(requestParameters.logId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: LogUpdateRequestToJSON(requestParameters.logUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Log
   */
  async updateLog(
    this: LogApi,
    requestParameters: UpdateLogRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<LogFetchResponse> {
    const response = await this.updateLogRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
