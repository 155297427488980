import React from "react";
import styled from "@emotion/styled";
import type { ReflexElementProps } from "react-reflex";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import type { StrictOmit } from "ts-essentials";
import { createSafeContext } from "../../../contexts";
import type { LayoutNode, PanelNode } from "./api";
import { usePanelLayoutContext } from "./provider";
import { resizeNode } from "./reducer";

export interface PanelLayoutRootProps {
  children: React.ReactNode;
}

export function PanelLayoutRoot({ children }: PanelLayoutRootProps) {
  const { layout: rootNode } = usePanelLayoutContext();

  return <Node node={rootNode}>{children}</Node>;
}

const PanelRoot = styled.div({
  height: "100%",
  width: "100%",
  position: "relative",
});

interface NodeProps {
  node: LayoutNode;
  children: React.ReactNode;
}

function Node({ node, children }: NodeProps) {
  const { dispatch } = usePanelLayoutContext();

  function makeResizeHandler(
    nodeId: LayoutNode["id"]
  ): ReflexElementProps["onResize"] {
    return ({ component }) =>
      dispatch(resizeNode(nodeId, component.props.flex ?? 0.0));
  }

  if (node.type === "container") {
    return (
      <ReflexContainer orientation={node.orientation}>
        <ReflexElement
          flex={node.firstChild.flex}
          onStopResize={makeResizeHandler(node.firstChild.id)}
        >
          <Node node={node.firstChild}>{children}</Node>
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement
          flex={node.secondChild.flex}
          onStopResize={makeResizeHandler(node.secondChild.id)}
        >
          <Node node={node.secondChild}>{children}</Node>
        </ReflexElement>
      </ReflexContainer>
    );
  } else {
    return (
      <PanelProvider node={node}>
        <PanelRoot>{children}</PanelRoot>
      </PanelProvider>
    );
  }
}

export const [usePanelContext, PanelContext] =
  createSafeContext<PanelNode>("Panel");

type PanelProviderProps = StrictOmit<NodeProps, "node"> & {
  node: PanelNode;
};

function PanelProvider({ node, children }: PanelProviderProps) {
  return <PanelContext.Provider value={node}>{children}</PanelContext.Provider>;
}
