import { getAppConfig } from "../../../config";
import {
  ExtractionApi,
  GroupApi,
  GroupAssociationApi,
  IngestionApi,
  LogApi,
  MeApi,
  MessageTypeApi,
  TopicApi,
  UserApi,
} from "../../../services/datastore";
import { createApiClientConfiguration } from "../../../services/logrus";
import type { DataStoreClients } from "./types";

export function getClients(): DataStoreClients {
  const configuration = createApiClientConfiguration(
    getAppConfig().staticApiEndpoint
  );

  return {
    extractionApi: new ExtractionApi(configuration),
    groupApi: new GroupApi(configuration),
    groupAssociationApi: new GroupAssociationApi(configuration),
    ingestionApi: new IngestionApi(configuration),
    logApi: new LogApi(configuration),
    meApi: new MeApi(configuration),
    messageTypeApi: new MessageTypeApi(configuration),
    topicApi: new TopicApi(configuration),
    userApi: new UserApi(configuration),
  };
}
