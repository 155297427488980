/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface RecordUpdateRequest
 */
export interface RecordUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  length?: number;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  dataOffset?: number;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  dataLength?: number;
  /**
   *
   * @type {string}
   * @memberof RecordUpdateRequest
   */
  chunkCompression?: string;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  chunkOffset?: number;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  chunkLength?: number;
  /**
   *
   * @type {string}
   * @memberof RecordUpdateRequest
   */
  s3Bucket?: string;
  /**
   *
   * @type {string}
   * @memberof RecordUpdateRequest
   */
  s3Key?: string;
  /**
   *
   * @type {string}
   * @memberof RecordUpdateRequest
   */
  format?: string;
  /**
   *
   * @type {any}
   * @memberof RecordUpdateRequest
   */
  messageData?: any | null;
  /**
   *
   * @type {any}
   * @memberof RecordUpdateRequest
   */
  context?: any | null;
  /**
   *
   * @type {number}
   * @memberof RecordUpdateRequest
   */
  nanosecond?: number;
  /**
   *
   * @type {Date}
   * @memberof RecordUpdateRequest
   */
  deletedAt?: Date;
}

export function RecordUpdateRequestFromJSON(json: any): RecordUpdateRequest {
  return RecordUpdateRequestFromJSONTyped(json, false);
}

export function RecordUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    offset: !exists(json, "offset") ? undefined : json["offset"],
    length: !exists(json, "length") ? undefined : json["length"],
    dataOffset: !exists(json, "data_offset") ? undefined : json["data_offset"],
    dataLength: !exists(json, "data_length") ? undefined : json["data_length"],
    chunkCompression: !exists(json, "chunk_compression")
      ? undefined
      : json["chunk_compression"],
    chunkOffset: !exists(json, "chunk_offset")
      ? undefined
      : json["chunk_offset"],
    chunkLength: !exists(json, "chunk_length")
      ? undefined
      : json["chunk_length"],
    s3Bucket: !exists(json, "s3_bucket") ? undefined : json["s3_bucket"],
    s3Key: !exists(json, "s3_key") ? undefined : json["s3_key"],
    format: !exists(json, "format") ? undefined : json["format"],
    messageData: !exists(json, "message_data")
      ? undefined
      : json["message_data"],
    context: !exists(json, "context") ? undefined : json["context"],
    nanosecond: !exists(json, "nanosecond") ? undefined : json["nanosecond"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function RecordUpdateRequestToJSON(
  value?: RecordUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    offset: value.offset,
    length: value.length,
    data_offset: value.dataOffset,
    data_length: value.dataLength,
    chunk_compression: value.chunkCompression,
    chunk_offset: value.chunkOffset,
    chunk_length: value.chunkLength,
    s3_bucket: value.s3Bucket,
    s3_key: value.s3Key,
    format: value.format,
    message_data: value.messageData,
    context: value.context,
    nanosecond: value.nanosecond,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
