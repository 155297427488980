import { floor } from "lodash";
import prettyMilliseconds from "pretty-ms";

export interface FormatTimestampOptions extends prettyMilliseconds.Options {
  precision?: number;
  relativeToMs?: number;
}

export default function formatTimestamp(
  timestampMs: number,
  {
    precision,
    relativeToMs = 0,
    colonNotation = true,
    keepDecimalsOnWholeSeconds = true,
    ...prettyMsOptions
  }: FormatTimestampOptions = {}
): string {
  let relativeStampMs = timestampMs - relativeToMs;

  if (precision !== undefined) {
    relativeStampMs = floor(relativeStampMs, precision);
  }

  return prettyMilliseconds(relativeStampMs, {
    colonNotation,
    keepDecimalsOnWholeSeconds,
    ...prettyMsOptions,
  });
}
