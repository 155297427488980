/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface TopicUpdateRequest
 */
export interface TopicUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof TopicUpdateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TopicUpdateRequest
   */
  associatedTopicId?: string;
  /**
   *
   * @type {number}
   * @memberof TopicUpdateRequest
   */
  startTime?: number;
  /**
   *
   * @type {number}
   * @memberof TopicUpdateRequest
   */
  endTime?: number;
  /**
   *
   * @type {number}
   * @memberof TopicUpdateRequest
   */
  recordSize?: number;
  /**
   *
   * @type {number}
   * @memberof TopicUpdateRequest
   */
  recordCount?: number;
  /**
   *
   * @type {object}
   * @memberof TopicUpdateRequest
   */
  context?: object;
  /**
   *
   * @type {boolean}
   * @memberof TopicUpdateRequest
   */
  locked?: boolean;
  /**
   *
   * @type {Date}
   * @memberof TopicUpdateRequest
   */
  deletedAt?: Date;
}

export function TopicUpdateRequestFromJSON(json: any): TopicUpdateRequest {
  return TopicUpdateRequestFromJSONTyped(json, false);
}

export function TopicUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TopicUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    associatedTopicId: !exists(json, "associated_topic_id")
      ? undefined
      : json["associated_topic_id"],
    startTime: !exists(json, "start_time") ? undefined : json["start_time"],
    endTime: !exists(json, "end_time") ? undefined : json["end_time"],
    recordSize: !exists(json, "record_size") ? undefined : json["record_size"],
    recordCount: !exists(json, "record_count")
      ? undefined
      : json["record_count"],
    context: !exists(json, "context") ? undefined : json["context"],
    locked: !exists(json, "locked") ? undefined : json["locked"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function TopicUpdateRequestToJSON(
  value?: TopicUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    associated_topic_id: value.associatedTopicId,
    start_time: value.startTime,
    end_time: value.endTime,
    record_size: value.recordSize,
    record_count: value.recordCount,
    context: value.context,
    locked: value.locked,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
