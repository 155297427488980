import React from "react";
import { Box, Stack } from "@mui/material";

export interface PanelLayoutProps {
  header: React.ReactNode;
  contentRef?: React.RefCallback<HTMLElement>;
  children: React.ReactNode;
}

export default function PanelLayout({
  header,
  contentRef,
  children,
}: PanelLayoutProps) {
  return (
    <Stack sx={{ height: 1, width: 1 }}>
      <Box
        sx={{
          height: 40,
          flexShrink: 0,
          overflow: "hidden",
          px: 0.5,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {header}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          // Overflow cannot be allowed on this element as it could possibly
          // have its size observed via the `contentRef`. This can (and has)
          // led to some nasty bugs if a scrollbar appears, causing the
          // element's content box size to change, causing resizes, and on
          // and on
          overflow: "hidden",
        }}
        ref={contentRef}
      >
        {children}
      </Box>
    </Stack>
  );
}
