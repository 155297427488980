import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { capitalize } from "lodash";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import type { ElementOf } from "ts-essentials";
import Error from "../../components/Error";
import GroupSelect from "../../components/GroupSelect";
import Loading from "../../components/Loading";
import { ReportDuration, NoMetricsError } from "../../services/minibot";
import type { SiteMetrics, MetricName } from "./hooks";
import { useSiteMetrics } from "./hooks";
import { createReportHelper } from "./utils";

const numberFormatter = new Intl.NumberFormat();

export default function MetricsDashboard() {
  const siteMetrics = useSiteMetrics();

  return (
    <Card>
      <CardContent sx={{ position: "relative" }}>
        <Stack direction="row" alignItems="center" sx={{ px: 4 }}>
          <GroupSelect
            label="Site"
            value={siteMetrics.groupId}
            onChange={siteMetrics.selectGroup}
            disableClearable
            loading={siteMetrics.query.isLoading}
            sx={{ width: "30ch" }}
          />
          <TextField
            select
            label="Report Duration"
            value={siteMetrics.reportDuration}
            onChange={(e) =>
              siteMetrics.selectReportDuration(e.target.value as ReportDuration)
            }
            sx={{ width: "25ch", mx: 2 }}
          >
            <MenuItem value={ReportDuration.Hour}>Hour</MenuItem>
            <MenuItem value={ReportDuration.Day}>Day</MenuItem>
            <MenuItem value={ReportDuration.Week}>Week</MenuItem>
            <MenuItem value={ReportDuration.Month}>Month</MenuItem>
          </TextField>
          <IconButton onClick={siteMetrics.showPreviousPeriod}>
            <NavigateBefore />
          </IconButton>
          <Typography>
            {createReportHelper(siteMetrics.reportDuration).formatSelectedDate(
              siteMetrics.date
            )}
          </Typography>
          <IconButton onClick={siteMetrics.showNextPeriod}>
            <NavigateNext />
          </IconButton>
        </Stack>
        {siteMetrics.query.isSuccess ? (
          <>
            <Stack
              component="ul"
              direction="row"
              justifyContent="space-between"
              sx={{ px: 4, mb: 2 }}
            >
              <MetricCategory
                name="Max Active Bots"
                metricKey="maxActiveBotsCount"
                siteMetrics={siteMetrics.query.data}
                activeMetric={siteMetrics.activeMetric}
                onClick={siteMetrics.selectMetric}
              />
              <MetricCategory
                name="Deliveries"
                metricKey="totalDeliveriesCount"
                siteMetrics={siteMetrics.query.data}
                activeMetric={siteMetrics.activeMetric}
                onClick={siteMetrics.selectMetric}
              />
              <MetricCategory
                name="Picks"
                metricKey="totalPicksCount"
                siteMetrics={siteMetrics.query.data}
                activeMetric={siteMetrics.activeMetric}
                onClick={siteMetrics.selectMetric}
              />
              <MetricCategory
                name="Scratches"
                metricKey="totalScratchesCount"
                siteMetrics={siteMetrics.query.data}
                activeMetric={siteMetrics.activeMetric}
                onClick={siteMetrics.selectMetric}
              />
              <MetricCategory
                name="Onboard Halts"
                metricKey="totalOnboardHaltsCount"
                siteMetrics={siteMetrics.query.data}
                activeMetric={siteMetrics.activeMetric}
                onClick={siteMetrics.selectMetric}
              />
            </Stack>
            <MetricsChart
              siteMetrics={siteMetrics.query.data}
              selectGraphedPeriod={siteMetrics.selectGraphedPeriod}
            />
            <Typography color="text.secondary" align="center">
              {capitalize(siteMetrics.query.data.xAxisLabel)}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="right">
              As of {new Date(siteMetrics.query.dataUpdatedAt).toLocaleString()}
            </Typography>
          </>
        ) : siteMetrics.query.isLoading ? (
          <Box sx={{ my: 4 }}>
            <Loading type="circular">
              <Typography variant="h5" component="p">
                Fetching metrics
              </Typography>
            </Loading>
          </Box>
        ) : siteMetrics.query.error instanceof NoMetricsError ? (
          <Box sx={{ my: 4 }}>
            <Error>
              <Typography variant="h5" component="p">
                No metrics for this site
              </Typography>
            </Error>
          </Box>
        ) : (
          <Box sx={{ my: 4 }}>
            <Error>
              <Typography variant="h5" component="p">
                Error fetching metrics
              </Typography>
            </Error>
          </Box>
        )}
        <Backdrop
          sx={{ position: "absolute" }}
          open={siteMetrics.query.isRefetching}
        >
          <CircularProgress />
        </Backdrop>
      </CardContent>
    </Card>
  );
}

interface MetricCategoryProps {
  name: string;
  metricKey: MetricName;
  siteMetrics: SiteMetrics;
  activeMetric: MetricName;
  onClick: (metricName: MetricName) => void;
}

function MetricCategory({
  name,
  metricKey,
  siteMetrics,
  activeMetric,
  onClick,
}: MetricCategoryProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{ borderRadius: "8px" }}
        selected={metricKey === activeMetric}
        onClick={() => onClick(metricKey)}
      >
        <ListItemText
          disableTypography
          secondary={
            <Typography variant="h5" component="p" color="text.secondary">
              {name}
            </Typography>
          }
        >
          <Typography variant="h2" component="p">
            {numberFormatter.format(siteMetrics[metricKey])}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

function MetricsChart({
  siteMetrics,
  selectGraphedPeriod,
}: {
  siteMetrics: SiteMetrics;
  selectGraphedPeriod: (
    startDate: Date,
    currentDuration: ReportDuration
  ) => void;
}) {
  const theme = useTheme();

  function handleBarClick(period: ElementOf<SiteMetrics["periods"]>) {
    selectGraphedPeriod(period.startDate, siteMetrics.duration);
  }

  return (
    <ResponsiveContainer width="100%" height={500}>
      <ComposedChart data={siteMetrics.periods}>
        <CartesianGrid vertical={false} strokeOpacity={0.25} />
        <XAxis
          dataKey="label"
          tickLine={false}
          ticks={siteMetrics.chartTicks}
        />
        <YAxis type="number" tickLine={false} />
        <Bar
          data={siteMetrics.periods}
          dataKey="value"
          fill={theme.palette.primary.dark}
          maxBarSize={150}
          animationDuration={500}
          onClick={handleBarClick}
          {...(siteMetrics.duration !== "hour" && { cursor: "pointer" })}
        />
        <Line
          dataKey="cumulativeValue"
          stroke={theme.palette.secondary.dark}
          fill={theme.palette.secondary.dark}
          animationDuration={500}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
