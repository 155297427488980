import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { RuntimeLayoutProfilesList } from "./models";
import {
  createLayoutProfile,
  deleteLayoutProfile,
  getLayoutProfiles,
} from "./storage";

export const layoutProfileKeys = {
  all: ["layout-profiles"] as const,
  lists: () => [...layoutProfileKeys.all, "list"] as const,
  list: () => [...layoutProfileKeys.lists()] as const,
};

export function useLayoutProfiles<TData = RuntimeLayoutProfilesList>(
  options?: UseQueryOptions<
    RuntimeLayoutProfilesList,
    unknown,
    TData,
    ReturnType<typeof layoutProfileKeys.list>
  >
) {
  return useQuery(layoutProfileKeys.list(), getLayoutProfiles, options);
}

export function useCreateLayoutProfile() {
  const queryClient = useQueryClient();

  return useMutation(createLayoutProfile, {
    onSuccess() {
      return queryClient.invalidateQueries(layoutProfileKeys.lists());
    },
  });
}

export function useDeleteLayoutProfile() {
  const queryClient = useQueryClient();

  return useMutation(deleteLayoutProfile, {
    onSuccess() {
      return queryClient.invalidateQueries(layoutProfileKeys.lists());
    },
  });
}
