import React from "react";
import { LOGQS_ORIGIN } from "../links";

export interface LogQSLinkProps {
  icon?: boolean;
}

export default function LogQSLink({ icon = false }: LogQSLinkProps) {
  return (
    <a className="LogQSLink-root" href={LOGQS_ORIGIN}>
      <img
        className="LogQSLink-image"
        src={icon ? "/logqs_icon.png" : "/logqs_logo.png"}
        alt="LogQS Homepage"
      />
    </a>
  );
}
