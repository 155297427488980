/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ExtractionTopicUpdateRequest
 */
export interface ExtractionTopicUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicUpdateRequest
   */
  startTime?: number;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicUpdateRequest
   */
  endTime?: number;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicUpdateRequest
   */
  frequency?: number;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopicUpdateRequest
   */
  size?: number;
  /**
   *
   * @type {Date}
   * @memberof ExtractionTopicUpdateRequest
   */
  deletedAt?: Date;
}

export function ExtractionTopicUpdateRequestFromJSON(
  json: any
): ExtractionTopicUpdateRequest {
  return ExtractionTopicUpdateRequestFromJSONTyped(json, false);
}

export function ExtractionTopicUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtractionTopicUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    startTime: !exists(json, "start_time") ? undefined : json["start_time"],
    endTime: !exists(json, "end_time") ? undefined : json["end_time"],
    frequency: !exists(json, "frequency") ? undefined : json["frequency"],
    size: !exists(json, "size") ? undefined : json["size"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function ExtractionTopicUpdateRequestToJSON(
  value?: ExtractionTopicUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    start_time: value.startTime,
    end_time: value.endTime,
    frequency: value.frequency,
    size: value.size,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
