/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ExtractionCreateRequest
 */
export interface ExtractionCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ExtractionCreateRequest
   */
  logId: string;
  /**
   *
   * @type {string}
   * @memberof ExtractionCreateRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ExtractionCreateRequest
   */
  note?: string;
  /**
   *
   * @type {object}
   * @memberof ExtractionCreateRequest
   */
  context?: object;
}

export function ExtractionCreateRequestFromJSON(
  json: any
): ExtractionCreateRequest {
  return ExtractionCreateRequestFromJSONTyped(json, false);
}

export function ExtractionCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtractionCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    logId: json["log_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    note: !exists(json, "note") ? undefined : json["note"],
    context: !exists(json, "context") ? undefined : json["context"],
  };
}

export function ExtractionCreateRequestToJSON(
  value?: ExtractionCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    log_id: value.logId,
    name: value.name,
    note: value.note,
    context: value.context,
  };
}
