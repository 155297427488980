import { z } from "zod";

export const EditExtractionFormValues = z.object({
  name: z
    .string()
    .trim()
    .transform((value) => value || null),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EditExtractionFormValues = z.infer<typeof EditExtractionFormValues>;
