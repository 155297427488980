import { sortedIndexBy, sortedLastIndexBy } from "lodash";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import { usePlaybackSource } from "../PlaybackProvider";
import type { DataFilter, PlayerRecord, SampleFrequencyValue } from "../types";
import type { UseRecordChunksResult } from "./useRecordChunks";
import useRecordChunks from "./useRecordChunks";
import {
  calculateRecordWindow,
  intervalToTimeRange,
  timeRangeToInterval,
} from "./utils";

export interface UseRecordWindowOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  windowSizeMs: number;
  sampleFrequency?: SampleFrequencyValue;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
  dataFilter?: DataFilter | DataFilter[];
}

export default function useRecordWindow({
  topicId,
  enabled = true,
  windowSizeMs,
  sampleFrequency,
  includeImage = false,
  bufferBehindMs,
  bufferAheadMs,
  chunkSizeMs,
  dataFilter,
}: UseRecordWindowOptions) {
  const playbackSource = usePlaybackSource();

  let windowBoundsMs: [number, number] | undefined = undefined;
  if (!playbackSource.isLoading) {
    windowBoundsMs = timeRangeToInterval(
      calculateRecordWindow(
        windowSizeMs,
        playbackSource.timestampMs,
        intervalToTimeRange(playbackSource.boundsMs)
      )
    );
  }

  const result = useRecordChunks({
    topicId,
    enabled: enabled && windowBoundsMs !== undefined,
    fetchBoundsMs: playbackSource.boundsMs,
    activeRangeMs: windowBoundsMs,
    sampleFrequency,
    includeImage,
    bufferBehindMs,
    bufferAheadMs,
    chunkSizeMs,
    dataFilter,
  });

  return sliceResult(result);
}

function sliceResult(result: UseRecordChunksResult): UseRecordChunksResult {
  if (result.data === undefined) {
    return result;
  }

  if (result.activeRangeMs === undefined) {
    return result;
  }

  const lowerIndex = sortedIndexBy(
    result.data,
    { timestampMs: result.activeRangeMs[0] } as PlayerRecord,
    "timestampMs"
  );
  const upperIndex = sortedLastIndexBy(
    result.data,
    { timestampMs: result.activeRangeMs[1] } as PlayerRecord,
    "timestampMs"
  );

  return {
    ...result,
    data: result.data.slice(lowerIndex, upperIndex),
  };
}
