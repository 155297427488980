/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface Log
 */
export interface Log {
  /**
   *
   * @type {string}
   * @memberof Log
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof Log
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Log
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Log
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  startTime: number | null;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  endTime: number | null;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  recordSize: number | null;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  recordCount: number | null;
  /**
   *
   * @type {string}
   * @memberof Log
   */
  note: string | null;
  /**
   *
   * @type {object}
   * @memberof Log
   */
  context: object | null;
  /**
   *
   * @type {boolean}
   * @memberof Log
   */
  locked: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  ingestionsTotalCount: number;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  ingestionsQueuedCount: number;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  ingestionsProcessingCount: number;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  ingestionsErroredCount: number;
  /**
   *
   * @type {number}
   * @memberof Log
   */
  ingestionsCompletedCount: number;
}

export function LogFromJSON(json: any): Log {
  return LogFromJSONTyped(json, false);
}

export function LogFromJSONTyped(json: any, ignoreDiscriminator: boolean): Log {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    groupId: json["group_id"],
    name: json["name"],
    startTime: json["start_time"],
    endTime: json["end_time"],
    recordSize: json["record_size"],
    recordCount: json["record_count"],
    note: json["note"],
    context: json["context"],
    locked: json["locked"],
    ingestionsTotalCount: json["ingestions_total_count"],
    ingestionsQueuedCount: json["ingestions_queued_count"],
    ingestionsProcessingCount: json["ingestions_processing_count"],
    ingestionsErroredCount: json["ingestions_errored_count"],
    ingestionsCompletedCount: json["ingestions_completed_count"],
  };
}

export function LogToJSON(value?: Log | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    group_id: value.groupId,
    name: value.name,
    start_time: value.startTime,
    end_time: value.endTime,
    record_size: value.recordSize,
    record_count: value.recordCount,
    note: value.note,
    context: value.context,
    locked: value.locked,
    ingestions_total_count: value.ingestionsTotalCount,
    ingestions_queued_count: value.ingestionsQueuedCount,
    ingestions_processing_count: value.ingestionsProcessingCount,
    ingestions_errored_count: value.ingestionsErroredCount,
    ingestions_completed_count: value.ingestionsCompletedCount,
  };
}
