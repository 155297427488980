import { secondsToMilliseconds } from "date-fns";
import type { Log } from "../services/datastore";

export type DerivedIngestionStatus =
  | "empty"
  | "error"
  | "processing"
  | "complete"
  | "unknown";

export function deriveIngestionStatus(log: Log): DerivedIngestionStatus {
  if (log.ingestionsTotalCount === 0) {
    return "empty";
  }

  if (log.ingestionsErroredCount > 0) {
    return "error";
  }

  if (log.ingestionsQueuedCount > 0 || log.ingestionsProcessingCount > 0) {
    return "processing";
  }

  if (log.ingestionsCompletedCount === log.ingestionsTotalCount) {
    return "complete";
  }

  return "unknown";
}

export interface LogBounds {
  startTimeMs: number | null;
  endTimeMs: number | null;
}

export function calculateLogBoundsInMs(log: Log): LogBounds {
  return {
    startTimeMs:
      log.startTime !== null
        ? secondsToMilliseconds(Math.floor(log.startTime))
        : null,
    endTimeMs:
      log.endTime !== null
        ? secondsToMilliseconds(Math.ceil(log.endTime))
        : null,
  };
}
