export interface LogoProps {
  icon?: boolean;
  alt?: string;
}

export default function Logo({ icon = false, alt = "LogQS Icon" }: LogoProps) {
  return (
    <img
      className="logqs-logo"
      src={icon ? "/logqs_icon.png" : "/logqs_logo.png"}
      alt={alt}
    />
  );
}
