import React from "react";
import type { HelmetProps as ReactHelmetProps } from "react-helmet-async";
import { Helmet as ReactHelmet } from "react-helmet-async";

export type HelmetProps = React.PropsWithChildren<
  Omit<ReactHelmetProps, "titleTemplate">
>;

export default function Helmet(props: HelmetProps) {
  return <ReactHelmet {...props} titleTemplate="%s - Studio" />;
}
