/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  UserRole,
  UserRoleFromJSON,
  UserRoleFromJSONTyped,
  UserRoleToJSON,
} from "./UserRole";

/**
 *
 * @export
 * @interface GroupAssociationCreateRequest
 */
export interface GroupAssociationCreateRequest {
  /**
   *
   * @type {string}
   * @memberof GroupAssociationCreateRequest
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof GroupAssociationCreateRequest
   */
  groupId: string;
  /**
   *
   * @type {UserRole}
   * @memberof GroupAssociationCreateRequest
   */
  role: UserRole;
}

export function GroupAssociationCreateRequestFromJSON(
  json: any
): GroupAssociationCreateRequest {
  return GroupAssociationCreateRequestFromJSONTyped(json, false);
}

export function GroupAssociationCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupAssociationCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: json["user_id"],
    groupId: json["group_id"],
    role: UserRoleFromJSON(json["role"]),
  };
}

export function GroupAssociationCreateRequestToJSON(
  value?: GroupAssociationCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    user_id: value.userId,
    group_id: value.groupId,
    role: UserRoleToJSON(value.role),
  };
}
