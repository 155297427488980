import React, { useEffect, useMemo, useState } from "react";
import type { AppBarProps } from "@mui/material";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  IconButton,
  styled,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { find } from "lodash";
import type { StrictOmit, ValueOf } from "ts-essentials";
import { createSafeContext } from "../contexts";
import { useSessionStorage } from "../hooks";

export const ScreenConfiguration = {
  Mobile: "mobile",
  Desktop: "desktop",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ScreenConfiguration = ValueOf<typeof ScreenConfiguration>;

export interface LayoutStateContextValue {
  isGlobalNavigationOpen: boolean;
  setIsGlobalNavigationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sideSheetState: string | null;
  setSideSheetState: React.Dispatch<React.SetStateAction<string | null>>;
}

export const [useLayoutStateContext, LayoutStateContext] =
  createSafeContext<LayoutStateContextValue>("LayoutState");

const DESKTOP_NAV_STATE_STORAGE_KEY = "desktop-global-nav-open";

export interface LayoutStateProviderProps {
  initialSideSheetState?: string;
  children: React.ReactNode;
}

// Must be rendered above the <Layout /> component. Allows maintaining
// layout state even if <Layout /> gets remounted in a given view.
// TODO: See if there are better ways of handling layout in app
export function LayoutStateProvider({
  initialSideSheetState,
  children,
}: LayoutStateProviderProps) {
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  // Global navigation defaults to open for desktop screen configuration. State
  // is synced with session storage to preserve it across view changes and page
  // refreshes.
  const [isDesktopGlobalNavOpen, setIsDesktopGlobalNavOpen] = useSessionStorage(
    DESKTOP_NAV_STATE_STORAGE_KEY,
    true,
    Boolean
  );
  // Global navigation defaults to closed for mobile screen configuration.
  // Additionally, it's only kept in memory for mobile configuration since the
  // nav will be shown as a modal. It wouldn't be good UX for the nav to
  // be open on page refresh just because it was open prior to refresh
  const [isMobileGlobalNavOpen, setIsMobileGlobalNavOpen] = useState(false);

  const [sideSheetState, setSideSheetState] = useState(
    initialSideSheetState ?? null
  );

  useEffect(
    function handleScreenConfigurationChange() {
      // Regardless of how the configuration changed, the mobile global nav
      // should always be closed. Especially import if the nav was open and the
      // user went from mobile -> desktop -> mobile: don't want the nav open
      // when they return to mobile configuration.
      setIsMobileGlobalNavOpen(false);

      if (isMobileConfiguration) {
        // Going from desktop -> mobile with the side sheet open should result
        // in it closing. Accomplishing this in an effect isn't ideal though as
        // it could cause a flash where the side sheet is open on screen only
        // to immediately close.
        setSideSheetState(null);
      }
    },
    [isMobileConfiguration]
  );

  let isGlobalNavigationOpen: LayoutStateContextValue["isGlobalNavigationOpen"];
  let setIsGlobalNavigationOpen: LayoutStateContextValue["setIsGlobalNavigationOpen"];
  if (isMobileConfiguration) {
    isGlobalNavigationOpen = isMobileGlobalNavOpen;
    setIsGlobalNavigationOpen = setIsMobileGlobalNavOpen;
  } else {
    isGlobalNavigationOpen = isDesktopGlobalNavOpen;
    setIsGlobalNavigationOpen = setIsDesktopGlobalNavOpen;
  }

  const value = useMemo(
    () => ({
      isGlobalNavigationOpen,
      setIsGlobalNavigationOpen,
      sideSheetState,
      setSideSheetState,
    }),
    [isGlobalNavigationOpen, setIsGlobalNavigationOpen, sideSheetState]
  );

  return (
    <LayoutStateContext.Provider value={value}>
      {children}
    </LayoutStateContext.Provider>
  );
}

interface OpenProps {
  navOpen: boolean;
  sheetOpen: boolean;
}

const GLOBAL_NAVIGATION_OPEN_WIDTH_PX = 220;
const GLOBAL_NAVIGATION_CLOSED_WIDTH_PX = 64;
const EFFECTIVE_GLOBAL_NAVIGATION_OPEN_WIDTH = `min(${GLOBAL_NAVIGATION_OPEN_WIDTH_PX}px, 80%)`;

const SIDE_SHEET_WIDTH_PX = 400;
const EFFECTIVE_SIDE_SHEET_WIDTH = `min(${SIDE_SHEET_WIDTH_PX}px, 80%)`;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "navOpen",
})<AppBarProps & StrictOmit<OpenProps, "sheetOpen">>(({ theme, navOpen }) => ({
  flexShrink: 0,
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${GLOBAL_NAVIGATION_CLOSED_WIDTH_PX}px)`,
    marginLeft: GLOBAL_NAVIGATION_CLOSED_WIDTH_PX,
    zIndex: theme.zIndex.drawer + 1,
    ...(navOpen && {
      width: `calc(100% - ${GLOBAL_NAVIGATION_OPEN_WIDTH_PX}px)`,
      marginLeft: GLOBAL_NAVIGATION_OPEN_WIDTH_PX,
    }),
  },
}));

const ContentArea = styled("div")({
  // Direct child of `#root` div which is a 100vh flex container. Should
  // grow to fill remaining space below app bar
  flexGrow: 1,
  minHeight: 0,
  display: "flex",
  flexDirection: "row",
});

const NavDrawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: EFFECTIVE_GLOBAL_NAVIGATION_OPEN_WIDTH,
  overflowX: "hidden",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: EFFECTIVE_GLOBAL_NAVIGATION_OPEN_WIDTH,
    padding: theme.spacing(2, 1, 5),
  },
  [theme.breakpoints.up("lg")]: {
    ...(!open && {
      width: GLOBAL_NAVIGATION_CLOSED_WIDTH_PX,
      "& .MuiDrawer-paper": {
        width: GLOBAL_NAVIGATION_CLOSED_WIDTH_PX,
      },
    }),
  },
}));

const Main = styled("main")({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
});

const SheetDrawer = styled(MuiDrawer)(({ open, theme }) => ({
  width: open ? EFFECTIVE_SIDE_SHEET_WIDTH : 0,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: open ? EFFECTIVE_SIDE_SHEET_WIDTH : 0,
    padding: theme.spacing(2, 2, 5),
  },
}));

export interface LayoutProps {
  header: React.ReactNode;
  globalNavigation: React.ReactNode;
  sideSheet?: React.ReactNode;
  children: React.ReactNode;
}

export default function Layout({
  header,
  globalNavigation,
  sideSheet,
  children,
}: LayoutProps) {
  const {
    isGlobalNavigationOpen,
    setIsGlobalNavigationOpen,
    sideSheetState,
    setSideSheetState,
  } = useLayoutStateContext();
  const isMobileConfiguration =
    useScreenConfiguration() === ScreenConfiguration.Mobile;

  const isSideSheetOpen = sideSheetState !== null;

  return (
    <>
      <AppBar position="static" navOpen={isGlobalNavigationOpen}>
        {header}
      </AppBar>
      <ContentArea>
        <NavDrawer
          anchor="left"
          variant={isMobileConfiguration ? "temporary" : "permanent"}
          transitionDuration={0}
          open={isGlobalNavigationOpen}
          onClose={() => setIsGlobalNavigationOpen(false)}
        >
          {globalNavigation}
        </NavDrawer>
        <Main>{children}</Main>
        <SheetDrawer
          anchor="right"
          variant={isMobileConfiguration ? "temporary" : "persistent"}
          transitionDuration={0}
          open={isSideSheetOpen}
          onClose={() => setSideSheetState(null)}
        >
          {!isMobileConfiguration && <Toolbar variant="dense" />}
          {sideSheet}
        </SheetDrawer>
      </ContentArea>
    </>
  );
}

export interface SideSheetTriggerProps {
  title: string;
  sidebarId: string;
  icon: React.ReactNode;
}

export function SideSheetTrigger({
  title,
  sidebarId,
  icon,
}: SideSheetTriggerProps) {
  const { sideSheetState, setSideSheetState } = useLayoutStateContext();

  function handleClick() {
    setSideSheetState(sideSheetState === sidebarId ? null : sidebarId);
  }

  return (
    <Tooltip title={title}>
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  );
}

export interface SidebarConfigItem {
  id: string;
  element: JSX.Element;
}

export interface SidebarSwitchProps {
  config: SidebarConfigItem[];
}

export function SidebarSwitch({ config }: SidebarSwitchProps) {
  const { sideSheetState } = useLayoutStateContext();

  if (sideSheetState === null) {
    return null;
  }

  // If a matching config item is found, render its element. Otherwise render
  // nothing (though that shouldn't be expected to happen).
  return find(config, { id: sideSheetState })?.element ?? null;
}

export function useScreenConfiguration(): ScreenConfiguration {
  const theme = useTheme();
  const isMobileConfiguration = useMediaQuery(theme.breakpoints.down("lg"), {
    noSsr: true,
  });

  return isMobileConfiguration
    ? ScreenConfiguration.Mobile
    : ScreenConfiguration.Desktop;
}
