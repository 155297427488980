import type { ReportDuration } from "../../services/minibot";

const DAYS_IN_WEEK = 7;

function createFormatter(
  options: Omit<Intl.DateTimeFormatOptions, "timeZone">
): Intl.DateTimeFormat {
  return new Intl.DateTimeFormat(undefined, { timeZone: "UTC", ...options });
}

export interface ReportHelper {
  formatSelectedDate(date: Date): string;

  formatBinLabel(date: Date): string;

  calculatePreviousPeriodStart(currentPeriodStart: Date): Date;

  calculateNextPeriodStart(currentPeriodStart: Date): Date;
}

class MonthReportHelper implements ReportHelper {
  static readonly #dateFormatter = createFormatter({
    year: "numeric",
    month: "long",
  });
  static readonly #binFormatter = createFormatter({
    month: "numeric",
    day: "numeric",
  });

  formatSelectedDate(date: Date): string {
    return `${MonthReportHelper.#dateFormatter.format(date)} UTC`;
  }

  formatBinLabel(date: Date): string {
    return MonthReportHelper.#binFormatter.format(date);
  }

  calculatePreviousPeriodStart(currentPeriodStart: Date): Date {
    const previousPeriodStart = new Date(currentPeriodStart);
    previousPeriodStart.setUTCMonth(previousPeriodStart.getUTCMonth() - 1);

    return previousPeriodStart;
  }

  calculateNextPeriodStart(currentPeriodStart: Date): Date {
    const nextPeriodStart = new Date(currentPeriodStart);
    nextPeriodStart.setUTCMonth(nextPeriodStart.getUTCMonth() + 1);

    return nextPeriodStart;
  }
}

class WeekReportHelper implements ReportHelper {
  static readonly #dateFormatter = createFormatter({
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });
  static readonly #binFormatter = createFormatter({
    month: "numeric",
    day: "numeric",
  });

  formatSelectedDate(date: Date): string {
    return `${WeekReportHelper.#dateFormatter.format(date)} UTC`;
  }

  formatBinLabel(date: Date): string {
    return WeekReportHelper.#binFormatter.format(date);
  }

  calculatePreviousPeriodStart(currentPeriodStart: Date): Date {
    const previousPeriodStart = new Date(currentPeriodStart);
    previousPeriodStart.setUTCDate(
      previousPeriodStart.getUTCDate() - DAYS_IN_WEEK
    );

    return previousPeriodStart;
  }

  calculateNextPeriodStart(currentPeriodStart: Date): Date {
    const nextPeriodStart = new Date(currentPeriodStart);
    nextPeriodStart.setUTCDate(nextPeriodStart.getUTCDate() + DAYS_IN_WEEK);

    return nextPeriodStart;
  }
}

class DayReportHelper implements ReportHelper {
  static readonly #dateFormatter = createFormatter({
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
  });
  static readonly #binFormatter = createFormatter({
    hour: "numeric",
    hourCycle: "h23",
  });

  formatSelectedDate(date: Date): string {
    return `${DayReportHelper.#dateFormatter.format(date)} UTC`;
  }

  formatBinLabel(date: Date): string {
    return DayReportHelper.#binFormatter.format(date);
  }

  calculatePreviousPeriodStart(currentPeriodStart: Date): Date {
    const previousPeriodStart = new Date(currentPeriodStart);
    previousPeriodStart.setUTCDate(previousPeriodStart.getUTCDate() - 1);

    return previousPeriodStart;
  }

  calculateNextPeriodStart(currentPeriodStart: Date): Date {
    const nextPeriodStart = new Date(currentPeriodStart);
    nextPeriodStart.setUTCDate(nextPeriodStart.getUTCDate() + 1);

    return nextPeriodStart;
  }
}

class HourReportHelper implements ReportHelper {
  static readonly #dateFormatter = createFormatter({
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hourCycle: "h23",
  });
  static readonly #binFormatter = createFormatter({
    minute: "numeric",
  });

  formatSelectedDate(date: Date): string {
    return `${HourReportHelper.#dateFormatter.format(date)} UTC`;
  }

  formatBinLabel(date: Date): string {
    return HourReportHelper.#binFormatter.format(date);
  }

  calculatePreviousPeriodStart(currentPeriodStart: Date): Date {
    const previousPeriodStart = new Date(currentPeriodStart);
    previousPeriodStart.setUTCHours(previousPeriodStart.getUTCHours() - 1);

    return previousPeriodStart;
  }

  calculateNextPeriodStart(currentPeriodStart: Date): Date {
    const nextPeriodStart = new Date(currentPeriodStart);
    nextPeriodStart.setUTCHours(nextPeriodStart.getUTCHours() + 1);

    return nextPeriodStart;
  }
}

export function convertToNewPeriod(
  currentPeriodStart: Date,
  newDuration: ReportDuration
): Date {
  switch (newDuration) {
    case "month": {
      const newPeriodStart = new Date(currentPeriodStart);
      newPeriodStart.setUTCDate(1);
      newPeriodStart.setUTCHours(0);

      return newPeriodStart;
    }
    case "week": {
      const newPeriodStart = new Date(currentPeriodStart);
      newPeriodStart.setUTCDate(
        currentPeriodStart.getUTCDate() - currentPeriodStart.getUTCDay()
      );
      newPeriodStart.setUTCHours(0);

      return newPeriodStart;
    }
    case "day": {
      const newPeriodStart = new Date(currentPeriodStart);
      newPeriodStart.setUTCHours(0);

      return newPeriodStart;
    }
    case "hour": {
      return currentPeriodStart;
    }
  }
}

export function createReportHelper(
  reportDuration: ReportDuration
): ReportHelper {
  switch (reportDuration) {
    case "month":
      return new MonthReportHelper();
    case "week":
      return new WeekReportHelper();
    case "day":
      return new DayReportHelper();
    case "hour":
      return new HourReportHelper();
  }
}
