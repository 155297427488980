import { Tooltip } from "@mui/material";
import { TimerSand } from "mdi-material-ui";
import prettyMilliseconds from "pretty-ms";

export interface StaleNotificationProps {
  staleTimeMs: number;
}

export default function StaleNotification({
  staleTimeMs,
}: StaleNotificationProps) {
  const formattedTimeSinceLastMarker = prettyMilliseconds(staleTimeMs, {
    colonNotation: true,
  });

  return (
    <Tooltip
      title={`${formattedTimeSinceLastMarker} since last marker published`}
      placement="left"
    >
      <TimerSand
        sx={{
          position: "absolute",
          top: (theme) => theme.spacing(1),
          right: (theme) => theme.spacing(1),
        }}
      />
    </Tooltip>
  );
}
