import { secondsToMilliseconds } from "date-fns";
import { floor, sortBy } from "lodash";
import { z } from "zod";
import type { Log } from "../../../../services/datastore";
import { calculateLogBoundsInMs } from "../../../../utils/logs";
import type { TimeRange } from "../../types";
import type { MinibotLog } from "./types";

export interface LogNameParts {
  type: "router" | "bot";
  name: string;
  date: string;
}

/*
 * Group 1 - "type": what type of log is this? Can either be "router" or "bot"
 * Group 2 - "name": the name of the router or bot
 * Group 3 - "date": when the log was recorded. Router and bot logs sharing the
 *   same ending date string are considered to be linked.
 */
const LOG_NAME_RE =
  /^(?<type>router|bot)_(?<name>[A-Za-z0-9-]+)_(?<date>\d{4}-\d{2}-\d{2})$/;

export function parseLogNameParts(logName: Log["name"]): LogNameParts | null {
  const match = logName.match(LOG_NAME_RE);

  if (match === null) {
    return null;
  }

  return {
    type: match.groups?.type,
    name: match.groups?.name,
    date: match.groups?.date,
  } as LogNameParts;
}

export function getBotName(logName: Log["name"]): string | null {
  const nameParts = parseLogNameParts(logName);

  if (nameParts?.type !== "bot") {
    return null;
  }

  return nameParts.name;
}

const ingestionsSchema = z
  .record(
    z.string().uuid(),
    z
      .object({
        start_time: z.number().nonnegative().nullable(),
        end_time: z.number().nonnegative().nullable(),
      })
      .transform((ingestion): TimeRange | null => {
        const { start_time, end_time } = ingestion;

        if (start_time === null || end_time === null) {
          return null;
        }

        return {
          startTimeMs: secondsToMilliseconds(floor(start_time, 1)),
          endTimeMs: secondsToMilliseconds(floor(end_time, 1)),
        };
      })
  )
  .transform((ingestionsRecord): TimeRange[] => {
    return sortBy(
      Object.values(ingestionsRecord).flatMap((range) => {
        if (range === null) {
          return [];
        } else {
          return range;
        }
      }),
      "startTimeMs"
    );
  });

export const logSummarySchema = z.object({
  ingestions: ingestionsSchema,
  topics: z.record(
    z.string().uuid(),
    z
      .object({
        name: z.string(),
        message_type_name: z.string(),
      })
      .transform((topic) => {
        const { message_type_name, ...rest } = topic;

        return {
          ...rest,
          messageTypeName: message_type_name,
        };
      })
  ),
});

export type LogSummary = z.infer<typeof logSummarySchema>;

export function getLogSummary(log: Log): LogSummary | null {
  const parseResult = logSummarySchema.safeParse(log.context);

  return parseResult.success ? parseResult.data : null;
}

export function selectMinibotLog(log: Log): MinibotLog {
  return {
    ...log,
    ...calculateLogBoundsInMs(log),
    summary: getLogSummary(log),
  };
}
