import { useEffect, useState } from "react";

export default function useDebouncedValue<TValue>(
  value: TValue,
  timeout: number
): TValue {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    function setValueAfterDelay() {
      const timeoutId = setTimeout(() => setDebouncedValue(value), timeout);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [value, timeout]
  );

  return debouncedValue;
}
