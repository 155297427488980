/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface ExtractionTopic
 */
export interface ExtractionTopic {
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof ExtractionTopic
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ExtractionTopic
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ExtractionTopic
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  extractionId: string;
  /**
   *
   * @type {string}
   * @memberof ExtractionTopic
   */
  topicId: string;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopic
   */
  startTime: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopic
   */
  endTime: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopic
   */
  frequency: number | null;
  /**
   *
   * @type {number}
   * @memberof ExtractionTopic
   */
  size: number | null;
}

export function ExtractionTopicFromJSON(json: any): ExtractionTopic {
  return ExtractionTopicFromJSONTyped(json, false);
}

export function ExtractionTopicFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExtractionTopic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    extractionId: json["extraction_id"],
    topicId: json["topic_id"],
    startTime: json["start_time"],
    endTime: json["end_time"],
    frequency: json["frequency"],
    size: json["size"],
  };
}

export function ExtractionTopicToJSON(value?: ExtractionTopic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    extraction_id: value.extractionId,
    topic_id: value.topicId,
    start_time: value.startTime,
    end_time: value.endTime,
    frequency: value.frequency,
    size: value.size,
  };
}
