import React from "react";
import type { TypographyProps, TypographyTypeMap } from "@mui/material";
import { Box, Typography } from "@mui/material";

export type IconicTypographyProps<
  TComponent extends React.ElementType = TypographyTypeMap["defaultComponent"]
> = TypographyProps<TComponent, { component?: TComponent }> & {
  icon: React.ReactNode;
};

export default function IconicTypography<
  TComponent extends React.ElementType = TypographyTypeMap["defaultComponent"]
>(props: IconicTypographyProps<TComponent>) {
  const { icon, children, ...typographyProps } = props;

  return (
    <Typography {...typographyProps}>
      <Box
        component="span"
        sx={{
          display: "inline-block",
          verticalAlign: "middle",
          pr: 2,
          "& .MuiSvgIcon-root": {
            fontSize: "inherit",
          },
        }}
      >
        {icon}
      </Box>
      {children}
    </Typography>
  );
}
