import { useCallback, useRef } from "react";
import type { RefCallback } from "react";
import type { Maybe } from "../types";

/**
 * Given a `Blob` object, returns a callback ref to be attached to an
 * `<img>` element. The callback ref will create an object URL and set it
 * as the `<img>`'s `src` and revoke the object URL when it is no longer
 * needed. If the `blob` parameter isn't defined the callback ref will
 * do nothing when called.
 *
 * **Important:** the `<img>` element to which the returned callback ref
 * is to be attached **should not have a `src` prop set**. The `src` prop
 * is managed by the callback ref but the ref will only run when the `blob`
 * parameter or the underlying `<img>` element changes.
 *
 * @param blob `Blob` object for which an object URL should be created
 *
 * @returns A callback ref to be attached to an `<img>` element whose `src`
 *          will be set to the `blob` parameter's object URL
 */
export default function useBlobSource(blob: Maybe<Blob>) {
  const objectUrlRef = useRef<string | null>(null);

  const callbackRef: RefCallback<HTMLImageElement> = useCallback(
    (element) => {
      if (objectUrlRef.current !== null) {
        URL.revokeObjectURL(objectUrlRef.current);
        objectUrlRef.current = null;
      }

      if (blob != null && element !== null) {
        objectUrlRef.current = URL.createObjectURL(blob);
        element.src = objectUrlRef.current;
      }
    },
    [blob]
  );

  return callbackRef;
}
