import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import { findLast } from "lodash";
import { getClients } from "../../../../../../domain/datastores";
import type { Record, Topic } from "../../../../../../services/datastore";
import { usePlaybackSource } from "../../../../PlaybackProvider";
import { useRecordWindow } from "../../../../hooks";
import { useRecordKeys } from "../../../../queries";
import type { PlayerRecord } from "../../../../types";
import { KNOWN_STATIC_TOPICS } from "../../constants";
import type { MinibotLog } from "../../types";
import type { StaticMarkerQueryStatus, ViewerRef } from "./types";
import {
  findRouterLogTopicName,
  removeMarkersIfPresent,
  renderMarkers,
} from "./utils";

type StaticMarkerData =
  | { isStatic: true; record: Record }
  | { isStatic: false };

export interface PlaybackMarkerTopicProps {
  routerLog: MinibotLog | null;
  topicId: Topic["id"];
  viewerRef: ViewerRef;
  setStatus: (topicId: Topic["id"], status: StaticMarkerQueryStatus) => void;
}

export default function PlaybackMarkerTopic({
  routerLog,
  topicId,
  viewerRef,
  setStatus,
}: PlaybackMarkerTopicProps) {
  usePlaybackMarkerTopic(routerLog, topicId, viewerRef, setStatus);

  return null;
}

function usePlaybackMarkerTopic(
  routerLog: MinibotLog | null,
  topicId: Topic["id"],
  viewerRef: ViewerRef,
  setStatus: PlaybackMarkerTopicProps["setStatus"]
) {
  const playbackSource = usePlaybackSource();

  const request = { topicId, limit: 1 };

  const recordKeys = useRecordKeys();
  const staticMarkerQuery = useQuery({
    queryKey: recordKeys.list(request),
    queryFn(context) {
      const { topicApi } = getClients();

      return topicApi.listRecords(request, context);
    },
    select(response): StaticMarkerData {
      if (response.count === 1) {
        return {
          isStatic: true,
          record: response.data[0],
        };
      } else if (
        isKnownStaticTopic(routerLog, topicId) &&
        response.data.length > 0
      ) {
        return {
          isStatic: true,
          record: response.data[0],
        };
      } else {
        return {
          isStatic: false,
        };
      }
    },
  });

  const enableWindowQuery =
    staticMarkerQuery.isError ||
    (staticMarkerQuery.isSuccess && !staticMarkerQuery.data.isStatic);

  const query = useRecordWindow({
    topicId,
    windowSizeMs: secondsToMilliseconds(30),
    bufferAheadMs: minutesToMilliseconds(1),
    chunkSizeMs: secondsToMilliseconds(30),
    enabled: enableWindowQuery,
  });

  let mostRecentRecord: PlayerRecord | undefined = undefined;
  if (query.status === "success" && !playbackSource.isLoading) {
    mostRecentRecord = findLast(
      query.data,
      (record) => record.timestampMs <= playbackSource.timestampMs
    );
  }

  useEffect(
    function renderRecord() {
      if (viewerRef.current == null) {
        return;
      }

      if (
        staticMarkerQuery.data !== undefined &&
        staticMarkerQuery.data.isStatic
      ) {
        const { record } = staticMarkerQuery.data;

        renderMarkers(viewerRef, record);

        return () => {
          removeMarkersIfPresent(viewerRef, record.topicId);
        };
      }

      if (mostRecentRecord === undefined) {
        return;
      }

      renderMarkers(viewerRef, mostRecentRecord);

      return () => {
        removeMarkersIfPresent(viewerRef, mostRecentRecord!.topicId);
      };
    }
    // TODO: Would be nice to have this effect array but running into race
    //   conditions with viewer ref not being defined that sometimes prevent
    //   it from running when needed
    // [viewerRef, staticMarkerQuery.data, mostRecentRecord]
  );

  let derivedStatus: StaticMarkerQueryStatus;
  if (enableWindowQuery) {
    derivedStatus = query.status === "idle" ? "loading" : query.status;
  } else {
    derivedStatus = staticMarkerQuery.status;
  }
  useEffect(
    function updateStatus() {
      setStatus(topicId, derivedStatus);
    },
    [setStatus, topicId, derivedStatus]
  );

  return query.status;
}

function isKnownStaticTopic(
  routerLog: MinibotLog | null,
  topicId: Topic["id"]
): boolean {
  const topicName = findRouterLogTopicName(routerLog, topicId);

  return KNOWN_STATIC_TOPICS.includes(topicName as any);
}
