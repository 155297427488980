/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  GroupAssociationCreateRequest,
  GroupAssociationCreateRequestFromJSON,
  GroupAssociationCreateRequestToJSON,
  GroupAssociationFetchResponse,
  GroupAssociationFetchResponseFromJSON,
  GroupAssociationFetchResponseToJSON,
  GroupAssociationListResponse,
  GroupAssociationListResponseFromJSON,
  GroupAssociationListResponseToJSON,
  GroupAssociationUpdateRequest,
  GroupAssociationUpdateRequestFromJSON,
  GroupAssociationUpdateRequestToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  UserRole,
  UserRoleFromJSON,
  UserRoleToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateGroupAssociationRequest {
  groupAssociationCreateRequest: GroupAssociationCreateRequest;
}

export interface DeleteGroupAssociationRequest {
  groupAssociationId: string;
}

export interface GetGroupAssociationRequest {
  groupAssociationId: string;
}

export interface ListGroupAssociationsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  userId?: string;
  groupId?: string;
  role?: UserRole;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateGroupAssociationRequest {
  groupAssociationId: string;
  groupAssociationUpdateRequest: GroupAssociationUpdateRequest;
}

/**
 *
 */
export class GroupAssociationApi extends runtime.BaseAPI {
  /**
   * Required role: [owner, admin]
   * Create Group Association
   */
  async createGroupAssociationRaw(
    this: GroupAssociationApi,
    requestParameters: CreateGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupAssociationFetchResponse>> {
    if (
      requestParameters.groupAssociationCreateRequest === null ||
      requestParameters.groupAssociationCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "groupAssociationCreateRequest",
        "Required parameter requestParameters.groupAssociationCreateRequest was null or undefined when calling createGroupAssociation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groupAssociations`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GroupAssociationCreateRequestToJSON(
          requestParameters.groupAssociationCreateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupAssociationFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [owner, admin]
   * Create Group Association
   */
  async createGroupAssociation(
    this: GroupAssociationApi,
    requestParameters: CreateGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupAssociationFetchResponse> {
    const response = await this.createGroupAssociationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Group Association
   */
  async deleteGroupAssociationRaw(
    this: GroupAssociationApi,
    requestParameters: DeleteGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.groupAssociationId === null ||
      requestParameters.groupAssociationId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupAssociationId",
        "Required parameter requestParameters.groupAssociationId was null or undefined when calling deleteGroupAssociation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groupAssociations/{group_association_id}`.replace(
          `{${"group_association_id"}}`,
          encodeURIComponent(String(requestParameters.groupAssociationId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Group Association
   */
  async deleteGroupAssociation(
    this: GroupAssociationApi,
    requestParameters: DeleteGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteGroupAssociationRaw(requestParameters, initOverrides);
  }

  /**
   * Get Group Association
   */
  async getGroupAssociationRaw(
    this: GroupAssociationApi,
    requestParameters: GetGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupAssociationFetchResponse>> {
    if (
      requestParameters.groupAssociationId === null ||
      requestParameters.groupAssociationId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupAssociationId",
        "Required parameter requestParameters.groupAssociationId was null or undefined when calling getGroupAssociation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groupAssociations/{group_association_id}`.replace(
          `{${"group_association_id"}}`,
          encodeURIComponent(String(requestParameters.groupAssociationId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupAssociationFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Group Association
   */
  async getGroupAssociation(
    this: GroupAssociationApi,
    requestParameters: GetGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupAssociationFetchResponse> {
    const response = await this.getGroupAssociationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Group Associations
   */
  async listGroupAssociationsRaw(
    this: GroupAssociationApi,
    requestParameters: ListGroupAssociationsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupAssociationListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.userId !== undefined) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.role !== undefined) {
      queryParameters["role"] = requestParameters.role;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groupAssociations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupAssociationListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Group Associations
   */
  async listGroupAssociations(
    this: GroupAssociationApi,
    requestParameters: ListGroupAssociationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupAssociationListResponse> {
    const response = await this.listGroupAssociationsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Group Association
   */
  async updateGroupAssociationRaw(
    this: GroupAssociationApi,
    requestParameters: UpdateGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<GroupAssociationFetchResponse>> {
    if (
      requestParameters.groupAssociationId === null ||
      requestParameters.groupAssociationId === undefined
    ) {
      throw new runtime.RequiredError(
        "groupAssociationId",
        "Required parameter requestParameters.groupAssociationId was null or undefined when calling updateGroupAssociation."
      );
    }

    if (
      requestParameters.groupAssociationUpdateRequest === null ||
      requestParameters.groupAssociationUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "groupAssociationUpdateRequest",
        "Required parameter requestParameters.groupAssociationUpdateRequest was null or undefined when calling updateGroupAssociation."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/groupAssociations/{group_association_id}`.replace(
          `{${"group_association_id"}}`,
          encodeURIComponent(String(requestParameters.groupAssociationId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: GroupAssociationUpdateRequestToJSON(
          requestParameters.groupAssociationUpdateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GroupAssociationFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Group Association
   */
  async updateGroupAssociation(
    this: GroupAssociationApi,
    requestParameters: UpdateGroupAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<GroupAssociationFetchResponse> {
    const response = await this.updateGroupAssociationRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
