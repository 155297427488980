/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  Record,
  RecordFromJSON,
  RecordFromJSONTyped,
  RecordToJSON,
} from "./Record";

/**
 *
 * @export
 * @interface RecordFetchResponse
 */
export interface RecordFetchResponse {
  /**
   *
   * @type {Record}
   * @memberof RecordFetchResponse
   */
  data: Record;
}

export function RecordFetchResponseFromJSON(json: any): RecordFetchResponse {
  return RecordFetchResponseFromJSONTyped(json, false);
}

export function RecordFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: RecordFromJSON(json["data"]),
  };
}

export function RecordFetchResponseToJSON(
  value?: RecordFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: RecordToJSON(value.data),
  };
}
