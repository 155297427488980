import React from "react";
import { Menu, MenuOpen } from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { useLayoutStateContext } from "./Layout";

export interface HeaderProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
}

export default function Header({ title, actions }: HeaderProps) {
  const { isGlobalNavigationOpen, setIsGlobalNavigationOpen } =
    useLayoutStateContext();

  return (
    <Toolbar
      variant="dense"
      sx={{ "& .MuiIconButton-root": { color: "inherit" } }}
    >
      <Tooltip title={isGlobalNavigationOpen ? "Close" : "Open"}>
        <IconButton
          sx={{ mr: 2 }}
          onClick={() => setIsGlobalNavigationOpen(!isGlobalNavigationOpen)}
        >
          {isGlobalNavigationOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
      </Tooltip>
      <Typography variant="h6" component="h1" flexGrow={1} noWrap>
        {title}
      </Typography>
      {actions}
    </Toolbar>
  );
}
