import React from "react";
import { Box, CircularProgress, SvgIcon } from "@mui/material";

export default function CircularProgressIcon() {
  return (
    <Box
      component="span"
      sx={{
        height: "auto",
        width: "auto",
        display: "inline-block",
        lineHeight: 0,
        position: "relative",
      }}
    >
      <SvgIcon fontSize="inherit" />
      <CircularProgress
        sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        size="100%"
      />
    </Box>
  );
}
