/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  Ingestion,
  IngestionFromJSON,
  IngestionFromJSONTyped,
  IngestionToJSON,
} from "./Ingestion";

/**
 *
 * @export
 * @interface IngestionFetchResponse
 */
export interface IngestionFetchResponse {
  /**
   *
   * @type {Ingestion}
   * @memberof IngestionFetchResponse
   */
  data: Ingestion;
}

export function IngestionFetchResponseFromJSON(
  json: any
): IngestionFetchResponse {
  return IngestionFetchResponseFromJSONTyped(json, false);
}

export function IngestionFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IngestionFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: IngestionFromJSON(json["data"]),
  };
}

export function IngestionFetchResponseToJSON(
  value?: IngestionFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: IngestionToJSON(value.data),
  };
}
