import React from "react";
import { getAppConfig } from "../../../config";
import { createSafeContext } from "../../../contexts";

export interface DataStoreProviderProps {
  children: React.ReactNode;
}

type DataStoreContextValue = string;

export const [useDataStoreContext, DataStoreContext] =
  createSafeContext<DataStoreContextValue>("DataStore");

export function useCurrentDataStore() {
  return useDataStoreContext();
}

export default function DataStoreProvider({
  children,
}: DataStoreProviderProps) {
  return (
    <DataStoreContext.Provider value={getAppConfig().staticApiEndpoint}>
      {children}
    </DataStoreContext.Provider>
  );
}
