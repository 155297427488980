/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import { Topic, TopicFromJSON, TopicFromJSONTyped, TopicToJSON } from "./Topic";

/**
 *
 * @export
 * @interface TopicFetchResponse
 */
export interface TopicFetchResponse {
  /**
   *
   * @type {Topic}
   * @memberof TopicFetchResponse
   */
  data: Topic;
}

export function TopicFetchResponseFromJSON(json: any): TopicFetchResponse {
  return TopicFetchResponseFromJSONTyped(json, false);
}

export function TopicFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TopicFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: TopicFromJSON(json["data"]),
  };
}

export function TopicFetchResponseToJSON(
  value?: TopicFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: TopicToJSON(value.data),
  };
}
