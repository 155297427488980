import { secondsToMilliseconds } from "date-fns";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import type { DataFilter } from "../types";
import useRecordChunks from "./useRecordChunks";

interface UseOverviewOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  fetchBoundsMs?: [number, number];
  dataFilter?: DataFilter | DataFilter[];
}

export default function useTopicOverview({
  topicId,
  enabled = topicId != null,
  fetchBoundsMs,
  dataFilter,
}: UseOverviewOptions) {
  return useRecordChunks({
    topicId,
    enabled,
    chunkSizeMs: secondsToMilliseconds(100),
    fetchBoundsMs,
    dataFilter,
  });
}
