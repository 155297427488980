import { useState } from "react";
import {
  BugReport,
  BugReportOutlined,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { TimerSync } from "mdi-material-ui";
import { useFormatPlaybackTimestamp } from "../../../../PlaybackProvider";
import type { useMarkerTopics } from "./hooks";

export interface DebugControlsProps {
  markerTopics: ReturnType<typeof useMarkerTopics>;
}

export default function DebugControls({ markerTopics }: DebugControlsProps) {
  const formatDebugTimestamp = useFormatPlaybackTimestamp();

  const [lastDebugTimeMs, setLastDebugTimeMs] = useState(
    markerTopics.debugTimeMs
  );

  if (
    markerTopics.debugTimeMs != null &&
    markerTopics.debugTimeMs !== lastDebugTimeMs
  ) {
    // Since debug time is derived from query data, there needs to be a way to
    // track the last successful debug time to continue showing it if new
    // data is loading.
    setLastDebugTimeMs(markerTopics.debugTimeMs);

    return null;
  }

  const formattedTime =
    lastDebugTimeMs == null ? "--:--" : formatDebugTimestamp(lastDebugTimeMs);

  return (
    <Paper sx={{ p: 1, position: "absolute", top: 0, right: 0, zIndex: 1 }}>
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <IconButton size="small" onClick={markerTopics.toggleDebugMode}>
          <Tooltip
            title={
              markerTopics.inDebugMode
                ? "Turn debug mode off"
                : "Turn debug mode on"
            }
          >
            {markerTopics.inDebugMode ? <BugReport /> : <BugReportOutlined />}
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          disabled={!markerTopics.hasPreviousFrame}
          onClick={markerTopics.goToPreviousFrame}
        >
          <Tooltip title="Previous message">
            <NavigateBefore />
          </Tooltip>
        </IconButton>
        <IconButton
          size="small"
          disabled={!markerTopics.hasNextFrame}
          onClick={markerTopics.goToNextFrame}
        >
          <Tooltip title="Next message">
            <NavigateNext />
          </Tooltip>
        </IconButton>
        <Typography>{formattedTime}</Typography>
        <IconButton
          size="small"
          disabled={!markerTopics.inDebugMode}
          onClick={markerTopics.syncWithPlayback}
        >
          <Tooltip title="Sync with playback">
            <TimerSync />
          </Tooltip>
        </IconButton>
      </Stack>
    </Paper>
  );
}
