/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface PresignedURLParams
 */
export interface PresignedURLParams {
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  uploadId?: string;
  /**
   *
   * @type {number}
   * @memberof PresignedURLParams
   */
  maxKeys?: number;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  continuationToken?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  startAfter?: string;
  /**
   *
   * @type {number}
   * @memberof PresignedURLParams
   */
  maxParts?: number;
  /**
   *
   * @type {number}
   * @memberof PresignedURLParams
   */
  partNumberMarker?: number;
  /**
   *
   * @type {number}
   * @memberof PresignedURLParams
   */
  partNumber?: number;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  versionId?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  keyMarker?: string;
  /**
   *
   * @type {string}
   * @memberof PresignedURLParams
   */
  versionIdMarker?: string;
}

export function PresignedURLParamsFromJSON(json: any): PresignedURLParams {
  return PresignedURLParamsFromJSONTyped(json, false);
}

export function PresignedURLParamsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PresignedURLParams {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    bucket: !exists(json, "Bucket") ? undefined : json["Bucket"],
    key: !exists(json, "Key") ? undefined : json["Key"],
    uploadId: !exists(json, "UploadId") ? undefined : json["UploadId"],
    maxKeys: !exists(json, "MaxKeys") ? undefined : json["MaxKeys"],
    continuationToken: !exists(json, "ContinuationToken")
      ? undefined
      : json["ContinuationToken"],
    startAfter: !exists(json, "StartAfter") ? undefined : json["StartAfter"],
    maxParts: !exists(json, "MaxParts") ? undefined : json["MaxParts"],
    partNumberMarker: !exists(json, "PartNumberMarker")
      ? undefined
      : json["PartNumberMarker"],
    partNumber: !exists(json, "PartNumber") ? undefined : json["PartNumber"],
    versionId: !exists(json, "VersionId") ? undefined : json["VersionId"],
    keyMarker: !exists(json, "KeyMarker") ? undefined : json["KeyMarker"],
    versionIdMarker: !exists(json, "VersionIdMarker")
      ? undefined
      : json["VersionIdMarker"],
  };
}

export function PresignedURLParamsToJSON(
  value?: PresignedURLParams | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    Bucket: value.bucket,
    Key: value.key,
    UploadId: value.uploadId,
    MaxKeys: value.maxKeys,
    ContinuationToken: value.continuationToken,
    StartAfter: value.startAfter,
    MaxParts: value.maxParts,
    PartNumberMarker: value.partNumberMarker,
    PartNumber: value.partNumber,
    VersionId: value.versionId,
    KeyMarker: value.keyMarker,
    VersionIdMarker: value.versionIdMarker,
  };
}
