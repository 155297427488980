/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface GroupAssociationUpdateRequest
 */
export interface GroupAssociationUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof GroupAssociationUpdateRequest
   */
  role?: string;
  /**
   *
   * @type {Date}
   * @memberof GroupAssociationUpdateRequest
   */
  deletedAt?: Date;
}

export function GroupAssociationUpdateRequestFromJSON(
  json: any
): GroupAssociationUpdateRequest {
  return GroupAssociationUpdateRequestFromJSONTyped(json, false);
}

export function GroupAssociationUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GroupAssociationUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    role: !exists(json, "role") ? undefined : json["role"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function GroupAssociationUpdateRequestToJSON(
  value?: GroupAssociationUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    role: value.role,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
