/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface LogCreateRequest
 */
export interface LogCreateRequest {
  /**
   *
   * @type {string}
   * @memberof LogCreateRequest
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof LogCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LogCreateRequest
   */
  note?: string;
  /**
   *
   * @type {object}
   * @memberof LogCreateRequest
   */
  context?: object;
}

export function LogCreateRequestFromJSON(json: any): LogCreateRequest {
  return LogCreateRequestFromJSONTyped(json, false);
}

export function LogCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupId: json["group_id"],
    name: json["name"],
    note: !exists(json, "note") ? undefined : json["note"],
    context: !exists(json, "context") ? undefined : json["context"],
  };
}

export function LogCreateRequestToJSON(value?: LogCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_id: value.groupId,
    name: value.name,
    note: value.note,
    context: value.context,
  };
}
