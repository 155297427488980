import React, { useEffect, useState } from "react";
import {
  Dashboard,
  Pause,
  PlayArrow,
  Replay,
  Share,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Tooltip,
} from "@mui/material";
import invariant from "invariant";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { PlaySpeed, SquareWave } from "mdi-material-ui";
import { useSnackbar } from "notistack";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatTimestamp } from "../../../utils";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSettings,
  usePlaybackSource,
} from "../PlaybackProvider";
import type { LayoutProfile } from "../panels";
import {
  loadLayout,
  useLayoutProfiles,
  usePanelLayoutContext,
} from "../panels";
import { usePlaybackTimer, usePlaybackTimerPause } from "../playback";
import {
  nextFrame,
  pause,
  play,
  previousFrame,
  restart,
  seek,
  setRange,
  tick,
} from "../playbackReducer";
import type { TimestepValue } from "../types";
import { PlaybackSpeed, Timestep } from "../types";
import ProfileDialog from "./ProfileDialog";

function offsetMsForTimestep(timestep: TimestepValue) {
  return timestep === Timestep.Second ? 1_000 : 100;
}

export default function PlaybackController() {
  const layoutProfilesQuery = useLayoutProfiles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const playbackSettings = usePlaybackSettings();
  const playbackSource = usePlaybackSource();

  const panelLayout = usePanelLayoutContext();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const profilesMenuState = usePopupState({
    variant: "popover",
    popupId: "profiles-menu",
  });
  const speedMenuState = usePopupState({
    variant: "popover",
    popupId: "speed-menu",
  });
  const stepMenuStep = usePopupState({
    variant: "popover",
    popupId: "step-menu",
  });

  const { enqueueSnackbar } = useSnackbar();

  const offsetMs = offsetMsForTimestep(playbackSettings.timestep);

  const { dispatch } = playbackSource;

  const playbackTimer = usePlaybackTimer();

  const [isSeeking, setIsSeeking] = useState(false);
  usePlaybackTimerPause(isSeeking);

  useEffect(
    function managePlaybackTimer() {
      if (!playbackSource.isPlaying) {
        return;
      }

      playbackTimer.set({
        onTick() {
          dispatch(tick());
        },
        speed: playbackSettings.speed,
        timestep: playbackSettings.timestep,
      });

      return () => {
        playbackTimer.clear();
      };
    },
    [
      playbackSource.isPlaying,
      playbackTimer,
      dispatch,
      playbackSettings.speed,
      playbackSettings.timestep,
    ]
  );

  function handleLayoutSelect(layoutProfile: LayoutProfile) {
    return function onClick() {
      panelLayout.dispatch(loadLayout(layoutProfile.layout));

      profilesMenuState.close();
    };
  }

  function handleDialogOpen() {
    setDialogOpen(true);

    profilesMenuState.close();
  }

  const isAtStart = playbackSource.timestampMs === playbackSource.boundsMs?.[0];
  const isAtEnd = playbackSource.timestampMs === playbackSource.boundsMs?.[1];

  let playbackControl;
  if (playbackSource.isPlaying) {
    playbackControl = (
      <Tooltip title="Pause">
        <span>
          <IconButton
            disabled={playbackSource.inRangeMode}
            aria-label="Pause log playback"
            onClick={() => playbackSource.dispatch(pause())}
            size="large"
          >
            <Pause />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else if (!playbackSource.isLoading && isAtEnd) {
    playbackControl = (
      <Tooltip title="Replay">
        <span>
          <IconButton
            disabled={playbackSource.inRangeMode}
            aria-label="Replay log from beginning"
            onClick={() => playbackSource.dispatch(restart())}
            size="large"
          >
            <Replay />
          </IconButton>
        </span>
      </Tooltip>
    );
  } else {
    playbackControl = (
      <Tooltip title="Play">
        <span>
          <IconButton
            disabled={playbackSource.isLoading || playbackSource.inRangeMode}
            aria-label="Start or resume log playback"
            onClick={() => playbackSource.dispatch(play())}
            size="large"
          >
            <PlayArrow />
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  function formatVerboseTimestamp(value: number) {
    return formatTimestamp(value, {
      precision: 1,
      relativeToMs: playbackSource.boundsMs?.[0],
      colonNotation: false,
      separateMilliseconds: true,
      verbose: true,
    });
  }

  let shareableLink: string = "";
  if (!playbackSource.isLoading) {
    const currentUrl = new URL(window.location.href);

    // Note: this keeps existing search parameters. Maybe we don't want
    // that but currently there's nothing important kept in there
    currentUrl.searchParams.set("t", playbackSource.timestampMs.toString());

    shareableLink = currentUrl.toString();
  }

  return (
    <Box
      sx={{
        mt: "auto",
        borderTop: 1,
        borderTopColor: "divider",
        p: 3,
        "& .MuiSkeleton-root": {
          display: "inline-block",
        },
      }}
    >
      <Box px={2}>
        <Slider
          sx={{
            "& :is(.MuiSlider-track, .MuiSlider-thumb)": {
              transition: "none",
            },
          }}
          disabled={playbackSource.isLoading}
          getAriaLabel={(index) => {
            if (!playbackSource.inRangeMode) {
              return "playback time";
            } else if (index === 0) {
              return "extraction time range start";
            } else {
              return "extraction time range end";
            }
          }}
          getAriaValueText={(value) => {
            if (playbackSource.isLoading) {
              return "loading playback time";
            } else if (!playbackSource.inRangeMode) {
              return `${formatVerboseTimestamp(
                value
              )} of ${formatVerboseTimestamp(playbackSource.boundsMs[1])}`;
            } else {
              return formatVerboseTimestamp(value);
            }
          }}
          valueLabelDisplay={playbackSource.inRangeMode ? "on" : "auto"}
          valueLabelFormat={formatPlaybackTimestamp}
          min={playbackSource.boundsMs?.[0] ?? 0}
          max={playbackSource.boundsMs?.[1] ?? 0}
          step={offsetMs}
          onChange={(e, value, thumbIndex) => {
            setIsSeeking(true);

            if (playbackSource.inRangeMode) {
              invariant(
                Array.isArray(value),
                "Expected a 2-tuple in range-select mode"
              );

              playbackSource.dispatch(setRange(value as [number, number]));
              playbackSource.dispatch(seek(value[thumbIndex]));
            } else {
              invariant(
                typeof value === "number",
                "Expected a number in playback mode"
              );

              playbackSource.dispatch(seek(value));
            }
          }}
          onChangeCommitted={() => {
            setIsSeeking(false);
          }}
          value={
            playbackSource.inRangeMode
              ? [
                  playbackSource.rangeMs?.[0] ?? 0,
                  playbackSource.rangeMs?.[1] ?? 0,
                ]
              : playbackSource.timestampMs ?? 0
          }
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Tooltip title="Previous">
          <span>
            <IconButton
              disabled={
                isAtStart ||
                playbackSource.isLoading ||
                playbackSource.inRangeMode
              }
              aria-label="Move backward to previous timestamp"
              onClick={() => playbackSource.dispatch(previousFrame())}
              size="large"
            >
              <SkipPrevious />
            </IconButton>
          </span>
        </Tooltip>
        {playbackControl}
        <Tooltip title="Next">
          <span>
            <IconButton
              disabled={
                isAtEnd ||
                playbackSource.isLoading ||
                playbackSource.inRangeMode
              }
              aria-label="Move forward to next timestamp"
              onClick={() => playbackSource.dispatch(nextFrame())}
              size="large"
            >
              <SkipNext />
            </IconButton>
          </span>
        </Tooltip>
        <Box sx={{ "& pre": { m: 0, display: "inline" } }}>
          <pre>
            {playbackSource.isLoading
              ? "--"
              : formatPlaybackTimestamp(playbackSource.timestampMs)}
          </pre>
          {playbackSettings.displayFormat !== "utc" && (
            <>
              {" / "}
              <pre>
                {playbackSource.isLoading
                  ? "--"
                  : formatPlaybackTimestamp(playbackSource.boundsMs[1])}
              </pre>
            </>
          )}
        </Box>
        <Box display="inline-block" ml="auto">
          <Tooltip title="Layout profiles">
            <span>
              <IconButton
                disabled={
                  playbackSource.isLoading || !layoutProfilesQuery.isSuccess
                }
                size="large"
                {...bindTrigger(profilesMenuState)}
              >
                <Dashboard />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(profilesMenuState)}>
            {layoutProfilesQuery.data?.map((profile) => (
              <MenuItem
                key={profile.name}
                onClick={handleLayoutSelect(profile)}
              >
                {profile.name}
              </MenuItem>
            ))}
            {(layoutProfilesQuery.data?.length ?? 0) > 0 && (
              <Divider component="li" sx={{ my: 1 }} />
            )}
            <MenuItem onClick={handleDialogOpen}>Manage Profiles...</MenuItem>
          </Menu>
          <ProfileDialog open={dialogOpen} setOpen={setDialogOpen} />
          <Tooltip title="Copy shareable link">
            <span>
              <CopyToClipboard
                text={shareableLink}
                onCopy={(_, result) => {
                  if (result) {
                    enqueueSnackbar("Link copied", {
                      variant: "success",
                    });
                  } else {
                    enqueueSnackbar("Unable to copy link", {
                      variant: "error",
                    });
                  }
                }}
              >
                <IconButton
                  disabled={playbackSource.isLoading}
                  aria-label="Copy shareable link"
                  size="large"
                >
                  <Share />
                </IconButton>
              </CopyToClipboard>
            </span>
          </Tooltip>
          <Tooltip title="Playback speed">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open playback speed menu"
                size="large"
                {...bindTrigger(speedMenuState)}
              >
                <PlaySpeed />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(speedMenuState)}>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesOne}
              onClick={() => playbackSettings.setSpeed(PlaybackSpeed.TimesOne)}
            >
              1x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesTwo}
              onClick={() => playbackSettings.setSpeed(PlaybackSpeed.TimesTwo)}
            >
              2x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesFive}
              onClick={() => playbackSettings.setSpeed(PlaybackSpeed.TimesFive)}
            >
              5x
            </MenuItem>
            <MenuItem
              selected={playbackSettings.speed === PlaybackSpeed.TimesTen}
              onClick={() => playbackSettings.setSpeed(PlaybackSpeed.TimesTen)}
            >
              10x
            </MenuItem>
          </Menu>
          <Tooltip title="Timestep">
            <span>
              <IconButton
                disabled={playbackSource.isLoading}
                aria-label="Open timestep control menu"
                size="large"
                {...bindTrigger(stepMenuStep)}
              >
                <SquareWave />
              </IconButton>
            </span>
          </Tooltip>
          <Menu {...bindMenu(stepMenuStep)}>
            <MenuItem
              selected={playbackSettings.timestep === Timestep.Second}
              onClick={() => playbackSettings.setTimestep(Timestep.Second)}
            >
              1 second
            </MenuItem>
            <MenuItem
              selected={playbackSettings.timestep === Timestep.Decisecond}
              onClick={() => playbackSettings.setTimestep(Timestep.Decisecond)}
            >
              0.1 seconds
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
}
