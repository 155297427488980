import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { getClients } from "../domain/datastores";
import type { UserFetchResponse } from "../services/datastore";
import type { ResolvedKeyFactory } from "../types";

export function useMeKeys() {
  const factory = {
    all: ["me"] as const,
    details: () => [...factory.all, "details"] as const,
    detail: () => [...factory.details()] as const,
  } as const;

  return factory;
}

export type MeKeys = ResolvedKeyFactory<typeof useMeKeys>;

export function useCurrentUser<TData = UserFetchResponse>(
  options?: UseQueryOptions<UserFetchResponse, unknown, TData, MeKeys["detail"]>
) {
  return useQuery({
    queryKey: useMeKeys().detail(),
    queryFn(context) {
      const { meApi } = getClients();

      return meApi.getMe(context);
    },
    ...options,
  });
}
