import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab } from "@mui/material";
import Center from "../../../../../components/Center";
import TopicSection from "../../../components/TopicSection";
import { LOG_VIEW_PANEL_SIZES_STORAGE_KEY } from "../constants";
import type { MinibotLog } from "../types";
import BotViewer from "./BotViewer";
import EventTimeline from "./EventTimeline";
import FleetOverview from "./FleetOverview";
import ResizeablePanels from "./ResizeablePanels";

export interface RouterLogProps {
  log: MinibotLog;
}

export default function RouterLog({ log }: RouterLogProps) {
  const [view, setView] = useState<"fleet" | "log">("fleet");

  function handleTabChange(_: unknown, newTab: string) {
    setView(newTab as any);
  }

  return (
    <TabContext value={view}>
      <Stack
        sx={{
          flexGrow: 1,
          minHeight: 0,
          "& .MuiTabs-root": {
            flex: "none",
            borderBottom: 1,
            borderColor: "divider",
          },
          "& .MuiTabPanel-root": {
            p: 0,
            flexGrow: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
            "&[hidden]": {
              display: "none",
            },
          },
        }}
      >
        <TabList onChange={handleTabChange}>
          <Tab value="fleet" label="Fleet" />
          <Tab value="log" label="Log" />
        </TabList>
        <TabPanel value="fleet">
          <Box
            sx={{
              flex: "1 0 40%",
              minHeight: 0,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <BotViewer routerLog={log} />
          </Box>
          <FleetOverview log={log} />
        </TabPanel>
        <TabPanel value="log">
          <Box
            sx={{
              flexGrow: 1,
              minHeight: 0,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <ResizeablePanels
              storageKey={LOG_VIEW_PANEL_SIZES_STORAGE_KEY}
              splitOrientation="vertical"
              left={<BotViewer routerLog={log} />}
              right={
                <Box sx={{ height: 1, width: 1, display: "flex" }}>
                  <TopicSection />
                </Box>
              }
            />
          </Box>
          <Box sx={{ height: 35 }}>
            <Center>
              <EventTimeline logSummary={log.summary} />
            </Center>
          </Box>
        </TabPanel>
      </Stack>
    </TabContext>
  );
}
