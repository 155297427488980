import { Auth } from "aws-amplify";
import { Configuration } from "../datastore";

export async function isUserAuthenticated(): Promise<boolean> {
  try {
    await Auth.currentSession();
  } catch {
    return false;
  }

  return true;
}

export function createApiClientConfiguration(dataStore: string): Configuration {
  return new Configuration({
    basePath: dataStore,
    accessToken: getAmplifyIdToken,
  });
}

export async function getAmplifyIdToken(): Promise<string> {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}
