/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  LogFormat,
  LogFormatFromJSON,
  LogFormatFromJSONTyped,
  LogFormatToJSON,
} from "./LogFormat";

/**
 *
 * @export
 * @interface RecordCreateRequest
 */
export interface RecordCreateRequest {
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  timestamp: number;
  /**
   *
   * @type {string}
   * @memberof RecordCreateRequest
   */
  messageTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof RecordCreateRequest
   */
  ingestionId?: string;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  length: number;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  dataOffset: number;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  dataLength: number;
  /**
   *
   * @type {string}
   * @memberof RecordCreateRequest
   */
  chunkCompression?: string;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  chunkOffset?: number;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  chunkLength?: number;
  /**
   *
   * @type {string}
   * @memberof RecordCreateRequest
   */
  s3Bucket?: string;
  /**
   *
   * @type {string}
   * @memberof RecordCreateRequest
   */
  s3Key?: string;
  /**
   *
   * @type {LogFormat}
   * @memberof RecordCreateRequest
   */
  format?: LogFormat;
  /**
   *
   * @type {any}
   * @memberof RecordCreateRequest
   */
  messageData?: any | null;
  /**
   *
   * @type {any}
   * @memberof RecordCreateRequest
   */
  context?: any | null;
  /**
   *
   * @type {number}
   * @memberof RecordCreateRequest
   */
  nanosecond?: number;
}

export function RecordCreateRequestFromJSON(json: any): RecordCreateRequest {
  return RecordCreateRequestFromJSONTyped(json, false);
}

export function RecordCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RecordCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: json["timestamp"],
    messageTypeId: !exists(json, "message_type_id")
      ? undefined
      : json["message_type_id"],
    ingestionId: !exists(json, "ingestion_id")
      ? undefined
      : json["ingestion_id"],
    offset: json["offset"],
    length: json["length"],
    dataOffset: json["data_offset"],
    dataLength: json["data_length"],
    chunkCompression: !exists(json, "chunk_compression")
      ? undefined
      : json["chunk_compression"],
    chunkOffset: !exists(json, "chunk_offset")
      ? undefined
      : json["chunk_offset"],
    chunkLength: !exists(json, "chunk_length")
      ? undefined
      : json["chunk_length"],
    s3Bucket: !exists(json, "s3_bucket") ? undefined : json["s3_bucket"],
    s3Key: !exists(json, "s3_key") ? undefined : json["s3_key"],
    format: !exists(json, "format")
      ? undefined
      : LogFormatFromJSON(json["format"]),
    messageData: !exists(json, "message_data")
      ? undefined
      : json["message_data"],
    context: !exists(json, "context") ? undefined : json["context"],
    nanosecond: !exists(json, "nanosecond") ? undefined : json["nanosecond"],
  };
}

export function RecordCreateRequestToJSON(
  value?: RecordCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timestamp: value.timestamp,
    message_type_id: value.messageTypeId,
    ingestion_id: value.ingestionId,
    offset: value.offset,
    length: value.length,
    data_offset: value.dataOffset,
    data_length: value.dataLength,
    chunk_compression: value.chunkCompression,
    chunk_offset: value.chunkOffset,
    chunk_length: value.chunkLength,
    s3_bucket: value.s3Bucket,
    s3_key: value.s3Key,
    format: LogFormatToJSON(value.format),
    message_data: value.messageData,
    context: value.context,
    nanosecond: value.nanosecond,
  };
}
