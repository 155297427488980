import { useEffect, useRef } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { millisecondsToSeconds, secondsToMilliseconds } from "date-fns";
import { floor, map, some } from "lodash";
import type { Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import { useRecordListCache } from "../RecordListCacheProvider";
import type {
  LocalRecordListResponse,
  UseRecordsQueriesOptions,
} from "../queries";
import { useRecordsQueries } from "../queries";
import type { DataFilter, PlayerRecord, TimeRange } from "../types";
import { SampleFrequency } from "../types";
import {
  calculateWindowChunks,
  intervalToTimeRange,
  prepareDataFilter,
} from "./utils";

export interface UseChunksOptions {
  topicId?: Maybe<Topic["id"]>;
  enabled?: boolean;
  fetchBoundsMs?: [number, number];
  activeRangeMs?: [number, number];
  sampleFrequency?: number;
  includeImage?: boolean;
  bufferBehindMs?: number;
  bufferAheadMs?: number;
  chunkSizeMs: number;
  dataFilter?: DataFilter | DataFilter[];
}

export interface UseRecordChunksIdleResult {
  status: "idle";
  isPlaceholderData: false;
  data: undefined;
  activeRangeMs: undefined;
}

export interface UseRecordChunksLoadingResult {
  status: "loading";
  isPlaceholderData: false;
  data: undefined;
  activeRangeMs: [number, number];
}

export interface UseRecordChunksLoadingPlaceholderResult {
  status: "loading";
  isPlaceholderData: true;
  data: PlayerRecord[];
  activeRangeMs: [number, number];
}

export interface UseRecordChunksErrorResult {
  status: "error";
  isPlaceholderData: false;
  data: undefined;
  activeRangeMs: [number, number];
}

export interface UseRecordChunksErrorPlaceholderResult {
  status: "error";
  isPlaceholderData: true;
  data: PlayerRecord[];
  activeRangeMs: [number, number];
}

export interface UseRecordChunksSuccessResult {
  status: "success";
  isPlaceholderData: false;
  data: PlayerRecord[];
  activeRangeMs: [number, number];
}

export type UseRecordChunksResult =
  | UseRecordChunksIdleResult
  | UseRecordChunksLoadingResult
  | UseRecordChunksLoadingPlaceholderResult
  | UseRecordChunksErrorResult
  | UseRecordChunksErrorPlaceholderResult
  | UseRecordChunksSuccessResult;

// Exported for tests
export type ReducedResult =
  | Pick<UseQueryResult<PlayerRecord[]>, "status" | "data">
  | { status: "idle"; data: undefined };

interface LastSuccessful {
  activeRangeMs: NonNullable<UseChunksOptions["activeRangeMs"]>;
  queriesOptions: UseRecordsQueriesOptions<PlayerRecord[]>[];
}

export default function useRecordChunks({
  topicId,
  enabled = true,
  fetchBoundsMs,
  activeRangeMs = fetchBoundsMs,
  sampleFrequency = SampleFrequency.Second,
  includeImage = false,
  bufferBehindMs = 0,
  bufferAheadMs = 0,
  chunkSizeMs,
  dataFilter,
}: UseChunksOptions) {
  const cache = useRecordListCache();

  const windowChunks = calculateWindowChunks({
    chunkSizeMs,
    bufferAheadMs,
    bufferBehindMs,
    // TODO: Eventually stop using interval arrays so these conversions
    //   aren't necessary
    window:
      activeRangeMs === undefined
        ? undefined
        : intervalToTimeRange(activeRangeMs),
    playerBounds:
      fetchBoundsMs === undefined
        ? undefined
        : intervalToTimeRange(fetchBoundsMs),
  });

  function makeOptionsForChunk(
    chunk: TimeRange
  ): UseRecordsQueriesOptions<PlayerRecord[]> {
    return {
      request: {
        topicId,
        sort: "asc",
        order: "timestamp",
        limit: 100,
        timestampGte: millisecondsToSeconds(chunk.startTimeMs),
        timestampLt: millisecondsToSeconds(chunk.endTimeMs),
        frequency: sampleFrequency,
        includeImage,
        dataFilter: prepareDataFilter(dataFilter),
      },
      options: {
        enabled,
        select: selectPlayerRecords,
        // Don't use react-query's cache if the responses are in the
        // custom cache
        ...(!includeImage && { cacheTime: 0 }),
      },

      ...(!includeImage && { cache }),
    };
  }

  const requiredQueriesOptions = windowChunks.required.map(makeOptionsForChunk);
  const requiredQueries = useRecordsQueries(requiredQueriesOptions);
  const currentResult = reduceChunkQueries(requiredQueries);

  // Results aren't used for buffered chunks. Just creating query observers so
  // react-query will fire off the requests if needed and won't evict the
  // responses from its cache.
  useRecordsQueries(windowChunks.bufferAhead.map(makeOptionsForChunk));
  useRecordsQueries(windowChunks.bufferBehind.map(makeOptionsForChunk));

  const lastSuccessfulRef = useRef<LastSuccessful>();
  useEffect(function saveLastSuccessfulData() {
    if (activeRangeMs === undefined) {
      return;
    }

    if (requiredQueriesOptions.length === 0) {
      return;
    }

    if (currentResult.status !== "success") {
      return;
    }

    lastSuccessfulRef.current = {
      activeRangeMs,
      queriesOptions: requiredQueriesOptions,
    };
  });

  const placeholderOptions =
    currentResult.status === "success"
      ? []
      : lastSuccessfulRef.current?.queriesOptions ?? [];
  const placeholderQueries = useRecordsQueries(placeholderOptions);
  const placeholderResult = reduceChunkQueries(placeholderQueries);

  return consolidateResults(
    currentResult,
    activeRangeMs,
    placeholderResult,
    lastSuccessfulRef.current?.activeRangeMs
  );
}

function selectPlayerRecords(
  response: LocalRecordListResponse
): PlayerRecord[] {
  return response.data.map((record) => ({
    ...record,
    timestampMs: secondsToMilliseconds(floor(record.timestamp, 1)),
  }));
}

// Exported for testing
export function reduceChunkQueries(
  requiredQueries: Array<UseQueryResult<PlayerRecord[]>>
): ReducedResult {
  if (requiredQueries.length === 0) {
    return {
      status: "idle",
      data: undefined,
    };
  }

  if (some(requiredQueries, { status: "error" })) {
    return {
      status: "error",
      data: undefined,
    };
  }

  if (some(requiredQueries, { status: "loading" })) {
    return {
      status: "loading",
      data: undefined,
    };
  }

  return {
    status: "success",
    data: ([] as PlayerRecord[]).concat(
      ...(map(requiredQueries, "data") as Array<PlayerRecord[]>)
    ),
  };
}

// Exported for testing
export function consolidateResults(
  currentResult: ReducedResult,
  currentActiveRangeMs: UseChunksOptions["activeRangeMs"],
  placeholderResult: ReducedResult | undefined,
  placeholderActiveRangeMs: UseChunksOptions["activeRangeMs"]
): UseRecordChunksResult {
  const isPlaceholderData =
    (currentResult.status === "loading" || currentResult.status === "error") &&
    placeholderResult !== undefined;

  return {
    status: currentResult.status,
    isPlaceholderData,
    data: isPlaceholderData ? placeholderResult.data : currentResult.data,
    activeRangeMs: isPlaceholderData
      ? placeholderActiveRangeMs
      : currentActiveRangeMs,
  } as UseRecordChunksResult;
}
