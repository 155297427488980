/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  MessageTypeCreateRequest,
  MessageTypeCreateRequestFromJSON,
  MessageTypeCreateRequestToJSON,
  MessageTypeFetchResponse,
  MessageTypeFetchResponseFromJSON,
  MessageTypeFetchResponseToJSON,
  MessageTypeListResponse,
  MessageTypeListResponseFromJSON,
  MessageTypeListResponseToJSON,
  MessageTypeUpdateRequest,
  MessageTypeUpdateRequestFromJSON,
  MessageTypeUpdateRequestToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateMessageTypeRequest {
  messageTypeCreateRequest: MessageTypeCreateRequest;
}

export interface DeleteMessageTypeRequest {
  messageTypeId: string;
}

export interface GetMessageTypeRequest {
  messageTypeId: string;
}

export interface ListMessageTypesRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  groupId?: string;
  name?: string;
  nameLike?: string;
  md5?: string;
  definitionLike?: string;
  noteLike?: string;
  contextFilter?: any;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateMessageTypeRequest {
  messageTypeId: string;
  messageTypeUpdateRequest: MessageTypeUpdateRequest;
}

/**
 *
 */
export class MessageTypeApi extends runtime.BaseAPI {
  /**
   * Required role: [editor, owner, admin]
   * Create Message Type
   */
  async createMessageTypeRaw(
    this: MessageTypeApi,
    requestParameters: CreateMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<MessageTypeFetchResponse>> {
    if (
      requestParameters.messageTypeCreateRequest === null ||
      requestParameters.messageTypeCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "messageTypeCreateRequest",
        "Required parameter requestParameters.messageTypeCreateRequest was null or undefined when calling createMessageType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/messageTypes`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MessageTypeCreateRequestToJSON(
          requestParameters.messageTypeCreateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageTypeFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [editor, owner, admin]
   * Create Message Type
   */
  async createMessageType(
    this: MessageTypeApi,
    requestParameters: CreateMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<MessageTypeFetchResponse> {
    const response = await this.createMessageTypeRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Message Type
   */
  async deleteMessageTypeRaw(
    this: MessageTypeApi,
    requestParameters: DeleteMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.messageTypeId === null ||
      requestParameters.messageTypeId === undefined
    ) {
      throw new runtime.RequiredError(
        "messageTypeId",
        "Required parameter requestParameters.messageTypeId was null or undefined when calling deleteMessageType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/messageTypes/{message_type_id}`.replace(
          `{${"message_type_id"}}`,
          encodeURIComponent(String(requestParameters.messageTypeId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Message Type
   */
  async deleteMessageType(
    this: MessageTypeApi,
    requestParameters: DeleteMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteMessageTypeRaw(requestParameters, initOverrides);
  }

  /**
   * Get Message Type
   */
  async getMessageTypeRaw(
    this: MessageTypeApi,
    requestParameters: GetMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<MessageTypeFetchResponse>> {
    if (
      requestParameters.messageTypeId === null ||
      requestParameters.messageTypeId === undefined
    ) {
      throw new runtime.RequiredError(
        "messageTypeId",
        "Required parameter requestParameters.messageTypeId was null or undefined when calling getMessageType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/messageTypes/{message_type_id}`.replace(
          `{${"message_type_id"}}`,
          encodeURIComponent(String(requestParameters.messageTypeId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageTypeFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Message Type
   */
  async getMessageType(
    this: MessageTypeApi,
    requestParameters: GetMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<MessageTypeFetchResponse> {
    const response = await this.getMessageTypeRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Message Types
   */
  async listMessageTypesRaw(
    this: MessageTypeApi,
    requestParameters: ListMessageTypesRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<MessageTypeListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.md5 !== undefined) {
      queryParameters["md5"] = requestParameters.md5;
    }

    if (requestParameters.definitionLike !== undefined) {
      queryParameters["definition_like"] = requestParameters.definitionLike;
    }

    if (requestParameters.noteLike !== undefined) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/messageTypes`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageTypeListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Message Types
   */
  async listMessageTypes(
    this: MessageTypeApi,
    requestParameters: ListMessageTypesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<MessageTypeListResponse> {
    const response = await this.listMessageTypesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Message Type
   */
  async updateMessageTypeRaw(
    this: MessageTypeApi,
    requestParameters: UpdateMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<MessageTypeFetchResponse>> {
    if (
      requestParameters.messageTypeId === null ||
      requestParameters.messageTypeId === undefined
    ) {
      throw new runtime.RequiredError(
        "messageTypeId",
        "Required parameter requestParameters.messageTypeId was null or undefined when calling updateMessageType."
      );
    }

    if (
      requestParameters.messageTypeUpdateRequest === null ||
      requestParameters.messageTypeUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "messageTypeUpdateRequest",
        "Required parameter requestParameters.messageTypeUpdateRequest was null or undefined when calling updateMessageType."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/messageTypes/{message_type_id}`.replace(
          `{${"message_type_id"}}`,
          encodeURIComponent(String(requestParameters.messageTypeId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: MessageTypeUpdateRequestToJSON(
          requestParameters.messageTypeUpdateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MessageTypeFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Message Type
   */
  async updateMessageType(
    this: MessageTypeApi,
    requestParameters: UpdateMessageTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<MessageTypeFetchResponse> {
    const response = await this.updateMessageTypeRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
