import type React from "react";
import { useCallback, useState } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { floor, get, some } from "lodash";
import type { Topic } from "../../../../../services/datastore";
import { usePlaybackSource } from "../../../PlaybackProvider";
import type { TimestampPageResponse } from "../../../hooks";
import { createTimestampPageQueryFn, useTimestampOffset } from "../../../hooks";
import type { InitializedPanelNode } from "../../../panels";
import { usePanelLayoutContext } from "../../../panels";
import { toggleTerminalAutoScroll } from "../../../panels/reducer";
import { seek } from "../../../playbackReducer";
import { useRecordKeys } from "../../../queries";
import type { TerminalRecord, TerminalRecords } from "./types";

export type TerminalRecordsQueryResult =
  | { status: "loading"; data: undefined }
  | { status: "error"; data: undefined }
  | { status: "success"; data: TerminalRecords };

const DEFAULT_REQUEST_LIMIT = 30;
const SPDLOG_REQUEST_LIMIT = 1_000;

export default function useTerminalRecords(
  topic: Topic,
  panel: InitializedPanelNode
) {
  const isSpdlogTopic = topic.messageTypeName === "minibot_msgs/SpdlogLogMsg";
  const rowRequestLimit = isSpdlogTopic
    ? SPDLOG_REQUEST_LIMIT
    : DEFAULT_REQUEST_LIMIT;

  const [filter, setFilter] = useState(isSpdlogTopic ? "payload" : "");

  const [selectedRow, setSelectedRow] = useState<TerminalRecord | null>(null);

  const recordKeys = useRecordKeys();

  const playbackSource = usePlaybackSource();

  const offsetQuery = useTimestampOffset({
    topicId: topic.id,
    timestamp: playbackSource.isLoading
      ? undefined
      : playbackSource.timestampMs / 1_000,
    filters: {
      queryKey: recordKeys.list({ topicId: topic.id, limit: rowRequestLimit }),
      predicate(query) {
        return (
          query.state.status === "success" &&
          Boolean(query.meta?.isTerminalChunk)
        );
      },
    },
    select(recordOffset) {
      invariant(!playbackSource.isLoading, "Expected defined timestamp");

      return [
        recordOffset - rowRequestLimit,
        recordOffset,
        recordOffset + rowRequestLimit,
      ]
        .filter((offset) => offset >= 0)
        .map((offset) => Math.floor(offset / rowRequestLimit) * rowRequestLimit)
        .map((offset) => {
          return {
            request: {
              topicId: topic.id,
              limit: rowRequestLimit,
              offset,
              sort: "asc",
              order: "timestamp",
            },
            queryFn: createTimestampPageQueryFn(
              playbackSource.timestampMs / 1_000,
              recordOffset,
              topic.id,
              rowRequestLimit,
              offset
            ),
          };
        });
    },
  });

  const select = useCallback(
    (response: TimestampPageResponse): TerminalRecords => {
      return {
        offset: response.offset,
        records: response.data.map((record) => ({
          id: String(record.timestamp),
          timestampMs: secondsToMilliseconds(floor(record.timestamp, 1)),
          payload: record.messageData,
          filteredPayload:
            filter === ""
              ? record.messageData
              : get(record.messageData, filter),
        })),
      };
    },
    [filter]
  );

  const requests = offsetQuery.data ?? [];

  const queries = useQueries({
    queries: requests.map(({ request, queryFn }) => ({
      queryKey: recordKeys.list(request),
      queryFn,
      select,
      meta: {
        isTerminalChunk: true,
      },
    })),
  });

  const queryResult = makeTerminalRecordsQueryResult(offsetQuery, queries);

  const { dispatch } = usePanelLayoutContext();

  return {
    query: queryResult,
    filter,
    setFilter,
    selectedRow,
    handleRowSelect(e: React.MouseEvent<HTMLDivElement>, row: TerminalRecord) {
      if (e.shiftKey) {
        playbackSource.dispatch(seek(row.timestampMs));
      } else {
        setSelectedRow((previousRow) =>
          previousRow?.id === row.id ? null : row
        );
      }
    },
    handleAutoScrollToggle() {
      dispatch(toggleTerminalAutoScroll(panel.id));
    },
  };
}

function makeTerminalRecordsQueryResult(
  playbackSyncQuery: UseQueryResult,
  recordQueries: Array<UseQueryResult<TerminalRecords>>
): TerminalRecordsQueryResult {
  if (playbackSyncQuery.isLoading) {
    return { status: "loading", data: undefined };
  }

  if (playbackSyncQuery.isError) {
    return { status: "error", data: undefined };
  }

  if (some(recordQueries, { isLoading: true })) {
    return { status: "loading", data: undefined };
  }

  if (some(recordQueries, { isError: true })) {
    return { status: "error", data: undefined };
  }

  const mergedTerminalRecords: TerminalRecords = {
    offset: Infinity,
    records: [],
  };

  // These queries should already be sorted by offset in ascending order
  for (const query of recordQueries) {
    const { offset, records } = query.data!;

    mergedTerminalRecords.offset = Math.min(
      mergedTerminalRecords.offset,
      offset
    );
    mergedTerminalRecords.records.push(...records);
  }

  return {
    status: "success",
    data: mergedTerminalRecords,
  };
}
