import React, { useCallback, useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Close,
  CloudDownload,
  Edit,
  Error,
  Lock,
  Settings,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import type { ChipProps } from "@mui/material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { addSeconds, differenceInMilliseconds } from "date-fns";
import invariant from "invariant";
import { find } from "lodash";
import prettyBytes from "pretty-bytes";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import {
  BooleanParam,
  createEnumParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import {
  Dd,
  Dl,
  DlGroup,
  Dt,
  renderDlGroup,
} from "../../components/DescriptionList";
import GlobalNavigation from "../../components/GlobalNavigation";
import Header from "../../components/Header";
import Helmet from "../../components/Helmet";
import IconicTypography from "../../components/IconicTypography";
import Layout, {
  LayoutStateProvider,
  SidebarSwitch,
  SideSheetTrigger,
} from "../../components/Layout";
import ListDetailLayout from "../../components/ListDetailLayout";
import SettingsDrawer from "../../components/SettingsDrawer";
import Time from "../../components/Time";
import { useCurrentDataStore } from "../../domain/datastores";
import * as paths from "../../paths";
import { selectData, useCurrentUser, useLog, useUser } from "../../queries";
import type {
  Extraction,
  ExtractionFetchResponse,
  User,
} from "../../services/datastore";
import { ResponseError } from "../../services/datastore";
import { pluralize } from "../../utils";
import { makeLimitParam, OffsetParam } from "../../validators";
import { EditExtractionFormValues } from "./models";
import {
  useCreateExtractionPresignedUrl,
  useExtraction,
  useExtractionKeys,
  useExtractions,
  useUpdateExtraction,
} from "./queries";

interface FormValues {
  search: string;
  mine: boolean;
}

const limitOptions = [15, 25, 50];

const LimitParam = makeLimitParam(limitOptions);

const SortOptions = {
  Newest: "newest",
  Oldest: "oldest",
} as const;

interface SearchParams {
  search: string;
  limit: number;
  offset: number;
  sort: string;
  mine: boolean;
}

const SortParam = createEnumParam([SortOptions.Newest, SortOptions.Oldest]);

export default function Extractions() {
  const [selectedExtractionId, setSelectedExtractionId] = useQueryParam(
    "extractionId",
    withDefault(StringParam, null)
  );
  const extractionQuery = useExtraction(selectedExtractionId, {
    select: selectData,
  });

  const [params, setParams] = useQueryParams({
    search: withDefault(StringParam, ""),
    limit: withDefault(LimitParam, limitOptions[0]),
    offset: withDefault(OffsetParam, 0),
    sort: withDefault(SortParam, SortOptions.Newest),
    mine: withDefault(BooleanParam, false),
  });

  const currentUserQuery = useCurrentUser({ select: selectData });

  const canFilterByCurrentUser = currentUserQuery.isSuccess;

  const extractionsQuery = useExtractionSearch(
    params,
    currentUserQuery.data?.id,
    selectedExtractionId
  );

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      search: params.search,
      mine: params.mine,
    },
  });

  const createPresignedUrl = useCreateExtractionPresignedUrl();
  const { mutate } = createPresignedUrl;
  const urlCreatedAtRef = useRef(0);

  const performUrlMutation = useCallback(() => {
    if (extractionQuery.data === undefined) {
      return;
    }

    urlCreatedAtRef.current = Date.now();

    mutate(extractionQuery.data);
  }, [mutate, extractionQuery.data]);

  useEffect(
    function createPresignedUrlForSelectedExtraction() {
      if (extractionQuery.data === undefined) {
        return;
      }

      if (extractionQuery.data.status !== "complete") {
        return;
      }

      if (!createPresignedUrl.isIdle) {
        return;
      }

      performUrlMutation();
    },
    [extractionQuery.data, createPresignedUrl.isIdle, performUrlMutation]
  );

  useEffect(
    function refreshPresignedUrlBeforeExpiration() {
      if (createPresignedUrl.data === undefined) {
        return;
      }

      const staleTimeMs = calculatePresignedUrlStaleTime(
        urlCreatedAtRef.current,
        createPresignedUrl.data.url
      );

      const timeoutId = setTimeout(performUrlMutation, staleTimeMs);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [createPresignedUrl.data, performUrlMutation]
  );

  const onSubmit: SubmitHandler<FormValues> = function onSubmit(values) {
    setParams({ ...values, offset: 0 });
  };

  function handleSelect(extraction: Extraction) {
    return function onClick() {
      createPresignedUrl.reset();
      setSelectedExtractionId(extraction.id);
    };
  }

  function handleClose() {
    createPresignedUrl.reset();
    setSelectedExtractionId(null);
  }

  return (
    <>
      <Helmet>
        <title>Search Extractions</title>
      </Helmet>
      <LayoutStateProvider>
        <Layout
          header={
            <Header
              title="Search Extractions"
              actions={
                <SideSheetTrigger
                  title="Settings"
                  sidebarId="settings"
                  icon={<Settings />}
                />
              }
            />
          }
          globalNavigation={<GlobalNavigation />}
          sideSheet={
            <SidebarSwitch
              config={[
                {
                  id: "settings",
                  element: <SettingsDrawer />,
                },
              ]}
            />
          }
        >
          <ListDetailLayout
            search={
              <Card>
                <CardContent>
                  <Stack
                    spacing={2}
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      name="search"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Extraction name"
                        />
                      )}
                    />
                    <Controller
                      name="mine"
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <FormControlLabel
                          disabled={!canFilterByCurrentUser}
                          control={<Checkbox checked={value} {...field} />}
                          label="My extractions only"
                        />
                      )}
                    />
                    <Button
                      sx={{ alignSelf: "start" }}
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Search
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            }
            list={
              <Card>
                <CardContent>
                  <Stack direction="row" alignItems="center">
                    {extractionsQuery.isLoading ? (
                      <Typography>Fetching extractions...</Typography>
                    ) : extractionsQuery.isError ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Error color="error" />
                        <Typography>Unable to perform search</Typography>
                      </Stack>
                    ) : extractionsQuery.isRefetching ? (
                      <Typography>Searching...</Typography>
                    ) : (
                      <Typography>
                        {pluralize(extractionsQuery.data.count, "extraction")}
                      </Typography>
                    )}
                    <TextField
                      select
                      size="small"
                      label="Sort by"
                      value={params.sort}
                      onChange={(e) =>
                        setParams({ sort: e.target.value as any, offset: 0 })
                      }
                      sx={{ ml: "auto", width: "18ch" }}
                    >
                      <MenuItem value={SortOptions.Newest}>Newest</MenuItem>
                      <MenuItem value={SortOptions.Oldest}>Oldest</MenuItem>
                    </TextField>
                    <TextField
                      select
                      size="small"
                      label="Results per page"
                      value={params.limit}
                      onChange={(e) =>
                        setParams({
                          limit: Number(e.target.value),
                          offset: 0,
                        })
                      }
                      sx={{ ml: 2, width: "15ch" }}
                    >
                      {limitOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Box position="relative" mt={2}>
                    <Divider />
                    {extractionsQuery.isFetching && (
                      <LinearProgress
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                        }}
                      />
                    )}
                  </Box>
                  {extractionsQuery.isSuccess && (
                    <List
                      disablePadding
                      sx={{
                        "& .MuiListItem-root": {
                          flexWrap: "wrap",
                          ".MuiListItemButton-root": {
                            py: 4,
                          },
                        },
                      }}
                    >
                      {extractionsQuery.data?.count === 0 ? (
                        <ListItem>
                          <ListItemText>No results</ListItemText>
                        </ListItem>
                      ) : (
                        extractionsQuery.data.data.map((extraction, index) => (
                          <ListItem
                            key={extraction.id}
                            disablePadding
                            divider={
                              index !== extractionsQuery.data.data.length - 1
                            }
                          >
                            <ListItemButton
                              selected={extraction.id === selectedExtractionId}
                              onClick={handleSelect(extraction)}
                              sx={{ flexDirection: "column" }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                width={1}
                                mb={2}
                              >
                                <Typography
                                  variant="h6"
                                  component="p"
                                  sx={
                                    extraction.name === null
                                      ? { fontStyle: "italic" }
                                      : { fontWeight: "bold" }
                                  }
                                >
                                  {extraction.name ?? "Unnamed Extraction"}
                                </Typography>
                                <Chip {...chipPropsForExtraction(extraction)} />
                              </Stack>
                              <Dl spacing={4}>
                                {renderDlGroup(
                                  "Size",
                                  renderExtractionSize(extraction),
                                  {
                                    xs: 12,
                                    md: "auto",
                                  }
                                )}
                                {renderDlGroup(
                                  "Created on",
                                  <Time date={extraction.createdAt} />,
                                  {
                                    xs: 12,
                                    md: "auto",
                                  }
                                )}
                              </Dl>
                            </ListItemButton>
                          </ListItem>
                        ))
                      )}
                    </List>
                  )}
                  {extractionsQuery.isSuccess && (
                    <>
                      <Divider sx={{ mb: 2 }} />
                      <Pagination
                        disabled={extractionsQuery.data.count <= params.limit}
                        count={Math.ceil(
                          extractionsQuery.data.count / params.limit
                        )}
                        page={params.offset / params.limit + 1}
                        onChange={(e, newPage) => {
                          setParams({
                            offset: (newPage - 1) * params.limit,
                          });
                        }}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            }
            details={
              selectedExtractionId !== null && (
                <ExtractionCard
                  extractionId={selectedExtractionId}
                  onClose={handleClose}
                  extractionsQuery={extractionsQuery}
                  createPresignedUrl={createPresignedUrl}
                />
              )
            }
          />
        </Layout>
      </LayoutStateProvider>
    </>
  );
}

interface ExtractionCardProps {
  extractionId: Extraction["id"];
  onClose: () => void;
  extractionsQuery: ReturnType<typeof useExtractionSearch>;
  createPresignedUrl: ReturnType<typeof useCreateExtractionPresignedUrl>;
}

function ExtractionCard({
  extractionId,
  onClose,
  extractionsQuery,
  createPresignedUrl,
}: ExtractionCardProps) {
  const [inEditMode, setInEditMode] = useState(false);

  const dataStore = useCurrentDataStore();

  const extractionQuery = useExtraction(extractionId, { select: selectData });
  const userQuery = useUser(extractionQuery.data?.createdBy, {
    select: selectData,
  });
  const logQuery = useLog(extractionQuery.data?.logId, { select: selectData });

  const updateExtraction = useUpdateExtraction(extractionId);

  const { control, handleSubmit, reset } = useForm<EditExtractionFormValues>({
    defaultValues: {
      name: extractionQuery.data?.name ?? "",
    },
    resolver: zodResolver(EditExtractionFormValues),
  });

  const onSubmit: SubmitHandler<EditExtractionFormValues> = function onSubmit(
    values
  ) {
    updateExtraction.mutate(values, {
      onSuccess() {
        setInEditMode(false);
      },
    });
  };

  function optionallyWrapWithForm(content: React.ReactNode) {
    if (inEditMode) {
      return <form onSubmit={handleSubmit(onSubmit)}>{content}</form>;
    } else {
      return content;
    }
  }

  function renderPermissionMessage() {
    if (updateExtraction.hasPermission === undefined) {
      return <Typography>Checking your permissions...</Typography>;
    } else if (!updateExtraction.hasPermission) {
      return (
        <IconicTypography icon={<Lock color="warning" />}>
          You don't have permission to update extractions
        </IconicTypography>
      );
    } else {
      return null;
    }
  }

  function renderServerErrorMessage() {
    if (!updateExtraction.isError) {
      return null;
    }

    if (
      updateExtraction.error instanceof ResponseError &&
      updateExtraction.error.response.status === 403
    ) {
      return (
        <IconicTypography icon={<Lock color="error" />}>
          Error: you don't have permission to update extractions
        </IconicTypography>
      );
    }

    return (
      <IconicTypography icon={<Error color="error" />}>
        An error occurred. Unable to update.
      </IconicTypography>
    );
  }

  function renderButtons() {
    invariant(extractionQuery.isSuccess, "Query hasn't been fetched yet");

    // Prevents function hoisting so TS won't complain that the query's
    // data may be undefined inside the function
    const handleEditClick = function handleEditClick() {
      setInEditMode(true);
      // Name may not have been defined when useForm was initially called.
      // However, it's definitely defined now so each time the user enters
      // edit mode, this will set the default values as if the form had
      // just been initialized
      reset({ name: extractionQuery.data.name ?? "" });
    };

    function handleCancelClick() {
      setInEditMode(false);
    }

    let content;
    if (inEditMode) {
      content = (
        <>
          {renderPermissionMessage()}
          {renderServerErrorMessage()}
          <LoadingButton
            type="submit"
            color="primary"
            variant="outlined"
            disabled={!updateExtraction.hasPermission}
            loading={updateExtraction.isLoading}
          >
            Save Changes
          </LoadingButton>
          <Button
            type="button"
            color="primary"
            variant="text"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </>
      );
    } else {
      content = (
        <>
          <LoadingButton
            href={createPresignedUrl.data?.url}
            loading={createPresignedUrl.isLoading}
            disabled={!createPresignedUrl.isSuccess}
            color="primary"
            variant="contained"
            startIcon={<CloudDownload />}
          >
            Download Extraction
          </LoadingButton>
          {createPresignedUrl.isError && (
            <IconicTypography icon={<Error color="error" />}>
              Unable to get download link
            </IconicTypography>
          )}
          <Button
            color="primary"
            variant="outlined"
            startIcon={<Edit />}
            onClick={handleEditClick}
          >
            Edit
          </Button>
        </>
      );
    }

    return <Stack spacing={2}>{content}</Stack>;
  }

  function renderName() {
    invariant(
      extractionQuery.isSuccess,
      "Can only render name for loaded query"
    );

    if (inEditMode) {
      const fieldId = "extraction-name-field";

      return (
        <DlGroup xs={12}>
          <Dt>
            <label htmlFor={fieldId}>Name</label>
          </Dt>
          <Dd>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} id={fieldId} fullWidth />
              )}
            />
          </Dd>
        </DlGroup>
      );
    } else {
      return renderDlGroup("Name", extractionQuery.data.name ?? "-", {
        xs: 12,
      });
    }
  }

  return (
    <Card>
      <CardHeader
        title={inEditMode ? "Edit Extraction" : "Details"}
        titleTypographyProps={{ component: "h2" }}
        action={
          <Tooltip title="Close panel">
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        {extractionQuery.isError ? (
          <IconicTypography icon={<Error color="error" />}>
            An error occurred fetching the extraction
          </IconicTypography>
        ) : extractionsQuery.isLoading || !extractionQuery.isSuccess ? (
          <Typography>Fetching extraction...</Typography>
        ) : (
          optionallyWrapWithForm(
            <>
              {renderButtons()}
              <Dl
                spacing={4}
                sx={{
                  mt: -2,
                  "& dd": {
                    wordBreak: "break-all",
                  },
                }}
              >
                {renderName()}
                {renderDlGroup(
                  "Log",
                  logQuery.isError ? (
                    <IconicTypography icon={<Error color="error" />}>
                      An error occurred fetching log
                    </IconicTypography>
                  ) : !logQuery.isSuccess ? (
                    "Fetching log..."
                  ) : (
                    <Link
                      component={RouterLink}
                      to={paths.makePlayerLocation({
                        url: dataStore,
                        logId: logQuery.data.id,
                      })}
                    >
                      {logQuery.data.name}
                    </Link>
                  ),
                  { xs: 12 }
                )}
                {renderDlGroup(
                  "Status",
                  <Chip {...chipPropsForExtraction(extractionQuery.data)} />,
                  { xs: 12 }
                )}
                {renderDlGroup(
                  "Created by",
                  userQuery.isError ? (
                    <IconicTypography icon={<Error color="error" />}>
                      An error occurred fetching name of creator
                    </IconicTypography>
                  ) : !userQuery.isSuccess ? (
                    "Fetching name of creator..."
                  ) : (
                    userQuery.data.username
                  ),
                  { xs: 12 }
                )}
                {renderDlGroup(
                  "Created on",
                  <Time date={extractionQuery.data.createdAt} />,
                  { xs: 12 }
                )}
                {renderDlGroup(
                  "Size",
                  renderExtractionSize(extractionQuery.data),
                  { xs: 12 }
                )}
                {renderDlGroup(
                  "Progress",
                  renderExtractionProgress(extractionQuery.data),
                  { xs: 12 }
                )}
              </Dl>
            </>
          )
        )}
      </CardContent>
    </Card>
  );
}

function useExtractionSearch(
  params: SearchParams,
  currentUserId: User["id"] | undefined,
  selectedExtractionId: Extraction["id"] | null
) {
  const extractionKeys = useExtractionKeys();

  const queryClient = useQueryClient();

  const canFilterByCurrentUser = currentUserId !== undefined;

  return useExtractions(
    {
      nameLike: params.search !== "" ? params.search : undefined,
      limit: params.limit,
      offset: params.offset,
      order: "created_at",
      sort: params.sort === SortOptions.Newest ? "desc" : "asc",
      createdBy:
        canFilterByCurrentUser && params.mine ? currentUserId : undefined,
    },
    {
      enabled: !params.mine || canFilterByCurrentUser,
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
      onSuccess({ data }) {
        const selectedExtraction = find(data, {
          id: selectedExtractionId as any,
        });

        if (selectedExtraction === undefined) {
          return;
        }

        const queryKey = extractionKeys.detail(selectedExtractionId);

        // To keep the details query in sync with the list query, whenever the
        // list query gets new results that contain the selected extraction,
        // the selected extraction query's cached data (if any) should be
        // overwritten.
        queryClient.cancelQueries(queryKey);
        queryClient.setQueryData<ExtractionFetchResponse>(queryKey, {
          data: selectedExtraction,
        });
      },
    }
  );
}

function calculatePresignedUrlStaleTime(
  fetchedAtMs: number,
  presignedUrl: string
): number {
  const parsedUrl = new URL(presignedUrl);
  const expiresInS = Number(
    parsedUrl.searchParams.get("X-Amz-Expires") ?? Infinity
  );

  const expiresAt = addSeconds(fetchedAtMs, expiresInS);

  return differenceInMilliseconds(expiresAt, Date.now());
}

function chipPropsForExtraction(extraction: Extraction): ChipProps {
  if (extraction.errored) {
    return { label: "Error", color: "error" };
  }

  if (extraction.status === "complete") {
    return { label: "Finished", color: "success" };
  }

  return { label: "Processing", color: "info" };
}

function renderExtractionSize(extraction: Extraction): string {
  const { size } = extraction;

  if (size === null) {
    return "-";
  }

  return prettyBytes(size);
}

function renderExtractionProgress(extraction: Extraction): string {
  const { progress } = extraction;

  if (progress === null) {
    return "-";
  }

  return new Intl.NumberFormat(undefined, { style: "percent" }).format(
    progress
  );
}
