import type React from "react";
import invariant from "invariant";
// @ts-ignore
import * as ROS3D from "ros3d";
import { Group } from "three";
import type { Record, Topic } from "../../../../../../services/datastore";
import type { Maybe } from "../../../../../../types";
import {
  LOW_POLY_STL_FILE,
  MARKER_ARRAY_MESSAGE_TYPE,
  STL_MODELS_PATH,
} from "../../constants";
import type { MinibotLog } from "../../types";
import type { SummaryTopic } from "./types";

export function findMarkerArrayTopics(
  routerLog: MinibotLog | null
): SummaryTopic[] {
  if (routerLog === null || routerLog.summary === null) {
    return [];
  }

  return Object.entries(routerLog.summary.topics)
    .filter(
      ([, { messageTypeName }]) => messageTypeName === MARKER_ARRAY_MESSAGE_TYPE
    )
    .map(([id, { name }]) => ({ id, name }));
}

export function findRouterLogTopicId(
  routerLog: MinibotLog | null,
  topicName: Topic["name"]
): Topic["id"] | null {
  if (routerLog === null || routerLog.summary === null) {
    return null;
  }

  const topicEntry = Object.entries(routerLog.summary.topics).find(
    ([, { name }]) => name.endsWith(topicName)
  );

  return topicEntry?.[0] ?? null;
}

export function findRouterLogTopicName(
  routerLog: MinibotLog | null,
  topicId: Topic["id"]
): Topic["name"] | null {
  if (routerLog === null || routerLog.summary === null) {
    return null;
  }

  const topicEntry = Object.entries(routerLog.summary.topics).find(
    ([entryTopicId]) => entryTopicId.endsWith(topicId)
  );

  return topicEntry?.[1].name ?? null;
}

export function disableLogs<TReturnValue>(
  fn: () => TReturnValue
): TReturnValue {
  const originalLog = console.log;
  const originalWarn = console.warn;

  function noop() {}

  console.log = noop;
  console.warn = noop;

  const returnValue = fn();

  console.log = originalLog;
  console.warn = originalWarn;

  return returnValue;
}

export function requireViewer(viewerRef: React.MutableRefObject<any>): any {
  invariant(viewerRef.current != null, "Viewer ref must be defined");

  return viewerRef.current;
}

export function renderMarkers(
  viewerRef: React.MutableRefObject<any>,
  record: Pick<Record, "messageData" | "topicId">
) {
  const markers = record.messageData.markers;
  if (markers == null || markers.length === 0) {
    return;
  }

  const viewer = requireViewer(viewerRef);

  let group = viewer.scene.getObjectByName(record.topicId);

  if (group === undefined) {
    // Not in scene yet
    group = new Group();
    group.name = record.topicId;

    viewer.scene.add(group);
  } else {
    disposeOfGroupedMarkers(group);
  }

  markers.forEach((message: any) => {
    try {
      const marker = disableLogs(() => new ROS3D.Marker({ message }));

      group.add(marker);
    } catch (e) {
      console.error("Error occurred creating marker:", e);
    }
  });
}

function disposeOfGroupedMarkers(group: any) {
  // All children should be ros3d Markers
  group.children.forEach((marker: any) => {
    marker.dispose();
  });

  group.remove(...group.children);
}

export function removeMarkersIfPresent(
  viewerRef: React.MutableRefObject<any>,
  name: string
) {
  const viewer = requireViewer(viewerRef);

  const markersGroup = viewer.scene.getObjectByName(name);
  if (markersGroup === undefined) {
    return;
  }

  viewer.scene.remove(markersGroup);

  disposeOfGroupedMarkers(markersGroup);
}

export function renderBotMarkers(
  viewerRef: React.MutableRefObject<any>,
  record: Pick<Record, "messageData"> | undefined,
  currentBotName: Maybe<string>
) {
  const messageData = record?.messageData;
  if (messageData == null) {
    return;
  }

  const viewer = requireViewer(viewerRef);

  for (const botMessage of messageData.data) {
    const botName = botMessage.robot_name;

    let botMarker = viewer.scene.getObjectByName(botName);
    if (botMarker === undefined) {
      botMarker = createBotMarker(botName, botName === currentBotName);
      viewer.scene.add(botMarker);
    }

    botMarker.setPose(botMessage.pose);

    let labelMarker = viewer.scene.getObjectByName(`${botName}-label`);
    if (labelMarker === undefined) {
      labelMarker = createLabelMarker(botName);
      viewer.scene.add(labelMarker);
    }

    labelMarker.setPose({
      ...botMessage.pose,
      position: {
        ...botMessage.pose.position,
        z: botMessage.pose.position.z + 0.5,
      },
    });
  }
}

function createBotMarker(name: string, isCurrent: boolean) {
  const message = {
    pose: {
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      orientation: {
        w: 1.0,
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
    },
    type: ROS3D.MARKER_MESH_RESOURCE,
    // ros3d assumes mesh resource file names start with "package://"
    mesh_resource: `package://${LOW_POLY_STL_FILE}`,
    color: {
      a: 1,
      b: 0.0,
      g: isCurrent ? 0.0 : 1.0,
      r: isCurrent ? 1.0 : 0.0,
    },
    scale: {
      x: 1.0,
      y: 1.0,
      z: 1.0,
    },
  };

  const botMarker = disableLogs(
    () => new ROS3D.Marker({ path: STL_MODELS_PATH, message })
  );
  botMarker.scale.set(0.001, 0.001, 0.001);
  botMarker.name = name;

  return botMarker;
}

function createLabelMarker(botName: string) {
  const labelMarker = disableLogs(
    () =>
      new ROS3D.Marker({
        message: {
          pose: {
            position: {
              x: 0,
              y: 0,
              z: 0,
            },
            orientation: {
              w: 1.0,
              x: 0.0,
              y: 0.0,
              z: 0.0,
            },
          },
          type: ROS3D.MARKER_TEXT_VIEW_FACING,
          text: botName,
          color: {
            a: 1.0,
            b: 0.10588235408067703,
            g: 0.6705882549285889,
            r: 0.5,
          },
          scale: {
            x: 0.35,
            y: 0.0,
            z: 0.0,
          },
        },
      })
  );
  labelMarker.name = `${botName}-label`;

  return labelMarker;
}
