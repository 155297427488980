import { Typography, useTheme } from "@mui/material";
import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { usePlaybackSource } from "../../../PlaybackProvider";
import { seek } from "../../../playbackReducer";
import type { LogSummary } from "../utils";

export interface EventTimelineProps {
  logSummary: LogSummary | null;
}

export default function EventTimeline({ logSummary }: EventTimelineProps) {
  const playbackSource = usePlaybackSource();

  const theme = useTheme();

  if (playbackSource.isLoading) {
    return null;
  }

  function handlePointClick(point: any) {
    playbackSource.dispatch(seek(point.payload.startTimeMs));
  }

  if (logSummary === null) {
    return <Typography fontStyle="italic">(No event data)</Typography>;
  } else {
    return (
      <ResponsiveContainer height="100%" width="100%">
        <ScatterChart
          data={logSummary.ingestions}
          margin={{ left: -1, right: -1 }}
        >
          <CartesianGrid horizontal={false} strokeDasharray="6 3" />
          <XAxis
            hide
            type="number"
            dataKey="startTimeMs"
            domain={playbackSource.boundsMs}
            tick={false}
            allowDataOverflow
          />
          <YAxis hide domain={[0, 1]} dataKey={() => 0.5} />
          <ZAxis range={[150, 150]} />
          <Scatter
            fill="darkgrey"
            stroke="white"
            onClick={handlePointClick}
            style={{ cursor: "pointer" }}
            isAnimationActive={false}
          />
          <ReferenceLine
            x={playbackSource.timestampMs}
            strokeWidth={2}
            stroke={theme.palette.grey["600"]}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
}
