import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { enableAllPlugins } from "immer";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import DefaultErrorFallback from "./components/DefaultErrorFallback";
import GlobalLoadingFallback from "./components/GlobalLoadingFallback";
import SuggestChromeAlert from "./components/SuggestChromeAlert";
import "./css/styles.css";
import { DataStoreProvider } from "./domain/datastores";
import * as paths from "./paths";
import DarkModeProvider from "./providers/DarkModeProvider";
import ThemeProvider from "./providers/ThemeProvider";
import { LogrusProvider, useLogrus } from "./services/logrus";
import Extractions from "./views/Extractions";
import Home from "./views/Home";
import Player from "./views/Player";
import SignIn from "./views/SignIn";

enableAllPlugins();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Preserve v3 behavior, opting out of smart query tracking (for now).
      // Smart tracking should be done on a case-by-case basis to avoid
      // unnoticed bugs
      notifyOnChangeProps: "all",
      // API clients use `fetch-retry` library to handle refetching
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // Default staleTime of Infinity for all queries. We deal with too
      // much data to be refetching willy-nilly and I'd prefer to control
      // when refetching happens anyways
      staleTime: Infinity,
    },
  },
});

export default function App() {
  return (
    <DarkModeProvider>
      <HelmetProvider>
        <ThemeProvider>
          <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
            <QueryClientProvider client={queryClient}>
              <Router>
                <LogrusProvider loadingFallback={<GlobalLoadingFallback />}>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <DataStoreProvider>
                      <SnackbarProvider maxSnack={1}>
                        <RoutesHandler />
                        <SuggestChromeAlert />
                      </SnackbarProvider>
                    </DataStoreProvider>
                  </QueryParamProvider>
                </LogrusProvider>
              </Router>
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </QueryClientProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </HelmetProvider>
    </DarkModeProvider>
  );
}

function RoutesHandler() {
  const authStatus = useLogrus();

  if (authStatus === "authenticated") {
    return (
      <Routes>
        <Route path={paths.PLAYER} element={<Player />} />
        <Route path={paths.EXTRACTIONS} element={<Extractions />} />
        <Route path={paths.INDEX} element={<Home />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    );
  } else {
    return <SignIn />;
  }
}

function NoMatch() {
  const location = useLocation();

  const { state, ...to } = paths.makeIndexLocation(undefined, {
    unmatchedPath: location.pathname,
  });

  return <Navigate replace to={to} state={state} />;
}
