import React from "react";
import { Box, Container, Grid } from "@mui/material";

export interface ListDetailLayoutProps {
  search: React.ReactNode;
  list: React.ReactNode;
  details?: React.ReactNode;
}

export default function ListDetailLayout({
  search,
  list,
  details,
}: ListDetailLayoutProps) {
  const showDetails = Boolean(details);

  return (
    <Box
      data-scroll-root=""
      sx={{ flexGrow: 1, minWidth: 0, overflowY: "auto", py: 4 }}
    >
      <Container fixed>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={showDetails ? 8 : 12}>
            {search}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} lg>
                {list}
              </Grid>
              {showDetails && (
                <Grid item xs={12} lg={4}>
                  {details}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
