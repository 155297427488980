/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import { Log, LogFromJSON, LogFromJSONTyped, LogToJSON } from "./Log";

/**
 *
 * @export
 * @interface LogFetchResponse
 */
export interface LogFetchResponse {
  /**
   *
   * @type {Log}
   * @memberof LogFetchResponse
   */
  data: Log;
}

export function LogFetchResponseFromJSON(json: any): LogFetchResponse {
  return LogFetchResponseFromJSONTyped(json, false);
}

export function LogFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LogFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: LogFromJSON(json["data"]),
  };
}

export function LogFetchResponseToJSON(value?: LogFetchResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: LogToJSON(value.data),
  };
}
