import type { UseQueryOptions } from "@tanstack/react-query";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { millisecondsToSeconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import type { StrictOmit } from "ts-essentials";
import { getClients } from "../../domain/datastores";
import { circumventPagination, mergeEnabledOption } from "../../queries";
import { API_MAX_LIMIT } from "../../queries";
import type {
  ExtractionCreateRequest,
  ListRecordsRequest,
  ListTopicsRequest,
  Log,
  Record,
  RecordListResponse,
  TopicListResponse,
} from "../../services/datastore";
import type { ResolvedKeyFactory, SetMaybe } from "../../types";
import type { RecordListCache } from "./recordListCache";
import type { DraftExtractionTopic } from "./types";

export type UseRecordQueriesRequest = SetMaybe<ListRecordsRequest, "topicId">;

export interface LocalRecord extends StrictOmit<Record, "imageUrl"> {
  image: Blob | null;
}

export type LocalRecordListResponse = StrictOmit<RecordListResponse, "data"> & {
  data: Array<LocalRecord>;
};

export interface UseRecordsQueriesOptions<TData = LocalRecordListResponse> {
  request: UseRecordQueriesRequest;
  options?: Pick<
    UseQueryOptions<LocalRecordListResponse, unknown, TData>,
    "select" | "enabled" | "meta" | "cacheTime"
  >;
  cache?: RecordListCache;
}

export interface UseCreateExtractionArgs {
  logId: Log["id"];
  draftExtractionTopics: DraftExtractionTopic[];
  name: ExtractionCreateRequest["name"];
}

export function useTopicKeys() {
  const factory = {
    all: ["topics"] as const,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListTopicsRequest) =>
      [...factory.lists(), request] as const,
  } as const;

  return factory;
}

export type TopicKeys = ResolvedKeyFactory<typeof useTopicKeys>;

export function useRecordKeys() {
  const factory = {
    all: ["records"] as const,
    lists: () => [...factory.all, "list"] as const,
    list: (request: UseRecordQueriesRequest) =>
      [...factory.lists(), request] as const,
  } as const;

  return factory;
}

export type RecordKeys = ResolvedKeyFactory<typeof useRecordKeys>;

export function useTopics<TData = TopicListResponse>(
  request: ListTopicsRequest,
  options?: UseQueryOptions<
    TopicListResponse,
    unknown,
    TData,
    TopicKeys["list"]
  >
) {
  return useQuery({
    queryKey: useTopicKeys().list(request),
    queryFn(context) {
      const { topicApi } = getClients();

      return request.limit === -1
        ? circumventPagination(
            topicApi.listTopics.bind(topicApi),
            API_MAX_LIMIT,
            request,
            context
          )
        : topicApi.listTopics(request, context);
    },
    ...options,
  });
}

export function isListRecordsRequest(
  request: UseRecordQueriesRequest
): request is ListRecordsRequest {
  return request.topicId != null;
}

export function useRecordsQueries<TData = LocalRecordListResponse>(
  queries: UseRecordsQueriesOptions<TData>[]
) {
  const recordKeys = useRecordKeys();

  return useQueries({
    queries: queries.map(
      ({
        request,
        options,
        cache,
      }: UseRecordsQueriesOptions<TData>): UseQueryOptions<
        LocalRecordListResponse,
        unknown,
        TData
      > => ({
        queryKey: recordKeys.list(request),
        async queryFn(context) {
          invariant(isListRecordsRequest(request), "Topic ID must be defined");

          const { topicApi } = getClients();

          const response = await topicApi.listRecords(request, context);

          const localRecordResponse = {
            ...response,
            data: await Promise.all(
              response.data.map(async (record) => ({
                ...record,
                image: await getLocalImage(record, context.signal),
              }))
            ),
          };

          cache?.set(request, localRecordResponse);

          return localRecordResponse;
        },
        staleTime: Infinity,
        cacheTime: secondsToMilliseconds(20),
        ...options,
        enabled: mergeEnabledOption(options, isListRecordsRequest(request)),
        initialData() {
          if (!isListRecordsRequest(request)) {
            return;
          }

          return cache?.get(request);
        },
      })
    ),
  });
}

export function useCreateExtraction() {
  return useMutation({
    async mutationFn({
      logId,
      draftExtractionTopics,
      name,
    }: UseCreateExtractionArgs) {
      const { extractionApi } = getClients();

      const newExtraction = await extractionApi.createExtraction({
        extractionCreateRequest: {
          logId,
          name,
        },
      });

      await Promise.all(
        draftExtractionTopics.map((draftExtractionTopic) =>
          extractionApi.createExtractionTopic({
            extractionId: newExtraction.data.id,
            extractionTopicCreateRequest: {
              topicId: draftExtractionTopic.topicId,
              startTime: millisecondsToSeconds(
                draftExtractionTopic.startTimeMs
              ),
              endTime: millisecondsToSeconds(draftExtractionTopic.endTimeMs),
            },
          })
        )
      );

      return extractionApi.updateExtraction({
        extractionId: newExtraction.data.id,
        extractionUpdateRequest: {
          queued: true,
        },
      });
    },
  });
}

// Utilities

async function getLocalImage(
  record: Record,
  signal: AbortSignal | undefined
): Promise<LocalRecord["image"]> {
  if (record.imageUrl === null) {
    return null;
  }

  let response;
  try {
    response = await fetch(record.imageUrl, { signal });
  } catch {
    return null;
  }

  if (!response.ok) {
    return null;
  }

  return response.blob();
}
