import React from "react";
import { Alert, Link } from "@mui/material";
import { filter, find, some } from "lodash";
import type { Topic } from "../../../../../../../services/datastore";
import type { LoadedPlaybackSource } from "../../../../../playbackReducer";
import type { DraftExtractionTopic } from "../../../../../types";
import type { DraftExtraction } from "./useDraftExtraction";
import { draftTopic } from "./useDraftExtraction";

export interface TfStaticAlertProps {
  playbackTopics: Topic[];
  playerBounds: LoadedPlaybackSource["boundsMs"];
  draftExtractionTopics: DraftExtractionTopic[];
  dispatch: DraftExtraction["dispatch"];
}

export default function TfStaticAlert({
  playbackTopics,
  playerBounds,
  draftExtractionTopics,
  dispatch,
}: TfStaticAlertProps) {
  const tfStaticTopic = find(playbackTopics, { name: "/tf_static" });

  if (tfStaticTopic === undefined) {
    // /tf_static isn't in this log
    return null;
  }

  if (some(draftExtractionTopics, { topicId: tfStaticTopic.id })) {
    // /tf_static is already in extraction
    return null;
  }

  // Draft list only includes topic IDs, so need to use those to find list
  // of actual topics
  const draftedTopics = filter(playbackTopics, ({ id }) =>
    some(draftExtractionTopics, { topicId: id })
  );

  if (!mightNeedTfStatic(draftedTopics)) {
    // Of the drafted topics, none merit showing the user an alert
    return null;
  }

  return (
    <Alert severity="info" variant="filled">
      Some topics in your extraction may need the <code>/tf_static</code> topic.
      If you need this topic, you can{" "}
      <Link
        component="button"
        color="inherit"
        onClick={() =>
          dispatch(
            draftTopic(tfStaticTopic, {
              startTimeMs: playerBounds[0],
              endTimeMs: playerBounds[1],
            })
          )
        }
      >
        add it to your extraction
      </Link>
      .
    </Alert>
  );
}

const SENSOR_MSGS_RE = /^sensor_msgs\/\w/;

function mightNeedTfStatic(draftedTopics: Topic[]): boolean {
  return some(draftedTopics, ({ name, messageTypeName }) => {
    if (name === "/tf") {
      return true;
    }

    if (messageTypeName === null) {
      return false;
    }

    return SENSOR_MSGS_RE.test(messageTypeName);
  });
}
