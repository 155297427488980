/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  ClientMethod,
  ClientMethodFromJSON,
  ClientMethodFromJSONTyped,
  ClientMethodToJSON,
} from "./ClientMethod";
import {
  PresignedURLParams,
  PresignedURLParamsFromJSON,
  PresignedURLParamsFromJSONTyped,
  PresignedURLParamsToJSON,
} from "./PresignedURLParams";

/**
 *
 * @export
 * @interface CreatePresignedURLRequest
 */
export interface CreatePresignedURLRequest {
  /**
   *
   * @type {ClientMethod}
   * @memberof CreatePresignedURLRequest
   */
  method: ClientMethod;
  /**
   *
   * @type {PresignedURLParams}
   * @memberof CreatePresignedURLRequest
   */
  params: PresignedURLParams;
}

export function CreatePresignedURLRequestFromJSON(
  json: any
): CreatePresignedURLRequest {
  return CreatePresignedURLRequestFromJSONTyped(json, false);
}

export function CreatePresignedURLRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreatePresignedURLRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    method: ClientMethodFromJSON(json["method"]),
    params: PresignedURLParamsFromJSON(json["params"]),
  };
}

export function CreatePresignedURLRequestToJSON(
  value?: CreatePresignedURLRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    method: ClientMethodToJSON(value.method),
    params: PresignedURLParamsToJSON(value.params),
  };
}
