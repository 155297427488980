import type { ListRecordsRequest, Topic } from "../../services/datastore";
import type { LocalRecordListResponse } from "./queries";

type ResponseMap = Map<string, LocalRecordListResponse>;

/**
 * Cache record list responses for a given panel's chunk requests.
 *
 * While react-query's caching and garbage collection strategy works for most
 * of Studio's use-cases, the player panels need to use a different strategy.
 * Record lists not containing image blobs should be cached for as long as there
 * are panels open which reference the request's topic. Only when all panels
 * for a request's topic have been removed should the responses be eligible for
 * cache eviction.
 *
 * There are 2 ways this behavior could be implemented in react-query:
 *
 * 1. Create observers for every chunk in the topic but only enable the
 *    observers whose chunks are needed for the current record window or
 *    whose chunks need to be buffered.
 * 2. Only create observers for the chunks needed for the current record window
 *    or whose chunks need to be buffered. Set a `cacheTime` of `Infinity` on
 *    each observed query.
 *
 * Option (1) is a problem because it means calculating **all** chunks for a
 * topic **and** creating observers for each one - most of which won't do much
 * on a given render - solely to keep react-query from evicting a chunk's
 * response once it's been fetched. This approach has already caused issues for
 * long logs, especially corrupted logs whose start times are 0 (i.e. the Unix
 * epoch).
 *
 * Option (2) is better, but the caveat is that while `cacheTime: Infinity`
 * keeps fetched responses around, there's no way to update a query's
 * `cacheTime` without creating an observer for it. Consequently, when the last
 * panel for a given topic is removed, there's no way to inform react-query to
 * update the `cacheTime` for the now-unused chunk responses. The queries will
 * stay in react-query's cache indefinitely, unable to be garbage collected.
 * An external collection of timeouts would be needed where each timeout would
 * tell react-query to remove all queries for a given topic.
 *
 * This class enables an approach taking the best of both options: responses
 * can be cached for the lifetime of a panel without needing query observers
 * for each response. Additionally, since entries can be synchronously
 * retrieved by request parameters, chunk queries can actually have a
 * `cacheTime` of `0` (meaning as soon as they're not being observed they're
 * evicted from react-query's cache) and can be instantly recreated and
 * populated later by loading from this cache in a query's `initialData` option.
 *
 * This cache additionally provides a means to declaratively specify which
 * topics' cached responses should be preserved, as opposed to the
 * more-difficult approach of specifying the topics that shouldn't be preserved.
 * By passing a list of topic IDs whose entries should be preserved, the
 * {@link RecordListCache#preserveTopics preserveTopics} method enables passing
 * the names of topics from open panels in a `useEffect` whenever those panels
 * change. The cache will determine if there are any entries for a topic whose
 * ID wasn't provided and schedule eviction.
 */
export class RecordListCache {
  readonly #topicResponsesMap = new Map<Topic["id"], ResponseMap>();
  readonly #evictionTimeoutsMap = new Map<
    Topic["id"],
    ReturnType<typeof setTimeout>
  >();

  readonly #evictionTimeoutMs: number;

  constructor(evictionTimeoutMs: number) {
    this.#evictionTimeoutMs = evictionTimeoutMs;
  }

  get(request: ListRecordsRequest): LocalRecordListResponse | undefined {
    const responseMap = this.#topicResponsesMap.get(request.topicId);
    if (responseMap === undefined) {
      return;
    }

    return responseMap.get(this.#makeCacheKey(request));
  }

  set(request: ListRecordsRequest, response: LocalRecordListResponse): void {
    let responseMap = this.#topicResponsesMap.get(request.topicId);
    if (responseMap === undefined) {
      responseMap = new Map();

      this.#topicResponsesMap.set(request.topicId, responseMap);
    }

    responseMap.set(this.#makeCacheKey(request), response);
  }

  preserveTopics(topicIds: Array<Topic["id"]>): void {
    for (const topicId of this.#topicResponsesMap.keys()) {
      const shouldPreserve = topicIds.includes(topicId);
      const hasEvictionTimeout = this.#evictionTimeoutsMap.has(topicId);

      if (shouldPreserve && hasEvictionTimeout) {
        clearTimeout(this.#evictionTimeoutsMap.get(topicId)!);

        this.#evictionTimeoutsMap.delete(topicId);
      }

      if (!shouldPreserve && !hasEvictionTimeout) {
        this.#setEvictionTimeout(topicId);
      }
    }
  }

  #makeCacheKey(request: ListRecordsRequest): string {
    // TODO: Since the cache is really only intended for holding non-image
    //   record list responses and those requests only vary by topic ID,
    //   start time, end time, and frequency, the sort and order fields can
    //   probably be omitted from this. Topic ID could probably be omitted as
    //   well since each cache is already keyed by its topic ID in the top-level
    //   map.
    const { topicId, sort, order, timestampGte, timestampLt, frequency } =
      request;

    return `${topicId}:${sort}:${order}:${timestampGte}:${timestampLt}:${frequency}`;
  }

  #setEvictionTimeout(topicId: Topic["id"]): void {
    const evictionTimeoutId = setTimeout(() => {
      this.#topicResponsesMap.delete(topicId);
      this.#evictionTimeoutsMap.delete(topicId);
    }, this.#evictionTimeoutMs);

    this.#evictionTimeoutsMap.set(topicId, evictionTimeoutId);
  }
}
