import React from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import Error from "../../../../../components/Error";
import Loading from "../../../../../components/Loading";
import { useCurrentDataStore } from "../../../../../domain/datastores";
import * as paths from "../../../../../paths";
import { usePlaybackSource } from "../../../PlaybackProvider";
import { useBotLogs, useEnsuredPlayerLogId } from "../hooks";
import type { MinibotLog } from "../types";
import { parseLogNameParts } from "../utils";
import EventTimeline from "./EventTimeline";

export interface FleetOverviewProps {
  log: MinibotLog;
}

export default function FleetOverview({ log }: FleetOverviewProps) {
  const logId = useEnsuredPlayerLogId();

  const botLogsQuery = useBotLogs(logId);

  const playbackSource = usePlaybackSource();

  if (playbackSource.isLoading || botLogsQuery.isLoading) {
    return <Loading type="circular" />;
  }

  if (botLogsQuery.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p">
          Error fetching fleet timeline data
        </Typography>
      </Error>
    );
  }

  return (
    <Stack
      sx={{
        minHeight: 0,
        "& > *": {
          bgcolor: "background.paper",
        },
        "& svg": {
          // Prevents the SVG's line height from causing vertical overflow
          display: "block",
        },
      }}
    >
      {botLogsQuery.data.data.length > 0 && (
        <Box
          sx={{
            // Tells the browser this section's baseline is enough pixels
            // to show all rows but it can shrink as needed. Since Virtuoso
            // only renders enough items to fit in its viewport, this box
            // must have a defined height for Virtuoso to grow to so it knows
            // how many items to actually render
            flex: `0 1 ${35 * botLogsQuery.data.data.length}px`,
            minHeight: 0,
          }}
        >
          <Virtuoso
            style={{ scrollbarGutter: "stable" }}
            data={botLogsQuery.data.data}
            fixedItemHeight={35}
            // 2 rows
            increaseViewportBy={70}
            computeItemKey={(_, botLog) => botLog.id}
            itemContent={(index, botLog) => (
              <TimelineRow log={botLog} isEven={index % 2 === 0} />
            )}
          />
        </Box>
      )}
      <TimelineRow log={log} isEven={false} isRouter />
    </Stack>
  );
}

function TimelineRow({
  log,
  isEven,
  isRouter = false,
}: {
  log: MinibotLog;
  isEven: boolean;
  isRouter?: boolean;
}) {
  const dataStore = useCurrentDataStore();
  const playbackSource = usePlaybackSource();

  const nameParts = parseLogNameParts(log.name);

  let name;
  if (isRouter) {
    name = <Typography>Router</Typography>;
  } else {
    name = (
      <Link
        component={RouterLink}
        to={paths.makePlayerLocation({
          url: dataStore,
          logId: log.id,
          t: playbackSource.timestampMs,
        })}
        target="_blank"
        // "noopener" isn't strictly necessary here because it's an internal
        // link but shouldn't hurt to add it
        rel="noopener"
      >
        {nameParts?.name ?? log.name}
      </Link>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{
        height: 35,
        alignItems: "center",
        ...(isEven && {
          bgcolor: (theme) =>
            theme.palette.mode === "light" ? "grey.100" : "grey.900",
        }),
        ...(isRouter && {
          scrollbarGutter: "stable",
          overflowY: "hidden",
          flex: "none",
          borderTop: 1,
          borderColor: "divider",
        }),
      }}
    >
      <Box sx={{ width: 150, flex: "none" }}>{name}</Box>
      <Box sx={{ height: 1, flex: 1, minWidth: 0 }}>
        <EventTimeline logSummary={log.summary} />
      </Box>
    </Stack>
  );
}
