import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import invariant from "invariant";
import { getClients } from "../domain/datastores";
import type {
  Group,
  GroupFetchResponse,
  GroupListResponse,
  ListGroupsRequest,
} from "../services/datastore";
import type { Maybe, ResolvedKeyFactory } from "../types";
import { circumventPagination, mergeEnabledOption } from "./utils";

export function useGroupKeys() {
  const factory = {
    all: ["groups"] as const,
    lists: () => [...factory.all, "list"] as const,
    list: (request: ListGroupsRequest) =>
      [...factory.lists(), request] as const,
    details: () => [...factory.all, "details"] as const,
    detail: (groupId: Maybe<Group["id"]>) =>
      [...factory.details(), groupId] as const,
  } as const;

  return factory;
}

export type GroupKeys = ResolvedKeyFactory<typeof useGroupKeys>;

export function useGroups<TData = GroupListResponse>(
  request: ListGroupsRequest,
  options?: UseQueryOptions<
    GroupListResponse,
    unknown,
    TData,
    GroupKeys["list"]
  >
) {
  return useQuery({
    queryKey: useGroupKeys().list(request),
    queryFn(context) {
      const { groupApi } = getClients();

      if (request.limit === -1) {
        return circumventPagination(
          groupApi.listGroups.bind(groupApi),
          100,
          request,
          context
        );
      } else {
        return groupApi.listGroups(request, context);
      }
    },
    ...options,
  });
}

export function useGroup<TData = GroupFetchResponse>(
  groupId: Maybe<Group["id"]>,
  options?: UseQueryOptions<
    GroupFetchResponse,
    unknown,
    TData,
    GroupKeys["detail"]
  >
) {
  return useQuery({
    queryKey: useGroupKeys().detail(groupId),
    queryFn(context) {
      invariant(groupId != null, "Group ID must be defined");

      const { groupApi } = getClients();

      return groupApi.getGroup({ groupId }, context);
    },
    ...options,
    enabled: mergeEnabledOption(options, groupId != null),
  });
}
