import type React from "react";
import { useCallback, useEffect } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQueries } from "@tanstack/react-query";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { findLast, without } from "lodash";
import { useImmer } from "use-immer";
import { useSessionStorage } from "../../../../../../hooks";
import type { Topic } from "../../../../../../services/datastore";
import type { Maybe } from "../../../../../../types";
import { usePlaybackSource } from "../../../../PlaybackProvider";
import { useRecordWindow, useTimestampOffsets } from "../../../../hooks";
import { useRecordKeys, useRecordsQueries } from "../../../../queries";
import type { PlayerRecord } from "../../../../types";
import {
  DEBUG_MODE_STORAGE_KEY,
  DEBUG_TIME_STORAGE_KEY,
  LANE_BOUNDARIES_MARKERS_TOPIC,
  MARKER_TOPICS_STORAGE_KEY,
  ROUTER_PLAYBACK_TOPIC,
} from "../../constants";
import type { MinibotLog } from "../../types";
import {
  computeDebugValues,
  deriveSelectionStatuses,
  FRAME_REQUEST_LIMIT,
  makeFrameQueriesResults,
  makeFrameRequests,
} from "./debug-utils";
import type { StaticMarkerQueryStatus, SummaryTopic } from "./types";
import {
  findMarkerArrayTopics,
  findRouterLogTopicId,
  removeMarkersIfPresent,
  renderBotMarkers,
  renderMarkers,
} from "./utils";

export type BotMarkersQueryResult =
  | { status: "loading" }
  | { status: "error" }
  | { status: "absent" }
  | { status: "stale"; staleTimeMs: number }
  | { status: "success" };

export function useSelectedTopics(routerLog: MinibotLog | null) {
  const topics = findMarkerArrayTopics(routerLog);

  const [selectedTopics, setSelectedTopics] = useSessionStorage<
    Array<Topic["id"]>
  >(
    `${MARKER_TOPICS_STORAGE_KEY}.${routerLog?.id}`,
    topics
      .filter(({ name }) => [LANE_BOUNDARIES_MARKERS_TOPIC].includes(name))
      .map(({ id }) => id)
  );

  return { topics, selectedTopics, setSelectedTopics };
}

function useDebugMode(routerLog: MinibotLog | null) {
  const [inDebugMode, setInDebugMode] = useSessionStorage(
    `${DEBUG_MODE_STORAGE_KEY}.${routerLog?.id}`,
    false
  );
  const [debugTime, setDebugTime] = useSessionStorage<number | null>(
    `${DEBUG_TIME_STORAGE_KEY}.${routerLog?.id}`,
    null
  );

  return { inDebugMode, setInDebugMode, debugTime, setDebugTime };
}

export function useMarkerTopics(
  routerLog: MinibotLog | null,
  viewerRef: React.MutableRefObject<any>
) {
  const { topics, selectedTopics, setSelectedTopics } =
    useSelectedTopics(routerLog);

  const [playbackTopicStatuses, setPlaybackTopicStatuses] = useImmer(
    () => new Map<Topic["id"], StaticMarkerQueryStatus>()
  );

  const routerPlaybackTopicId = findRouterLogTopicId(
    routerLog,
    ROUTER_PLAYBACK_TOPIC
  );

  const topicIds =
    routerPlaybackTopicId === null
      ? selectedTopics
      : [routerPlaybackTopicId, ...selectedTopics];

  const playbackSource = usePlaybackSource();
  const { inDebugMode, setInDebugMode, debugTime, setDebugTime } =
    useDebugMode(routerLog);

  const recordKeys = useRecordKeys();

  const recordCountQueries = useRecordsQueries(
    topicIds.map((topicId) => ({
      request: {
        topicId,
        limit: 0,
      },
      options: {
        select(response) {
          return response.count;
        },
      },
    }))
  );

  const priorRecordsCountQueries = useTimestampOffsets({
    requests: topicIds.map((topicId) => ({
      topicId,
      filters: {
        queryKey: recordKeys.list({
          topicId,
          limit: FRAME_REQUEST_LIMIT,
        }),
        predicate(query) {
          return (
            query.state.status === "success" &&
            Boolean(query.meta?.isMarkerTopic)
          );
        },
      },
    })),
    timestamp: debugTime,
  });

  const frameQueries = useDebugRequests(
    topicIds,
    recordCountQueries,
    debugTime,
    priorRecordsCountQueries
  );

  const selected = deriveSelectionStatuses(
    topicIds,
    inDebugMode,
    playbackTopicStatuses,
    recordCountQueries,
    priorRecordsCountQueries,
    frameQueries
  );

  const isLoading = new Set(selected.values()).has("loading");

  const { previousTimestamp, currentTimestamp, nextTimestamp, records } =
    computeDebugValues(debugTime, frameQueries);

  useEffect(
    function renderCurrentRecords() {
      records.forEach((record) => {
        if (record.topicId === routerPlaybackTopicId) {
          renderBotMarkers(viewerRef, record, null);
        } else {
          renderMarkers(viewerRef, record);
        }
      });
    },
    [records, routerPlaybackTopicId, viewerRef]
  );

  const hasPreviousFrame = previousTimestamp !== undefined;
  const hasNextFrame = nextTimestamp !== undefined;

  const setStatus = useCallback(
    (topicId: Topic["id"], status: StaticMarkerQueryStatus) => {
      setPlaybackTopicStatuses((draft) => {
        draft.set(topicId, status);
      });
    },
    [setPlaybackTopicStatuses]
  );

  return {
    topics,
    selected,
    routerPlaybackTopicId,
    setStatus,
    isLoading,
    handleTopicClick(topic: SummaryTopic) {
      removeMarkersIfPresent(viewerRef, topic.id);

      setPlaybackTopicStatuses((draft) => {
        if (draft.has(topic.id)) {
          draft.delete(topic.id);
        }
      });

      setSelectedTopics((prevSelectedTopics) => {
        const existingTopicId = prevSelectedTopics.find(
          (prevTopicId) => prevTopicId === topic.id
        );

        if (existingTopicId !== undefined) {
          return without(prevSelectedTopics, existingTopicId);
        } else {
          return [...prevSelectedTopics, topic.id];
        }
      });
    },
    inDebugMode,
    toggleDebugMode() {
      invariant(!playbackSource.isLoading, "Playback hasn't loaded");

      const willEnterDebugMode = !inDebugMode;

      setInDebugMode(willEnterDebugMode);

      setPlaybackTopicStatuses((draft) => {
        draft.clear();
      });

      if (willEnterDebugMode) {
        setDebugTime(playbackSource.timestampMs / 1_000);
      } else {
        setDebugTime(null);

        selectedTopics.forEach((topicId) => {
          removeMarkersIfPresent(viewerRef, topicId);
        });
      }
    },
    debugTimeMs:
      currentTimestamp === undefined ? undefined : currentTimestamp * 1_000,
    syncWithPlayback() {
      invariant(!playbackSource.isLoading, "Playback hasn't loaded");

      setDebugTime(playbackSource.timestampMs / 1_000);
    },
    hasPreviousFrame,
    goToPreviousFrame() {
      invariant(hasPreviousFrame, "No previous frame");

      setDebugTime(previousTimestamp);
    },
    hasNextFrame,
    goToNextFrame() {
      invariant(hasNextFrame, "No next frame");

      setDebugTime(nextTimestamp);
    },
  };
}

function useDebugRequests(
  topicIds: string[],
  recordCountQueries: Array<UseQueryResult<number>>,
  debugTime: number | null,
  priorRecordsCountQueries: Array<UseQueryResult<number>>
) {
  const recordKeys = useRecordKeys();

  const frameRequests = makeFrameRequests(
    topicIds,
    recordCountQueries,
    debugTime,
    priorRecordsCountQueries,
    recordKeys
  );

  const initialFrameQueries = useQueries({
    queries: frameRequests.requiredRequests,
  });

  // Don't actually care about their results, just need them to be fetched
  // and observed by react-query
  useQueries({
    queries: frameRequests.preloadRequests,
  });

  return makeFrameQueriesResults(frameRequests.summaries, initialFrameQueries);
}

export function useBotMarkers(
  routerLog: MinibotLog | null,
  viewerRef: React.MutableRefObject<any>,
  botName: Maybe<string>
): BotMarkersQueryResult {
  const playbackSource = usePlaybackSource();

  const topicId = findRouterLogTopicId(routerLog, ROUTER_PLAYBACK_TOPIC);

  const recordWindowQuery = useRecordWindow({
    topicId,
    windowSizeMs: secondsToMilliseconds(30),
    bufferAheadMs: minutesToMilliseconds(1),
    chunkSizeMs: secondsToMilliseconds(30),
  });

  let mostRecentRecord: PlayerRecord | undefined = undefined;
  let staleTimeMs: number | undefined = undefined;
  if (!playbackSource.isLoading && recordWindowQuery.status === "success") {
    mostRecentRecord = findLast(
      recordWindowQuery.data,
      (record) => record.timestampMs <= playbackSource.timestampMs
    );

    if (mostRecentRecord !== undefined) {
      const msSinceLastMessage =
        playbackSource.timestampMs - mostRecentRecord.timestampMs;

      if (msSinceLastMessage > 2_000) {
        staleTimeMs = msSinceLastMessage;
      }
    }
  }

  useEffect(
    function renderMarkers() {
      if (viewerRef.current == null) {
        return;
      }

      renderBotMarkers(viewerRef, mostRecentRecord, botName);
    },
    [viewerRef, mostRecentRecord, botName]
  );

  if (topicId === null) {
    return { status: "absent" };
  } else if (
    recordWindowQuery.status === "loading" ||
    recordWindowQuery.status === "idle"
  ) {
    return { status: "loading" };
  } else if (recordWindowQuery.status === "error") {
    return { status: "error" };
  } else if (staleTimeMs !== undefined) {
    return { status: "stale", staleTimeMs };
  } else {
    return { status: "success" };
  }
}
