import { usePreviewImages } from "../../../queries";
import usePlayerConfig from "./usePlayerConfig";

const NUM_THUMBNAILS = 3;

export default function useLogThumbnails() {
  const { logId } = usePlayerConfig();

  return usePreviewImages(logId, NUM_THUMBNAILS);
}
