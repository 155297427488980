import { Amplify } from "aws-amplify";
import invariant from "invariant";
import { z } from "zod";

function getEnvVar(varName: string, strict?: false): string | undefined;
function getEnvVar(varName: string, strict: true): string;
function getEnvVar(
  varName: string,
  strict: boolean = false
): string | undefined {
  const name = `REACT_APP_${varName}`;
  const value = process.env[name];

  invariant(
    !strict || value !== undefined,
    `Env var with name "${name}" was expected but not found in environment`
  );

  return value;
}

const configurationSchema = z.object({
  staticApiEndpoint: z
    .string()
    .url()
    .refine((value) => new URL(value).protocol === "https:"),
  mapName: z.string(),
  mapIdentityPoolId: z.string(),
  userPoolRegion: z.string(),
  userPoolId: z.string(),
  userPoolWebClientId: z.string(),
  oAuthDomain: z.string(),
  oAuthScopes: z.string().array(),
});
export type Configuration = z.infer<typeof configurationSchema>;

const CONFIG_JSON_URL = `${process.env.PUBLIC_URL}/config.json`;

async function fetchRemoteConfig() {
  const response = await fetch(CONFIG_JSON_URL);

  if (!response.ok) {
    console.error(
      "Unable to load required application config",
      response,
      "Is config.json available?"
    );

    throw new Error(
      "Error fetching application configuration. See console for details"
    );
  }

  return response.json();
}

async function generateRemoteConfig(): Promise<Configuration> {
  const remoteConfig = await fetchRemoteConfig();

  try {
    return configurationSchema.parse(remoteConfig);
  } catch {
    console.error(
      "Remote configuration doesn't match expected shape",
      remoteConfig
    );

    throw new Error("Invalid remote configuration. See console for details");
  }
}

function generateStaticConfig(): Configuration {
  const STATIC_API_ENDPOINT = getEnvVar("STATIC_API_ENDPOINT", true);
  const MAP_NAME = getEnvVar("MAP_NAME", true);
  const MAP_IDENTITY_POOL_ID = getEnvVar("MAP_IDENTITY_POOL_ID", true);
  const USER_POOL_REGION = getEnvVar("USER_POOL_REGION", true);
  const USER_POOL_ID = getEnvVar("USER_POOL_ID", true);
  const USER_POOL_WEB_CLIENT_ID = getEnvVar("USER_POOL_WEB_CLIENT_ID", true);
  const OAUTH_DOMAIN = getEnvVar("OAUTH_DOMAIN", true);
  const OAUTH_SCOPES = getEnvVar("OAUTH_SCOPES", true).split(",");

  return {
    staticApiEndpoint: STATIC_API_ENDPOINT,
    mapName: MAP_NAME,
    mapIdentityPoolId: MAP_IDENTITY_POOL_ID,
    userPoolRegion: USER_POOL_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    oAuthDomain: OAUTH_DOMAIN,
    oAuthScopes: OAUTH_SCOPES,
  };
}

let _config: Configuration | undefined = undefined;

export async function initAppConfig(): Promise<void> {
  _config = await (getEnvVar("USE_DYNAMIC_CONFIGURATION") === "true"
    ? generateRemoteConfig()
    : generateStaticConfig());
}

export function getAppConfig(): Configuration {
  invariant(_config !== undefined, "Configuration not initialized yet");

  return _config;
}

function getMapIdentityPoolRegion() {
  const [mapIdentityPoolRegion] = getAppConfig().mapIdentityPoolId.split(":");

  return mapIdentityPoolRegion;
}

export function configureAuth(): void {
  const config = getAppConfig();

  const mapIdentityPoolRegion = getMapIdentityPoolRegion();

  Amplify.configure({
    Auth: {
      identityPoolRegion: mapIdentityPoolRegion,
      identityPoolId: config.mapIdentityPoolId,
      region: config.userPoolRegion,
      userPoolId: config.userPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
      oauth: {
        domain: config.oAuthDomain,
        scope: config.oAuthScopes,
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: "code",
      },
    },
  });
}

export function configureGeo(): void {
  const config = getAppConfig();

  const mapIdentityPoolRegion = getMapIdentityPoolRegion();

  Amplify.configure({
    geo: {
      AmazonLocationService: {
        maps: {
          items: {
            [config.mapName]: {
              style: "RasterEsriImagery",
            },
          },
          default: config.mapName,
        },
        region: mapIdentityPoolRegion,
      },
    },
  });
}

export const COGNITO_GROUPS_PROPERTY_NAME = "cognito:groups";
