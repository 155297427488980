export function calculateWindowTicks(
  activeRangeMs: [number, number],
  intervalMs: number
): number[] {
  // Floor to a multiple of the interval size
  let firstTickMs = Math.floor(activeRangeMs[0] / intervalMs) * intervalMs;
  if (firstTickMs < activeRangeMs[0]) {
    firstTickMs += intervalMs;
  }

  const ticks = [firstTickMs];
  for (let tick = firstTickMs; tick < activeRangeMs[1]; tick += intervalMs) {
    ticks.push(tick);
  }

  return ticks;
}
