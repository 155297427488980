import { Typography } from "@mui/material";
import { findKey, last, trimEnd } from "lodash";
import type { ValueOf } from "ts-essentials";

const LogLevel = {
  Trace: 0,
  Debug: 1,
  Info: 2,
  Warn: 3,
  Error: 4,
  Critical: 5,
  Off: 6,
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
type LogLevel = ValueOf<typeof LogLevel>;

const levelColorMap: Map<LogLevel, string> = new Map([
  [LogLevel.Error, "red"],
  [LogLevel.Warn, "yellow"],
  [LogLevel.Info, "green"],
]);

export interface SpdlogMessageProps {
  message: any;
}

export default function SpdlogMessage({ message }: SpdlogMessageProps) {
  const { level, time, source_loc_filename, source_loc_line, payload } =
    message;

  const prefixColor = levelColorMap.get(level);

  const messageDate = convertNanosecondsToDate(time);
  const formattedMessageDate = trimEnd(messageDate.toISOString(), "Z");

  const levelText =
    findKey(LogLevel, (enumLevel) => enumLevel === level)?.toLowerCase() ?? "";

  const fileName = last(source_loc_filename.split("/")) ?? "";

  return (
    <Typography
      variant="body2"
      sx={{ fontFamily: "monospace", wordBreak: "break-all", lineHeight: 1 }}
    >
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: prefixColor,
          fontFamily: "inherit",
          lineHeight: "inherit",
        }}
      >
        [{formattedMessageDate} {levelText}|{fileName}:{source_loc_line}]
      </Typography>{" "}
      {payload}
    </Typography>
  );
}

function convertNanosecondsToDate(
  time: number | { secs: number; nsecs: number }
): Date {
  if (typeof time === "number") {
    // When time is just a number, it's measured in nanoseconds
    return new Date(time / 1e6);
  } else {
    const flattenedTimeMs = time.secs * 1_000 + time.nsecs / 1e6;

    return new Date(flattenedTimeMs);
  }
}
