/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 */
export const ClientMethod = {
  AbortMultipartUpload: "abort_multipart_upload",
  CompleteMultipartUpload: "complete_multipart_upload",
  CreateMultipartUpload: "create_multipart_upload",
  DeleteObject: "delete_object",
  GetObject: "get_object",
  HeadObject: "head_object",
  ListMultipartUploads: "list_multipart_uploads",
  ListObjectVersions: "list_object_versions",
  ListObjectsV2: "list_objects_v2",
  ListParts: "list_parts",
  PutObject: "put_object",
  RestoreObject: "restore_object",
  UploadPart: "upload_part",
} as const;
export type ClientMethod = typeof ClientMethod[keyof typeof ClientMethod];

export function ClientMethodFromJSON(json: any): ClientMethod {
  return ClientMethodFromJSONTyped(json, false);
}

export function ClientMethodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClientMethod {
  return json as ClientMethod;
}

export function ClientMethodToJSON(value?: ClientMethod | null): any {
  return value as any;
}
