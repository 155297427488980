import { Box, Stack } from "@mui/material";
import Center from "../../../../../components/Center";
import Loading from "../../../../../components/Loading";
import TopicSection from "../../../components/TopicSection";
import { LOG_VIEW_PANEL_SIZES_STORAGE_KEY } from "../constants";
import { useBotRouterLog } from "../hooks";
import type { MinibotLog } from "../types";
import { getBotName } from "../utils";
import BotViewer from "./BotViewer";
import EventTimeline from "./EventTimeline";
import ResizeablePanels from "./ResizeablePanels";

export interface BotLogProps {
  log: MinibotLog;
}

export default function BotLog({ log }: BotLogProps) {
  const routerLogQuery = useBotRouterLog(log.id);

  if (routerLogQuery.isLoading) {
    return <Loading type="circular" />;
  }

  // Treating query error as if there were no router log
  const effectiveRouterLog = routerLogQuery.data ?? null;

  const botName = getBotName(log.name);

  return (
    <Stack sx={{ flexGrow: 1, minHeight: 0 }}>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <ResizeablePanels
          storageKey={LOG_VIEW_PANEL_SIZES_STORAGE_KEY}
          splitOrientation="vertical"
          left={<BotViewer routerLog={effectiveRouterLog} botName={botName} />}
          right={
            <Box sx={{ height: 1, width: 1, display: "flex" }}>
              <TopicSection />
            </Box>
          }
        />
      </Box>
      <Box sx={{ height: 35 }}>
        <Center>
          <EventTimeline logSummary={log.summary} />
        </Center>
      </Box>
    </Stack>
  );
}
