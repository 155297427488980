import { Close } from "@mui/icons-material";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useLayoutStateContext } from "./Layout";

export interface DrawerHeaderProps {
  title: string;
}

export default function DrawerHeader({ title }: DrawerHeaderProps) {
  const { setSideSheetState } = useLayoutStateContext();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="p">
          {title}
        </Typography>
        <Tooltip title="Close">
          <IconButton onClick={() => setSideSheetState(null)}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider sx={{ my: 2 }} />
    </>
  );
}
