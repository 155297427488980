import type { QueryFilters, UseQueryOptions } from "@tanstack/react-query";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import invariant from "invariant";
import { getClients } from "../../../domain/datastores";
import type { RecordListResponse, Topic } from "../../../services/datastore";
import type { Maybe } from "../../../types";
import type { UseRecordQueriesRequest } from "../queries";
import { isListRecordsRequest } from "../queries";
import { findTimestampOffset } from "./utils";

export interface TimestampOffsetRequest {
  topicId: Maybe<Topic["id"]>;
  filters: QueryFilters;
}

export interface UseTimestampOffsetsParams<TData = number> {
  requests: ReadonlyArray<TimestampOffsetRequest>;
  // This should be in seconds
  timestamp: Maybe<number>;
  select?: (recordOffset: number) => TData;
}

const baseQueryKey = ["playback-offset"];

// Lower-level hook for when offsets are needed for multiple topics
export function useTimestampOffsets<TData = number>({
  requests,
  timestamp,
  select,
}: UseTimestampOffsetsParams<TData>) {
  const queryClient = useQueryClient();

  return useQueries({
    queries: requests.map(
      ({ topicId, filters }): UseQueryOptions<number, unknown, TData> => {
        const request: UseRecordQueriesRequest = {
          topicId,
          limit: 0,
          sort: "asc",
          order: "timestamp",
          timestampLte: timestamp ?? undefined,
        };

        const enabled =
          isListRecordsRequest(request) && request.timestampLte !== undefined;

        return {
          queryKey: [...baseQueryKey, request],
          async queryFn(context) {
            invariant(enabled, "Query shouldn't be running");

            const { topicApi } = getClients();

            const response = await topicApi.listRecords(request, context);

            return response.count;
          },
          cacheTime: 0,
          initialData() {
            const { timestampLte } = request;
            if (timestampLte === undefined) {
              return undefined;
            }

            const currentRecordQueries =
              queryClient.getQueriesData<RecordListResponse>(filters);

            return findTimestampOffset(
              timestampLte,
              currentRecordQueries.map((result) => result[1])
            );
          },
          enabled,
          select,
        };
      }
    ),
  });
}

export interface UseTimestampOffsetParams<TData = number>
  extends Pick<UseTimestampOffsetsParams<TData>, "timestamp" | "select"> {
  topicId: Maybe<Topic["id"]>;
  filters: QueryFilters;
}

// Default export for normal usage when offset only needed for one topic
export default function useTimestampOffset<TData = number>({
  topicId,
  timestamp,
  filters,
  select,
}: UseTimestampOffsetParams<TData>) {
  const [query] = useTimestampOffsets({
    requests: [{ topicId, filters }],
    timestamp,
    select,
  });

  return query;
}
