/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  UserCreateRequest,
  UserCreateRequestFromJSON,
  UserCreateRequestToJSON,
  UserFetchResponse,
  UserFetchResponseFromJSON,
  UserFetchResponseToJSON,
  UserListResponse,
  UserListResponseFromJSON,
  UserListResponseToJSON,
  UserUpdateRequest,
  UserUpdateRequestFromJSON,
  UserUpdateRequestToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateUserRequest {
  userCreateRequest: UserCreateRequest;
}

export interface DeleteUserRequest {
  userId: string;
}

export interface GetUserRequest {
  userId: string;
}

export interface ListUsersRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  username?: string;
  usernameLike?: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateUserRequest {
  userId: string;
  userUpdateRequest: UserUpdateRequest;
}

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   * Create User
   */
  async createUserRaw(
    this: UserApi,
    requestParameters: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserFetchResponse>> {
    if (
      requestParameters.userCreateRequest === null ||
      requestParameters.userCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "userCreateRequest",
        "Required parameter requestParameters.userCreateRequest was null or undefined when calling createUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/users`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserCreateRequestToJSON(requestParameters.userCreateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create User
   */
  async createUser(
    this: UserApi,
    requestParameters: CreateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserFetchResponse> {
    const response = await this.createUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete User
   */
  async deleteUserRaw(
    this: UserApi,
    requestParameters: DeleteUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling deleteUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete User
   */
  async deleteUser(
    this: UserApi,
    requestParameters: DeleteUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteUserRaw(requestParameters, initOverrides);
  }

  /**
   * Get User
   */
  async getUserRaw(
    this: UserApi,
    requestParameters: GetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserFetchResponse>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling getUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get User
   */
  async getUser(
    this: UserApi,
    requestParameters: GetUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserFetchResponse> {
    const response = await this.getUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * List Users
   */
  async listUsersRaw(
    this: UserApi,
    requestParameters: ListUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.username !== undefined) {
      queryParameters["username"] = requestParameters.username;
    }

    if (requestParameters.usernameLike !== undefined) {
      queryParameters["username_like"] = requestParameters.usernameLike;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Users
   */
  async listUsers(
    this: UserApi,
    requestParameters: ListUsersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserListResponse> {
    const response = await this.listUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update User
   */
  async updateUserRaw(
    this: UserApi,
    requestParameters: UpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserFetchResponse>> {
    if (
      requestParameters.userId === null ||
      requestParameters.userId === undefined
    ) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling updateUser."
      );
    }

    if (
      requestParameters.userUpdateRequest === null ||
      requestParameters.userUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "userUpdateRequest",
        "Required parameter requestParameters.userUpdateRequest was null or undefined when calling updateUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/users/{user_id}`.replace(
          `{${"user_id"}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdateRequestToJSON(requestParameters.userUpdateRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update User
   */
  async updateUser(
    this: UserApi,
    requestParameters: UpdateUserRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserFetchResponse> {
    const response = await this.updateUserRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
