import React, { useEffect } from "react";
import { useState } from "react";
import { secondsToMilliseconds } from "date-fns";
import { find } from "lodash";
import { createSafeContext } from "../../contexts";
import type { Topic } from "../../services/datastore";
import { usePlayerTopics } from "./hooks";
import { iteratePanels, usePanelLayoutContext } from "./panels";
import { RecordListCache } from "./recordListCache";

const EVICTION_TIMEOUT_MS = secondsToMilliseconds(20);

export const [useRecordListCache, RecordListCacheContext] =
  createSafeContext<RecordListCache>("RecordListCache");

export interface RecordListCacheProviderProps {
  children: React.ReactNode;
}

export function RecordListCacheProvider({
  children,
}: RecordListCacheProviderProps) {
  const [recordListCache] = useState(
    () => new RecordListCache(EVICTION_TIMEOUT_MS)
  );

  const playerTopicsQuery = usePlayerTopics();
  const { layout } = usePanelLayoutContext();
  useEffect(
    function preserveCurrentTopicsInCache() {
      if (playerTopicsQuery.data === undefined) {
        return;
      }

      const currentTopicIds = new Set<Topic["id"]>();
      iteratePanels(layout, (panel) => {
        if (!panel.isInitialized) {
          return;
        }

        const topic = find(playerTopicsQuery.data, { name: panel.topicName });
        if (topic !== undefined) {
          currentTopicIds.add(topic.id);
        }
      });

      recordListCache.preserveTopics(Array.from(currentTopicIds));
    },
    [playerTopicsQuery.data, layout, recordListCache]
  );

  return (
    <RecordListCacheContext.Provider value={recordListCache}>
      {children}
    </RecordListCacheContext.Provider>
  );
}
