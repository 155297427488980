import React from "react";
import { styled } from "@mui/material";
import Layout from "./Layout";

export interface LayoutProps {
  header: React.ReactNode;
  globalNavigation: React.ReactNode;
  sideSheet: React.ReactNode;
  content: React.ReactNode;
  controls: React.ReactNode;
}

const ContentArea = styled("div")(() => ({
  flexGrow: 1,
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
}));

const Controls = styled("div")({ flexShrink: 0 });

export default function AdvancedLayout({
  header,
  globalNavigation,
  sideSheet,
  content,
  controls,
}: LayoutProps) {
  return (
    <Layout
      globalNavigation={globalNavigation}
      header={header}
      sideSheet={sideSheet}
    >
      <ContentArea>
        {content}
        <Controls>{controls}</Controls>
      </ContentArea>
    </Layout>
  );
}
