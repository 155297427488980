export const STL_MODELS_PATH = "/models";

export const LOW_POLY_STL_FILE = "minibot_m2_low_poly.stl";

export const MARKER_TOPICS_STORAGE_KEY = "static-markers";

export const DEBUG_MODE_STORAGE_KEY = "in-debug-mode";

export const DEBUG_TIME_STORAGE_KEY = "debug-time";

export const LOG_VIEW_PANEL_SIZES_STORAGE_KEY = "log-view-panel-sizes";

export const MARKER_ARRAY_MESSAGE_TYPE = "visualization_msgs/MarkerArray";

export const LANE_BOUNDARIES_MARKERS_TOPIC = "rviz/lane_boundaries_markers";

export const ROUTER_PLAYBACK_TOPIC = "router/playback";

export const KNOWN_STATIC_TOPICS = [LANE_BOUNDARIES_MARKERS_TOPIC];

export const BOT_LOGS_FETCH_LIMIT = 25;
