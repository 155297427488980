import React from "react";
import { Box } from "@mui/material";

export type CenterProps = React.PropsWithChildren<{}>;

export default function Center({ children }: CenterProps) {
  return (
    <Box
      height={1}
      width={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
}
