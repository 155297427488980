import React from "react";
import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  TextField,
  Typography,
} from "@mui/material";
import type { Topic } from "../../../services/datastore";

export interface BaseTopicSelectProps {
  id?: string;
  inputLabel: string;
  topics: Topic[];
  disabled?: boolean;
}

export interface SingleTopicSelectProps extends BaseTopicSelectProps {
  multiple?: false;
  value: Topic | null;
  onChange: (selection: Topic | null) => void;
}

export interface MultiTopicSelectProps extends BaseTopicSelectProps {
  multiple: true;
  value: Topic[];
  onChange: (selection: Topic[]) => void;
}

export type TopicSelectProps = SingleTopicSelectProps | MultiTopicSelectProps;

export default function TopicSelect({
  id,
  inputLabel,
  topics,
  disabled,
  multiple,
  value,
  onChange,
}: TopicSelectProps) {
  return (
    <Autocomplete
      fullWidth
      autoComplete
      autoHighlight
      disabled={disabled}
      id={id}
      options={topics}
      multiple={multiple}
      limitTags={1}
      value={value}
      onChange={(_, newSelection) => onChange(newSelection as any)}
      filterOptions={createFilterOptions<Topic>({
        stringify: (topic) => `${topic.name} ${topic.messageTypeName ?? ""}`,
      })}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
      renderTags={(value, getTagProps) =>
        value.map((topic, index) => (
          <Chip
            {...getTagProps({ index })}
            label={topic.name}
            title={topic.name}
          />
        ))
      }
      getOptionLabel={({ name }) => name}
      renderOption={(props, topic) => (
        <li {...props}>
          <Box maxWidth={1}>
            <Typography noWrap title={topic.name}>
              {topic.name}
            </Typography>
            <Typography noWrap variant="body2" color="textSecondary">
              {topic.messageTypeName}
            </Typography>
          </Box>
        </li>
      )}
    />
  );
}
