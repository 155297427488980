import React, { useEffect, useRef } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { fromUnixTime } from "date-fns";
import {
  Dl,
  renderDlGroup,
} from "../../../../../../../components/DescriptionList";
import DrawerHeader from "../../../../../../../components/DrawerHeader";
import Error from "../../../../../../../components/Error";
import GroupSelect from "../../../../../../../components/GroupSelect";
import Time from "../../../../../../../components/Time";
import {
  useDebouncedValue,
  useSessionStorage,
} from "../../../../../../../hooks";
import { selectData, useGroup, useLogs } from "../../../../../../../queries";
import { usePlayerConfig, usePlayerLog } from "../../../../../hooks";
import LogListItem from "./LogListItem";
import {
  renderGroupName,
  renderLogDuration,
  renderLogIngestionStatusChip,
} from "./utils";

type RefetchReason = "input" | "previous" | "next";

const limit = 10;

const ROUTER_LOG_PREFIX = "router_";

const SEARCH_QUERY_STORAGE_KEY = "log-drawer-search-query";
const SEARCH_OFFSET_STORAGE_KEY = "log-drawer-search-offset";
const GROUP_ID_STORAGE_KEY = "log-drawer-group-id";

export default function LogDrawer() {
  const { logId, setLogId } = usePlayerConfig();
  const currentLogQuery = usePlayerLog();

  const selectedLogGroupQuery = useGroup(currentLogQuery.data?.groupId, {
    select: selectData,
  });

  const refetchReasonRef = useRef<RefetchReason>();
  const searchHeaderRef = useRef<HTMLParagraphElement | null>(null);

  const [search, setSearch] = useSessionStorage(SEARCH_QUERY_STORAGE_KEY, "");
  const [offset, setOffset] = useSessionStorage(SEARCH_OFFSET_STORAGE_KEY, 0);
  const [groupId, setGroupId] = useSessionStorage(GROUP_ID_STORAGE_KEY, "");

  const debouncedSearch = useDebouncedValue(search, 200);
  const searchQuery = useLogs(
    {
      nameLike: `${ROUTER_LOG_PREFIX}%${debouncedSearch}`,
      offset,
      limit,
      groupId: groupId || undefined,
      sort: "desc",
      order: "start_time",
      startTimeNull: false,
      endTimeNull: false,
    },
    {
      keepPreviousData: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );

  useEffect(
    function scrollToTopOfSearchOnPageChange() {
      if (
        searchQuery.data !== undefined &&
        (refetchReasonRef.current === "previous" ||
          refetchReasonRef.current === "next")
      ) {
        searchHeaderRef.current?.scrollIntoView(true);
      }
    },
    [searchQuery.data]
  );

  const isPaginationDisabled =
    !searchQuery.isSuccess || searchQuery.data.count === 0;

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    refetchReasonRef.current = "input";

    setSearch(e.target.value);
  }

  function makePaginationHandler(direction: "previous" | "next") {
    return function handlePaginationChange() {
      refetchReasonRef.current = direction;

      const change = direction === "previous" ? -limit : limit;
      setOffset(offset + change);
    };
  }

  function causedRefetch(reason: RefetchReason) {
    return searchQuery.isFetching && refetchReasonRef.current === reason;
  }

  return (
    <>
      <DrawerHeader title="Logs" />
      <Stack spacing={2}>
        <div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="p">
              Current Log
            </Typography>
          </Stack>
          {logId == null ? (
            <Typography paragraph>
              No log selected. Search for one below
            </Typography>
          ) : currentLogQuery.isSuccess ? (
            <Dl
              spacing={3}
              sx={{
                mt: 0,
                "& .MuiGrid-root:first-child": {
                  pt: 0,
                },
              }}
            >
              {renderDlGroup("Name", currentLogQuery.data.name, { xs: 12 })}
              {renderDlGroup(
                "Group",
                renderGroupName(selectedLogGroupQuery, "--"),
                { xs: 12 }
              )}
              {renderDlGroup(
                "Start Time",
                currentLogQuery.data.startTime === null ? (
                  "--"
                ) : (
                  <Time date={fromUnixTime(currentLogQuery.data.startTime)} />
                ),
                { xs: 12 }
              )}
              {renderDlGroup(
                "Duration",
                currentLogQuery.data.startTimeMs === null ||
                  currentLogQuery.data.endTimeMs === null
                  ? "--"
                  : renderLogDuration(currentLogQuery.data),
                { xs: 12 }
              )}
              {renderDlGroup(
                "Ingestion Status",
                renderLogIngestionStatusChip(currentLogQuery.data)
              )}
            </Dl>
          ) : currentLogQuery.isError ? (
            <Typography paragraph>
              An error occurred trying to load the log.
            </Typography>
          ) : (
            <Typography paragraph>Loading the log...</Typography>
          )}
        </div>
        <Divider />
        <div>
          <Typography
            ref={searchHeaderRef}
            variant="h6"
            component="p"
            paragraph
          >
            Search Router Logs
          </Typography>
          <GroupSelect
            label="Site"
            value={groupId}
            onChange={setGroupId}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Search router logs"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {ROUTER_LOG_PREFIX}
                </InputAdornment>
              ),
            }}
          />
          <LinearProgress
            sx={{
              mt: 2,
              visibility: searchQuery.isFetching ? "visible" : "hidden",
            }}
          />
          {searchQuery.isError ? (
            <Box my={3}>
              <Error>
                <Typography>An error occurred searching for logs</Typography>
              </Error>
            </Box>
          ) : (
            <>
              <List>
                {searchQuery.isSuccess && searchQuery.data.count === 0 ? (
                  <ListItem>No logs matched the search</ListItem>
                ) : searchQuery.isSuccess ? (
                  searchQuery.data.data.map((log) => (
                    <LogListItem
                      key={log.id}
                      log={log}
                      selectedLogId={logId}
                      setSelectedLogId={setLogId}
                    />
                  ))
                ) : (
                  <ListItem>Fetching logs...</ListItem>
                )}
              </List>
              <Stack direction="row" justifyContent="space-between">
                <LoadingButton
                  loading={causedRefetch("previous")}
                  startIcon={<ChevronLeft />}
                  disabled={isPaginationDisabled || offset === 0}
                  onClick={makePaginationHandler("previous")}
                >
                  Previous
                </LoadingButton>
                <LoadingButton
                  loading={causedRefetch("next")}
                  endIcon={<ChevronRight />}
                  disabled={
                    isPaginationDisabled ||
                    offset + limit >= searchQuery.data.count
                  }
                  onClick={makePaginationHandler("next")}
                >
                  Next
                </LoadingButton>
              </Stack>
            </>
          )}
        </div>
      </Stack>
    </>
  );
}
