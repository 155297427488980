import React, { useState } from "react";
import { FilterAlt } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import invariant from "invariant";
import { Trackpad, TrackpadLock } from "mdi-material-ui";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import Error from "../../../../../components/Error";
import type { Topic } from "../../../../../services/datastore";
import type { Maybe } from "../../../../../types";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSource,
} from "../../../PlaybackProvider";
import { useUpdatePanelBuffering } from "../../../hooks";
import type { InitializedPanelNode } from "../../../panels";
import { seek } from "../../../playbackReducer";
import JsonTree from "../../JsonTree";
import PanelHeader from "../../PanelHeader";
import PanelLayout from "../../PanelLayout";
import TerminalList from "./TerminalList";
import type { TerminalRecords } from "./types";
import useTerminalRecords from "./useTerminalRecords";

const Root = styled("div", {
  shouldForwardProp: (propName) =>
    !["hasSelectedRow", "sx"].includes(propName as any),
})<{ hasSelectedRow?: boolean }>(({ hasSelectedRow }) => ({
  display: "grid",
  gridTemplateAreas: `
    "filter filter"
    "list   json"
  `,
  gridTemplateColumns: hasSelectedRow
    ? "repeat(2, minmax(0, 1fr))"
    : "minmax(0, 1fr) 0",
  gridTemplateRows: "auto minmax(0, 1fr)",
}));

const FilterArea = styled("form")({
  gridArea: "filter",
});

const ListArea = styled("div")({
  gridArea: "list",
  position: "relative",
  "& .MuiList-root": {
    margin: 0,
    padding: 0,
  },
  "& .MuiListItem-root": {
    margin: 0,
    padding: 0,
  },
  "& .MuiListSubheader-root": {
    margin: 0,
    padding: 0,
  },
});

const JsonArea = styled("div")({
  gridArea: "json",
  display: "flex",
  flexDirection: "column",
});

const JsonHeaderArea = styled("div")({
  flex: "none",
});

const JsonTreeArea = styled("div")({
  flex: "1",
  minHeight: 0,
  overflowY: "auto",
});

interface FilterFormValues {
  filter: string;
}

export interface TerminalVisualizationProps {
  panel: InitializedPanelNode;
  topic: Topic;
}

export default function TerminalVisualization({
  panel,
  topic,
}: TerminalVisualizationProps) {
  const isSpdlogTopic = topic.messageTypeName === "minibot_msgs/SpdlogLogMsg";

  const terminalRecords = useTerminalRecords(topic, panel);

  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const filterForm = useForm<FilterFormValues>({
    defaultValues: { filter: terminalRecords.filter },
  });

  const onSubmit: SubmitHandler<FilterFormValues> = function onSubmit(values) {
    terminalRecords.setFilter(values.filter);
  };

  function seekToSelectedRowTime() {
    invariant(terminalRecords.selectedRow !== null, "Selected row is null");

    playbackSource.dispatch(seek(terminalRecords.selectedRow.timestampMs));
  }

  return (
    <PanelLayout
      header={
        <PanelHeader
          actions={
            <Tooltip
              title={
                panel.terminalAutoScroll
                  ? "Disable auto-scroll"
                  : "Enable auto-scroll"
              }
            >
              <IconButton
                size="small"
                onClick={terminalRecords.handleAutoScrollToggle}
              >
                {panel.terminalAutoScroll ? <TrackpadLock /> : <Trackpad />}
              </IconButton>
            </Tooltip>
          }
        />
      }
    >
      <Root
        hasSelectedRow={terminalRecords.selectedRow !== null}
        sx={{ height: 1 }}
      >
        <FilterArea
          sx={{ p: isSpdlogTopic ? 0 : 2 }}
          onSubmit={filterForm.handleSubmit(onSubmit)}
        >
          {!isSpdlogTopic && (
            <TextField
              fullWidth
              label="Filter field"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <Tooltip title="Apply filter">
                        <FilterAlt />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...filterForm.register("filter")}
            />
          )}
        </FilterArea>
        <ListArea sx={{ overflowY: "auto", overflowWrap: "anywhere" }}>
          <ListWrapper
            panel={panel}
            terminalRecordsHookResult={terminalRecords}
          />
        </ListArea>
        <JsonArea>
          {terminalRecords.selectedRow !== null && (
            <>
              <JsonHeaderArea
                sx={{ p: 2, display: "flex", alignItems: "center" }}
              >
                <Typography color="text.secondary">
                  {formatPlaybackTimestamp(
                    terminalRecords.selectedRow.timestampMs
                  )}
                </Typography>
                <Button
                  sx={{ ml: "auto" }}
                  variant="text"
                  color="primary"
                  onClick={seekToSelectedRowTime}
                >
                  Seek To{" "}
                  {formatPlaybackTimestamp(
                    terminalRecords.selectedRow.timestampMs
                  )}
                </Button>
              </JsonHeaderArea>
              <JsonTreeArea sx={{ p: 2, overflowWrap: "anywhere" }}>
                <JsonTree
                  src={terminalRecords.selectedRow.payload}
                  onSelect={() => {}}
                />
              </JsonTreeArea>
            </>
          )}
        </JsonArea>
      </Root>
    </PanelLayout>
  );
}

interface ListWrapperProps {
  panel: InitializedPanelNode;
  terminalRecordsHookResult: ReturnType<typeof useTerminalRecords>;
}

function ListWrapper({ panel, terminalRecordsHookResult }: ListWrapperProps) {
  const [lastSuccessful, setLastSuccessful] = useState<{
    timestampMs: number;
    data: TerminalRecords;
  }>();

  const playbackSource = usePlaybackSource();

  useUpdatePanelBuffering(terminalRecordsHookResult.query.status === "loading");

  if (
    !playbackSource.isLoading &&
    terminalRecordsHookResult.query.status === "success" &&
    // It's crucial to only perform the conditional state update when the
    // timestamps don't match, otherwise a single successful query would result
    // in an infinite render loop. It can be assumed that anytime the query
    // is successful, it's successful *for the current playback time*
    lastSuccessful?.timestampMs !== playbackSource.timestampMs
  ) {
    // A new strategy I'm trying for showing previous data when queries
    // are loading. Setting state during render is allowed though uncommon.
    // Essentially, every time this component encounters a successful query
    // result it'll store it so in the future it'll have something to continue
    // showing under the loading backdrop.
    setLastSuccessful({
      timestampMs: playbackSource.timestampMs,
      data: terminalRecordsHookResult.query.data,
    });

    return null;
  }

  if (terminalRecordsHookResult.query.status === "error") {
    return (
      <Error>
        <Typography variant="h5" component="p">
          Error fetching terminal rows
        </Typography>
      </Error>
    );
  }

  let effectiveTimestampMs: Maybe<number>;
  let effectiveData: Maybe<TerminalRecords>;
  if (
    !playbackSource.isLoading &&
    terminalRecordsHookResult.query.status === "success"
  ) {
    effectiveTimestampMs = playbackSource.timestampMs;
    effectiveData = terminalRecordsHookResult.query.data;
  } else {
    effectiveTimestampMs = lastSuccessful?.timestampMs;
    effectiveData = lastSuccessful?.data;
  }

  return (
    <>
      {!playbackSource.isLoading &&
        effectiveTimestampMs != null &&
        effectiveData != null && (
          <TerminalList
            panel={panel}
            timestampMs={effectiveTimestampMs}
            globalOffsetIndex={effectiveData.offset}
            records={effectiveData.records}
            selectedRowId={terminalRecordsHookResult.selectedRow?.id}
            onRowClick={terminalRecordsHookResult.handleRowSelect}
          />
        )}
      <Backdrop
        sx={{ position: "absolute" }}
        open={terminalRecordsHookResult.query.status === "loading"}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
