/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CreatePresignedURLRequest,
  CreatePresignedURLRequestFromJSON,
  CreatePresignedURLRequestToJSON,
  CreatePresignedURLResponse,
  CreatePresignedURLResponseFromJSON,
  CreatePresignedURLResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  IngestionCreateRequest,
  IngestionCreateRequestFromJSON,
  IngestionCreateRequestToJSON,
  IngestionFetchResponse,
  IngestionFetchResponseFromJSON,
  IngestionFetchResponseToJSON,
  IngestionListResponse,
  IngestionListResponseFromJSON,
  IngestionListResponseToJSON,
  IngestionUpdateRequest,
  IngestionUpdateRequestFromJSON,
  IngestionUpdateRequestToJSON,
  LogFormat,
  LogFormatFromJSON,
  LogFormatToJSON,
  ProcessStatus,
  ProcessStatusFromJSON,
  ProcessStatusToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateIngestionRequest {
  ingestionCreateRequest: IngestionCreateRequest;
}

export interface CreateIngestionPresignedUrlRequest {
  ingestionId: string;
  createPresignedURLRequest: CreatePresignedURLRequest;
}

export interface DeleteIngestionRequest {
  ingestionId: string;
}

export interface GetIngestionRequest {
  ingestionId: string;
}

export interface ListIngestionsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  logId?: string;
  groupId?: string;
  status?: ProcessStatus;
  processing?: boolean;
  queued?: boolean;
  cancelled?: boolean;
  errored?: boolean;
  archived?: boolean;
  completed?: boolean;
  format?: LogFormat;
  name?: string;
  nameLike?: string;
  s3Bucket?: string;
  s3Key?: string;
  s3KeyPrefix?: string;
  sizeNull?: boolean;
  sizeGte?: number;
  sizeLte?: number;
  progressNull?: boolean;
  progressGte?: number;
  progressLte?: number;
  metaLike?: string;
  errorLike?: string;
  noteLike?: string;
  contextFilter?: any;
  startOffsetNull?: boolean;
  startOffsetGte?: number;
  startOffsetLte?: number;
  endOffsetNull?: boolean;
  endOffsetGte?: number;
  endOffsetLte?: number;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateIngestionRequest {
  ingestionId: string;
  ingestionUpdateRequest: IngestionUpdateRequest;
}

/**
 *
 */
export class IngestionApi extends runtime.BaseAPI {
  /**
   * Required role: [editor, owner, admin]
   * Create Ingestion
   */
  async createIngestionRaw(
    this: IngestionApi,
    requestParameters: CreateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IngestionFetchResponse>> {
    if (
      requestParameters.ingestionCreateRequest === null ||
      requestParameters.ingestionCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionCreateRequest",
        "Required parameter requestParameters.ingestionCreateRequest was null or undefined when calling createIngestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionCreateRequestToJSON(
          requestParameters.ingestionCreateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IngestionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Required role: [editor, owner, admin]
   * Create Ingestion
   */
  async createIngestion(
    this: IngestionApi,
    requestParameters: CreateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IngestionFetchResponse> {
    const response = await this.createIngestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Ingestion Presigned Url
   */
  async createIngestionPresignedUrlRaw(
    this: IngestionApi,
    requestParameters: CreateIngestionPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreatePresignedURLResponse>> {
    if (
      requestParameters.ingestionId === null ||
      requestParameters.ingestionId === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionId",
        "Required parameter requestParameters.ingestionId was null or undefined when calling createIngestionPresignedUrl."
      );
    }

    if (
      requestParameters.createPresignedURLRequest === null ||
      requestParameters.createPresignedURLRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "createPresignedURLRequest",
        "Required parameter requestParameters.createPresignedURLRequest was null or undefined when calling createIngestionPresignedUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}/presignedUrls`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePresignedURLRequestToJSON(
          requestParameters.createPresignedURLRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePresignedURLResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Ingestion Presigned Url
   */
  async createIngestionPresignedUrl(
    this: IngestionApi,
    requestParameters: CreateIngestionPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreatePresignedURLResponse> {
    const response = await this.createIngestionPresignedUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Ingestion
   */
  async deleteIngestionRaw(
    this: IngestionApi,
    requestParameters: DeleteIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.ingestionId === null ||
      requestParameters.ingestionId === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionId",
        "Required parameter requestParameters.ingestionId was null or undefined when calling deleteIngestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Ingestion
   */
  async deleteIngestion(
    this: IngestionApi,
    requestParameters: DeleteIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteIngestionRaw(requestParameters, initOverrides);
  }

  /**
   * Get Ingestion
   */
  async getIngestionRaw(
    this: IngestionApi,
    requestParameters: GetIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IngestionFetchResponse>> {
    if (
      requestParameters.ingestionId === null ||
      requestParameters.ingestionId === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionId",
        "Required parameter requestParameters.ingestionId was null or undefined when calling getIngestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IngestionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Ingestion
   */
  async getIngestion(
    this: IngestionApi,
    requestParameters: GetIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IngestionFetchResponse> {
    const response = await this.getIngestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Ingestions
   */
  async listIngestionsRaw(
    this: IngestionApi,
    requestParameters: ListIngestionsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IngestionListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.logId !== undefined) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.processing !== undefined) {
      queryParameters["processing"] = requestParameters.processing;
    }

    if (requestParameters.queued !== undefined) {
      queryParameters["queued"] = requestParameters.queued;
    }

    if (requestParameters.cancelled !== undefined) {
      queryParameters["cancelled"] = requestParameters.cancelled;
    }

    if (requestParameters.errored !== undefined) {
      queryParameters["errored"] = requestParameters.errored;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    if (requestParameters.completed !== undefined) {
      queryParameters["completed"] = requestParameters.completed;
    }

    if (requestParameters.format !== undefined) {
      queryParameters["format"] = requestParameters.format;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.s3Bucket !== undefined) {
      queryParameters["s3_bucket"] = requestParameters.s3Bucket;
    }

    if (requestParameters.s3Key !== undefined) {
      queryParameters["s3_key"] = requestParameters.s3Key;
    }

    if (requestParameters.s3KeyPrefix !== undefined) {
      queryParameters["s3_key_prefix"] = requestParameters.s3KeyPrefix;
    }

    if (requestParameters.sizeNull !== undefined) {
      queryParameters["size_null"] = requestParameters.sizeNull;
    }

    if (requestParameters.sizeGte !== undefined) {
      queryParameters["size_gte"] = requestParameters.sizeGte;
    }

    if (requestParameters.sizeLte !== undefined) {
      queryParameters["size_lte"] = requestParameters.sizeLte;
    }

    if (requestParameters.progressNull !== undefined) {
      queryParameters["progress_null"] = requestParameters.progressNull;
    }

    if (requestParameters.progressGte !== undefined) {
      queryParameters["progress_gte"] = requestParameters.progressGte;
    }

    if (requestParameters.progressLte !== undefined) {
      queryParameters["progress_lte"] = requestParameters.progressLte;
    }

    if (requestParameters.metaLike !== undefined) {
      queryParameters["meta_like"] = requestParameters.metaLike;
    }

    if (requestParameters.errorLike !== undefined) {
      queryParameters["error_like"] = requestParameters.errorLike;
    }

    if (requestParameters.noteLike !== undefined) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.startOffsetNull !== undefined) {
      queryParameters["start_offset_null"] = requestParameters.startOffsetNull;
    }

    if (requestParameters.startOffsetGte !== undefined) {
      queryParameters["start_offset_gte"] = requestParameters.startOffsetGte;
    }

    if (requestParameters.startOffsetLte !== undefined) {
      queryParameters["start_offset_lte"] = requestParameters.startOffsetLte;
    }

    if (requestParameters.endOffsetNull !== undefined) {
      queryParameters["end_offset_null"] = requestParameters.endOffsetNull;
    }

    if (requestParameters.endOffsetGte !== undefined) {
      queryParameters["end_offset_gte"] = requestParameters.endOffsetGte;
    }

    if (requestParameters.endOffsetLte !== undefined) {
      queryParameters["end_offset_lte"] = requestParameters.endOffsetLte;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IngestionListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Ingestions
   */
  async listIngestions(
    this: IngestionApi,
    requestParameters: ListIngestionsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IngestionListResponse> {
    const response = await this.listIngestionsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Ingestion
   */
  async updateIngestionRaw(
    this: IngestionApi,
    requestParameters: UpdateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IngestionFetchResponse>> {
    if (
      requestParameters.ingestionId === null ||
      requestParameters.ingestionId === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionId",
        "Required parameter requestParameters.ingestionId was null or undefined when calling updateIngestion."
      );
    }

    if (
      requestParameters.ingestionUpdateRequest === null ||
      requestParameters.ingestionUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "ingestionUpdateRequest",
        "Required parameter requestParameters.ingestionUpdateRequest was null or undefined when calling updateIngestion."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/ingestions/{ingestion_id}`.replace(
          `{${"ingestion_id"}}`,
          encodeURIComponent(String(requestParameters.ingestionId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: IngestionUpdateRequestToJSON(
          requestParameters.ingestionUpdateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IngestionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Ingestion
   */
  async updateIngestion(
    this: IngestionApi,
    requestParameters: UpdateIngestionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IngestionFetchResponse> {
    const response = await this.updateIngestionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
