import { useEffect } from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocalStorage } from "../hooks";

const STORAGE_KEY = "chrome-alert-shown";

export default function SuggestChromeAlert() {
  const [hasShownChromeAlert, setHasShownChromeAlert] = useLocalStorage(
    STORAGE_KEY,
    false
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    function notifyUserOnceToUseChrome() {
      if (hasShownChromeAlert) {
        return;
      }

      setHasShownChromeAlert(true);

      if (isProbablyNotChrome()) {
        enqueueSnackbar(
          "LogQS Studio works best on recent versions of Google Chrome",
          {
            variant: "info",
            action(snackbarId) {
              return (
                <Tooltip title="Close">
                  <IconButton onClick={() => closeSnackbar(snackbarId)}>
                    <Close />
                  </IconButton>
                </Tooltip>
              );
            },
          }
        );
      }
    },
    [
      hasShownChromeAlert,
      setHasShownChromeAlert,
      enqueueSnackbar,
      closeSnackbar,
    ]
  );

  return null;
}

function isProbablyNotChrome(): boolean {
  if (!("userAgentData" in navigator)) {
    return true;
  }

  return (navigator as any).userAgentData.brands.every(
    (brand: any) => brand.brand !== "Google Chrome"
  );
}
