import React from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";
import Helmet from "../components/Helmet";

export default function SignIn() {
  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <Stack
        sx={{
          flexGrow: 1,
          minHeight: 0,
          justifyContent: "center",
        }}
      >
        <Container fixed maxWidth="sm">
          <Card>
            <CardContent>
              <Typography component="h1" variant="h4" gutterBottom>
                Sign In
              </Typography>
              <Typography>You must sign in to use LogQS Studio</Typography>
              <Button
                sx={{ mt: 4 }}
                fullWidth
                disableElevation
                color="primary"
                variant="contained"
                onClick={() => Auth.federatedSignIn()}
              >
                Sign In
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Stack>
    </>
  );
}
