import { useCallback } from "react";
import { Typography } from "@mui/material";
import Error from "../../../../components/Error";
import Loading from "../../../../components/Loading";
import { useLog } from "../../../../queries";
import type { LogFetchResponse } from "../../../../services/datastore";
import usePlayerConfig from "../../hooks/usePlayerConfig";
import BotLog from "./components/BotLog";
import RouterLog from "./components/RouterLog";
import type { MinibotLog } from "./types";
import { parseLogNameParts, selectMinibotLog } from "./utils";

export default function Minibot() {
  const { logId } = usePlayerConfig();

  const select = useCallback((response: LogFetchResponse): MinibotLog => {
    return selectMinibotLog(response.data);
  }, []);

  const logQuery = useLog(logId, { select });

  if (logQuery.isLoading) {
    return <Loading type="circular" />;
  }

  if (logQuery.isError) {
    return (
      <Error>
        <Typography variant="h5" component="p">
          Error fetching log
        </Typography>
      </Error>
    );
  }

  const nameParts = parseLogNameParts(logQuery.data.name);

  if (nameParts === null) {
    return (
      <Error>
        <Typography variant="h5" component="p">
          Couldn't determine if log is a router or bot log
        </Typography>
      </Error>
    );
  }

  return nameParts.type === "router" ? (
    <RouterLog log={logQuery.data} />
  ) : (
    <BotLog log={logQuery.data} />
  );
}
