/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface TopicCreateRequest
 */
export interface TopicCreateRequest {
  /**
   *
   * @type {string}
   * @memberof TopicCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TopicCreateRequest
   */
  logId: string;
  /**
   *
   * @type {string}
   * @memberof TopicCreateRequest
   */
  messageTypeId?: string;
  /**
   *
   * @type {string}
   * @memberof TopicCreateRequest
   */
  ingestionId?: string;
  /**
   *
   * @type {string}
   * @memberof TopicCreateRequest
   */
  associatedTopicId?: string;
  /**
   *
   * @type {object}
   * @memberof TopicCreateRequest
   */
  context?: object;
}

export function TopicCreateRequestFromJSON(json: any): TopicCreateRequest {
  return TopicCreateRequestFromJSONTyped(json, false);
}

export function TopicCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TopicCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    logId: json["log_id"],
    messageTypeId: !exists(json, "message_type_id")
      ? undefined
      : json["message_type_id"],
    ingestionId: !exists(json, "ingestion_id")
      ? undefined
      : json["ingestion_id"],
    associatedTopicId: !exists(json, "associated_topic_id")
      ? undefined
      : json["associated_topic_id"],
    context: !exists(json, "context") ? undefined : json["context"],
  };
}

export function TopicCreateRequestToJSON(
  value?: TopicCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    log_id: value.logId,
    message_type_id: value.messageTypeId,
    ingestion_id: value.ingestionId,
    associated_topic_id: value.associatedTopicId,
    context: value.context,
  };
}
