import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { fromUnixTime } from "date-fns";
import Time from "../../../../../../../components/Time";
import { selectData, useGroup } from "../../../../../../../queries";
import type { Log } from "../../../../../../../services/datastore";
import {
  renderGroupName,
  renderLogDuration,
  renderLogIngestionStatusChip,
} from "./utils";

export interface LogListItemProps {
  log: Log;
  selectedLogId: string | null;
  setSelectedLogId: (logId: string) => void;
}

export default function LogListItem({
  log,
  selectedLogId,
  setSelectedLogId,
}: LogListItemProps) {
  const hasDefinedBounds = log.startTime !== null && log.endTime !== null;

  const groupQuery = useGroup(log.groupId, { select: selectData });

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={log.id === selectedLogId}
        role={undefined}
        onClick={() => setSelectedLogId(log.id)}
      >
        <ListItemText
          disableTypography
          secondary={
            hasDefinedBounds ? (
              <Typography
                variant="body2"
                color="text.secondary"
                {...(hasDefinedBounds
                  ? { fontWeight: "bold" }
                  : { fontStyle: "italic" })}
              >
                {renderLogDuration(log)} &bull;{" "}
                <Time date={fromUnixTime(log.startTime!)} />
                {renderGroupName(groupQuery)}
              </Typography>
            ) : (
              renderGroupName(groupQuery)
            )
          }
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            width={1}
            sx={{ wordBreak: "break-all" }}
          >
            <Typography fontWeight="bold">{log.name}</Typography>
            {renderLogIngestionStatusChip(log, "small")}
          </Stack>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
