/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  MessageType,
  MessageTypeFromJSON,
  MessageTypeFromJSONTyped,
  MessageTypeToJSON,
} from "./MessageType";

/**
 *
 * @export
 * @interface MessageTypeFetchResponse
 */
export interface MessageTypeFetchResponse {
  /**
   *
   * @type {MessageType}
   * @memberof MessageTypeFetchResponse
   */
  data: MessageType;
}

export function MessageTypeFetchResponseFromJSON(
  json: any
): MessageTypeFetchResponse {
  return MessageTypeFetchResponseFromJSONTyped(json, false);
}

export function MessageTypeFetchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MessageTypeFetchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: MessageTypeFromJSON(json["data"]),
  };
}

export function MessageTypeFetchResponseToJSON(
  value?: MessageTypeFetchResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: MessageTypeToJSON(value.data),
  };
}
