/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  LogFormat,
  LogFormatFromJSON,
  LogFormatFromJSONTyped,
  LogFormatToJSON,
} from "./LogFormat";
import {
  ProcessStatus,
  ProcessStatusFromJSON,
  ProcessStatusFromJSONTyped,
  ProcessStatusToJSON,
} from "./ProcessStatus";

/**
 *
 * @export
 * @interface Extraction
 */
export interface Extraction {
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof Extraction
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Extraction
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Extraction
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  deletedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  logId: string;
  /**
   *
   * @type {ProcessStatus}
   * @memberof Extraction
   */
  status: ProcessStatus;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  processing: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  queued: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  cancelled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  errored: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  archived: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Extraction
   */
  completed: boolean;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  name: string | null;
  /**
   *
   * @type {LogFormat}
   * @memberof Extraction
   */
  format: LogFormat | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  s3Bucket: string | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  s3Key: string | null;
  /**
   *
   * @type {number}
   * @memberof Extraction
   */
  size: number | null;
  /**
   *
   * @type {number}
   * @memberof Extraction
   */
  progress: number | null;
  /**
   *
   * @type {object}
   * @memberof Extraction
   */
  error: object | null;
  /**
   *
   * @type {string}
   * @memberof Extraction
   */
  note: string | null;
  /**
   *
   * @type {object}
   * @memberof Extraction
   */
  context: object | null;
}

export function ExtractionFromJSON(json: any): Extraction {
  return ExtractionFromJSONTyped(json, false);
}

export function ExtractionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Extraction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    logId: json["log_id"],
    status: ProcessStatusFromJSON(json["status"]),
    processing: json["processing"],
    queued: json["queued"],
    cancelled: json["cancelled"],
    errored: json["errored"],
    archived: json["archived"],
    completed: json["completed"],
    name: json["name"],
    format: LogFormatFromJSON(json["format"]),
    s3Bucket: json["s3_bucket"],
    s3Key: json["s3_key"],
    size: json["size"],
    progress: json["progress"],
    error: json["error"],
    note: json["note"],
    context: json["context"],
  };
}

export function ExtractionToJSON(value?: Extraction | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    log_id: value.logId,
    status: ProcessStatusToJSON(value.status),
    processing: value.processing,
    queued: value.queued,
    cancelled: value.cancelled,
    errored: value.errored,
    archived: value.archived,
    completed: value.completed,
    name: value.name,
    format: LogFormatToJSON(value.format),
    s3_bucket: value.s3Bucket,
    s3_key: value.s3Key,
    size: value.size,
    progress: value.progress,
    error: value.error,
    note: value.note,
    context: value.context,
  };
}
