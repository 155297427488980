import React, { useEffect, useRef } from "react";
import { ArrowBack, Clear } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { minutesToMilliseconds, secondsToMilliseconds } from "date-fns";
import invariant from "invariant";
import { filter, find, sortBy } from "lodash";
import { useSnackbar } from "notistack";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import BreakableText from "../../../../../../../components/BreakableText";
import DrawerHeader from "../../../../../../../components/DrawerHeader";
import { useLayoutStateContext } from "../../../../../../../components/Layout";
import { useCurrentDataStore } from "../../../../../../../domain/datastores";
import * as paths from "../../../../../../../paths";
import type { Extraction, Log } from "../../../../../../../services/datastore";
import { pluralize } from "../../../../../../../utils";
import { deriveIngestionStatus } from "../../../../../../../utils/logs";
import {
  useFormatPlaybackTimestamp,
  usePlaybackSource,
} from "../../../../../PlaybackProvider";
import TopicSelect from "../../../../../components/TopicSelect";
import {
  usePlayerConfig,
  usePlayerLog,
  usePlayerTopics,
} from "../../../../../hooks";
import {
  centerRange,
  enterRangeMode,
  exitRangeMode,
} from "../../../../../playbackReducer";
import { useCreateExtraction } from "../../../../../queries";
import type { DraftExtractionTopic } from "../../../../../types";
import TfStaticAlert from "./TfStaticAlert";
import type { DraftExtraction } from "./useDraftExtraction";
import {
  abortFinalizing,
  draftSelectedTopics,
  removeDraftTopic,
  resetDraft,
  selectLayoutTopics,
  setSelectedTopics,
  startFinalizing,
} from "./useDraftExtraction";

type FormValues = {
  name: string;
};

const defaultFormValues: FormValues = {
  name: "",
};

export type ExtractionDrawerProps = {
  draftExtraction: DraftExtraction;
} & ReturnType<typeof useExtractionFinalizer>;

export default function ExtractionDrawer({
  draftExtraction,
  createExtraction,
  form,
  onSubmit,
}: ExtractionDrawerProps) {
  const { logId } = usePlayerConfig();
  const logQuery = usePlayerLog();

  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const loading = playbackSource.isLoading || !topicsQuery.isSuccess;
  const selectLayoutTopicsDisabled =
    loading ||
    playbackSource.inRangeMode ||
    !draftExtraction.canSelectLayoutTopics;
  const topicSelectDisabled = loading || playbackSource.inRangeMode;
  const selectTimeManuallyDisabled = loading;
  const selectRelativeTimeDisabled = loading || playbackSource.inRangeMode;
  const addToDraftDisabled =
    loading ||
    playbackSource.inRangeMode ||
    draftExtraction.selectedTopicIds.length === 0;
  const finalizeDisabled =
    loading ||
    playbackSource.inRangeMode ||
    draftExtraction.topics.length === 0;
  const areRangeEndsEqual =
    !playbackSource.isLoading &&
    playbackSource.rangeMs[0] === playbackSource.rangeMs[1];

  function handleSelectLayoutTopics() {
    invariant(
      !selectLayoutTopicsDisabled,
      "Layout topics button should not be enabled"
    );

    draftExtraction.dispatch(selectLayoutTopics());
  }

  function handleSelectTimeManually() {
    invariant(
      !selectTimeManuallyDisabled,
      "Manual-select button should not be enabled yet"
    );

    playbackSource.dispatch(enterRangeMode());
  }

  function makeRelativeTimeClickHandler(relativeTimeMs: number) {
    return function onRelativeTimeClick() {
      invariant(
        !selectRelativeTimeDisabled,
        "Relative time buttons should not be enabled right now"
      );

      playbackSource.dispatch(centerRange(relativeTimeMs));
    };
  }

  function handleAddDraftTopics() {
    invariant(!addToDraftDisabled, "Cannot add draft topics yet");

    draftExtraction.dispatch(draftSelectedTopics());
  }

  function handleReset() {
    draftExtraction.dispatch(resetDraft());

    createExtraction.reset();
    form.reset(defaultFormValues);
  }

  // TODO: Move this into the reducer?
  const selectedTopics = filter(topicsQuery.data ?? [], (topic) =>
    draftExtraction.selectedTopicIds.includes(topic.id)
  );

  if (!draftExtraction.isFinalizing) {
    return (
      <>
        <DrawerHeader title="Create an Extraction" />
        {renderOptionalAlert(logId, logQuery)}
        <Typography paragraph>
          An extraction is a subset of a log you can download for offline use.
        </Typography>
        <Typography paragraph>
          To create an extraction, select topics you're interested in and choose
          what time ranges in the log you want records for those topics. You can
          choose multiple time ranges for a topic or choose topics in different
          time ranges.
        </Typography>
        <Stack spacing={5}>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Select Topics
            </Typography>
            <Stack spacing={3}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                disabled={selectLayoutTopicsDisabled}
                onClick={handleSelectLayoutTopics}
              >
                Select Layout Topics
              </Button>
              <TopicSelect
                multiple
                disabled={topicSelectDisabled}
                value={selectedTopics}
                onChange={(topics) =>
                  draftExtraction.dispatch(setSelectedTopics(topics))
                }
                inputLabel="Selected topics"
                topics={topicsQuery.data ?? []}
              />
            </Stack>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Select Time Range
            </Typography>
            <Stack spacing={3}>
              {playbackSource.inRangeMode ? (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    fullWidth
                    disabled={areRangeEndsEqual}
                    onClick={() => playbackSource.dispatch(exitRangeMode())}
                  >
                    Confirm Selection
                  </Button>
                  {areRangeEndsEqual && (
                    <Typography>Time range edges cannot be equal</Typography>
                  )}
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={selectTimeManuallyDisabled}
                  onClick={handleSelectTimeManually}
                >
                  Select Manually
                </Button>
              )}
              <div>
                <Typography id="relative-time-title">
                  Relative to playback time:
                </Typography>
                <ButtonGroup
                  aria-labelledby="relative-time-title"
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  disabled={selectRelativeTimeDisabled}
                >
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      secondsToMilliseconds(15)
                    )}
                  >
                    &plusmn; 15 sec
                  </Button>
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      minutesToMilliseconds(1)
                    )}
                  >
                    &plusmn; 1 min
                  </Button>
                  <Button
                    onClick={makeRelativeTimeClickHandler(
                      minutesToMilliseconds(3)
                    )}
                  >
                    &plusmn; 3 min
                  </Button>
                </ButtonGroup>
              </div>
              <div>
                <Typography>Selected time range:</Typography>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    "& pre": {
                      bgcolor: (theme) =>
                        theme.palette.mode === "light"
                          ? "grey.300"
                          : "grey.700",
                      border: 1,
                      borderColor: "divider",
                      borderRadius: 1,
                      m: 0,
                      px: 1,
                      py: 0.5,
                    },
                  }}
                >
                  <pre>
                    {loading
                      ? "--"
                      : formatPlaybackTimestamp(playbackSource.rangeMs[0])}
                  </pre>
                  -
                  <pre>
                    {loading
                      ? "--"
                      : formatPlaybackTimestamp(playbackSource.rangeMs[1])}
                  </pre>
                </Stack>
              </div>
            </Stack>
          </div>
          <div>
            <Typography variant="h6" gutterBottom>
              Add to Draft
            </Typography>
            <Typography paragraph>
              {addToDraftDisabled ? (
                "Select topics and a time range to add to the draft"
              ) : (
                <>
                  You've selected{" "}
                  {pluralize(draftExtraction.selectedTopicIds.length, "topic")}{" "}
                  to be added between{" "}
                  {formatPlaybackTimestamp(playbackSource.rangeMs[0])}
                  {" - "}
                  {formatPlaybackTimestamp(playbackSource.rangeMs[1])}
                </>
              )}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              disabled={addToDraftDisabled}
              onClick={handleAddDraftTopics}
            >
              Add to Draft
            </Button>
          </div>
          <div>
            <Typography variant="h6" component="p" gutterBottom>
              Extraction Summary
            </Typography>
            {logId === null ? (
              <Typography paragraph>
                Choose a log to start making an extraction
              </Typography>
            ) : loading ? (
              <Typography paragraph>Loading...</Typography>
            ) : draftExtraction.topics.length === 0 ? (
              <Typography paragraph>
                No topics added to this draft yet
              </Typography>
            ) : (
              <DraftExtractionList
                draftExtractionTopics={draftExtraction.topics}
                dispatch={draftExtraction.dispatch}
              />
            )}
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={finalizeDisabled}
              onClick={() => draftExtraction.dispatch(startFinalizing())}
            >
              Finalize Extraction
            </Button>
          </div>
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <DrawerHeader title="Finalize Extraction" />
        <Button
          sx={{ mb: 2 }}
          disabled={createExtraction.isLoading || createExtraction.isSuccess}
          color="primary"
          variant="text"
          startIcon={<ArrowBack />}
          onClick={() => draftExtraction.dispatch(abortFinalizing())}
        >
          Back to Draft
        </Button>
        {!loading && (
          <TfStaticAlert
            playbackTopics={topicsQuery.data}
            playerBounds={playbackSource.boundsMs}
            draftExtractionTopics={draftExtraction.topics}
            dispatch={draftExtraction.dispatch}
          />
        )}
        <Typography mt={2}>
          The following topics will be extracted in the time ranges you chose:
        </Typography>
        <DraftExtractionList
          disableRemove
          draftExtractionTopics={draftExtraction.topics}
          dispatch={draftExtraction.dispatch}
        />
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Typography paragraph>
            You can provide a name for the extraction to help you identify it
            later:
          </Typography>
          <Controller
            name="name"
            control={form.control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={
                  createExtraction.isLoading || createExtraction.isSuccess
                }
                label="Name (optional)"
              />
            )}
          />
          <LoadingButton
            sx={{ mt: 2, mb: 4 }}
            color="primary"
            variant="contained"
            fullWidth
            loading={createExtraction.isLoading}
            disabled={createExtraction.isSuccess}
            type="submit"
          >
            Submit Extraction
          </LoadingButton>
        </form>
        {createExtraction.isError && (
          <Alert severity="error" variant="filled">
            Failed to create the extraction
          </Alert>
        )}
        {createExtraction.isSuccess && (
          <>
            <SuccessAlert extractionId={createExtraction.data.data.id} />
            <Button
              sx={{ mt: 2 }}
              color="primary"
              variant="text"
              onClick={handleReset}
            >
              Start New Extraction
            </Button>
          </>
        )}
      </>
    );
  }
}

interface SuccessAlertProps {
  extractionId: Extraction["id"];
}

function SuccessAlert({ extractionId }: SuccessAlertProps) {
  const dataStore = useCurrentDataStore();

  return (
    <Alert severity="success" variant="filled">
      <Link
        component={RouterLink}
        to={paths.makeExtractionsLocation({
          url: dataStore,
          extractionId,
        })}
        color="inherit"
      >
        View your extraction's details
      </Link>
    </Alert>
  );
}

interface DraftExtractionListProps {
  disableRemove?: boolean;
  draftExtractionTopics: DraftExtractionTopic[];
  dispatch: DraftExtraction["dispatch"];
}

function DraftExtractionList({
  disableRemove = false,
  draftExtractionTopics,
  dispatch,
}: DraftExtractionListProps) {
  const topicsQuery = usePlayerTopics();
  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  function makeRemoveDraftTopicHandler(draftTopic: DraftExtractionTopic) {
    return function handleRemoveDraftTopic() {
      dispatch(removeDraftTopic(draftTopic));
    };
  }

  return (
    <List>
      {sortBy(
        Array.from(groupDrafts(draftExtractionTopics).entries()),
        "0"
      ).map(([topicId, drafts]) => {
        const topic = find(topicsQuery.data, { id: topicId });

        invariant(topic !== undefined, "Topic not found");

        return (
          <ListItem
            key={topicId}
            sx={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <ListItemText>
              <BreakableText separator={/(\/)/}>{topic.name}</BreakableText>
            </ListItemText>
            <List sx={{ alignSelf: "stretch" }}>
              {sortBy(drafts, "startTimeMs").map((draft) => (
                <ListItem
                  key={draft.startTimeMs}
                  secondaryAction={
                    disableRemove ? undefined : (
                      <Tooltip title="Remove this time range">
                        <IconButton
                          onClick={makeRemoveDraftTopicHandler(draft)}
                        >
                          <Clear />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                >
                  <ListItemText>
                    {formatPlaybackTimestamp(draft.startTimeMs)}
                    {" - "}
                    {formatPlaybackTimestamp(draft.endTimeMs)}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </ListItem>
        );
      })}
    </List>
  );
}

function groupDrafts(
  drafts: DraftExtractionTopic[]
): Map<DraftExtractionTopic["topicId"], DraftExtractionTopic[]> {
  const draftMap = new Map<
    DraftExtractionTopic["topicId"],
    DraftExtractionTopic[]
  >();

  drafts.forEach((draft) => {
    if (!draftMap.has(draft.topicId)) {
      draftMap.set(draft.topicId, []);
    }

    draftMap.get(draft.topicId)!.push(draft);
  });

  return draftMap;
}

function renderOptionalAlert(
  logId: Log["id"] | null,
  logQuery: ReturnType<typeof usePlayerLog>
) {
  if (logId === null) {
    return (
      <Alert severity="info" variant="filled" sx={{ mb: 2 }}>
        Choose a log to create an extraction
      </Alert>
    );
  }

  if (logQuery.isLoading || logQuery.isError) {
    return null;
  }

  const status = deriveIngestionStatus(logQuery.data);

  if (["error", "processing", "unknown"].includes(status)) {
    return (
      <Alert
        severity="warning"
        variant="filled"
        sx={{ mb: 2, "& span": { fontWeight: "bold" } }}
      >
        This log's status is <span>{status}</span>. An extraction created now
        may contain incomplete data.
      </Alert>
    );
  }
}

export function useExtractionFinalizer(
  draftExtractionTopics: DraftExtractionTopic[]
) {
  const { logId } = usePlayerConfig();

  const { sideSheetState } = useLayoutStateContext();

  // Since <ExtractionDrawer /> is always mounted even if not visible, there
  // needs to be a way to let the user know their extraction is done if they
  // close the drawer while the mutation is ongoing. This ref is used by the
  // mutation callbacks to show a snackbar if the mutation settles while the
  // drawer is closed.
  const isDrawerOpenRef = useRef(sideSheetState === "extractions");
  useEffect(
    function updateDrawerOpenRef() {
      isDrawerOpenRef.current = sideSheetState === "extractions";
    },
    [sideSheetState]
  );

  const form = useForm<FormValues>({ defaultValues: defaultFormValues });

  const { enqueueSnackbar } = useSnackbar();
  const createExtraction = useCreateExtraction();
  const onSubmit: SubmitHandler<FormValues> = function onSubmit({ name }) {
    invariant(logId !== null, "Log ID cannot be null");

    createExtraction.mutate(
      {
        logId,
        draftExtractionTopics,
        name: name || undefined,
      },
      {
        onSuccess() {
          if (isDrawerOpenRef.current) {
            return;
          }

          enqueueSnackbar(
            "Extraction created! Open the extraction sidebar for more details",
            { variant: "success" }
          );
        },
        onError() {
          if (isDrawerOpenRef.current) {
            return;
          }

          enqueueSnackbar(
            "Couldn't create extraction. Open the extraction sidebar for more details",
            { variant: "error" }
          );
        },
      }
    );
  };

  return {
    createExtraction,
    form,
    onSubmit,
  };
}
