/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import {
  CreatePresignedURLRequest,
  CreatePresignedURLRequestFromJSON,
  CreatePresignedURLRequestToJSON,
  CreatePresignedURLResponse,
  CreatePresignedURLResponseFromJSON,
  CreatePresignedURLResponseToJSON,
  ExtractionCreateRequest,
  ExtractionCreateRequestFromJSON,
  ExtractionCreateRequestToJSON,
  ExtractionFetchResponse,
  ExtractionFetchResponseFromJSON,
  ExtractionFetchResponseToJSON,
  ExtractionListResponse,
  ExtractionListResponseFromJSON,
  ExtractionListResponseToJSON,
  ExtractionTopicCreateRequest,
  ExtractionTopicCreateRequestFromJSON,
  ExtractionTopicCreateRequestToJSON,
  ExtractionTopicFetchResponse,
  ExtractionTopicFetchResponseFromJSON,
  ExtractionTopicFetchResponseToJSON,
  ExtractionTopicListResponse,
  ExtractionTopicListResponseFromJSON,
  ExtractionTopicListResponseToJSON,
  ExtractionTopicUpdateRequest,
  ExtractionTopicUpdateRequestFromJSON,
  ExtractionTopicUpdateRequestToJSON,
  ExtractionUpdateRequest,
  ExtractionUpdateRequestFromJSON,
  ExtractionUpdateRequestToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LogFormat,
  LogFormatFromJSON,
  LogFormatToJSON,
  ProcessStatus,
  ProcessStatusFromJSON,
  ProcessStatusToJSON,
} from "../models";
import * as runtime from "../runtime";

export interface CreateExtractionRequest {
  extractionCreateRequest: ExtractionCreateRequest;
}

export interface CreateExtractionPresignedUrlRequest {
  extractionId: string;
  createPresignedURLRequest: CreatePresignedURLRequest;
}

export interface CreateExtractionTopicRequest {
  extractionId: string;
  extractionTopicCreateRequest: ExtractionTopicCreateRequest;
}

export interface DeleteExtractionRequest {
  extractionId: string;
}

export interface DeleteExtractionTopicRequest {
  extractionId: string;
  extractionTopicId: string;
}

export interface GetExtractionRequest {
  extractionId: string;
}

export interface GetExtractionTopicRequest {
  extractionId: string;
  extractionTopicId: string;
}

export interface ListExtractionTopicsRequest {
  extractionId: string;
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  topicId?: string;
  startTimeNull?: boolean;
  startTimeLte?: number;
  startTimeGte?: number;
  endTimeNull?: boolean;
  endTimeLte?: number;
  endTimeGte?: number;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  createdAtLte?: Date;
  createdAtGte?: Date;
  updatedAtLte?: Date;
  updatedAtGte?: Date;
  deletedAtLte?: Date;
  deletedAtGte?: Date;
}

export interface ListExtractionsRequest {
  offset?: number;
  limit?: number;
  order?: string;
  sort?: string;
  logId?: string;
  groupId?: string;
  status?: ProcessStatus;
  processing?: boolean;
  queued?: boolean;
  cancelled?: boolean;
  errored?: boolean;
  archived?: boolean;
  completed?: boolean;
  name?: string;
  nameLike?: string;
  format?: LogFormat;
  s3Bucket?: string;
  s3Key?: string;
  s3KeyPrefix?: string;
  sizeNull?: boolean;
  sizeGte?: number;
  sizeLte?: number;
  progressNull?: boolean;
  progressGte?: number;
  progressLte?: number;
  errorLike?: string;
  noteLike?: string;
  contextFilter?: any;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  updatedByNull?: boolean;
  deletedByNull?: boolean;
  updatedAtNull?: boolean;
  deletedAtNull?: boolean;
  createdAtLte?: Date;
  updatedAtLte?: Date;
  deletedAtLte?: Date;
  createdAtGte?: Date;
  updatedAtGte?: Date;
  deletedAtGte?: Date;
}

export interface UpdateExtractionRequest {
  extractionId: string;
  extractionUpdateRequest: ExtractionUpdateRequest;
}

export interface UpdateExtractionTopicRequest {
  extractionId: string;
  extractionTopicId: string;
  extractionTopicUpdateRequest: ExtractionTopicUpdateRequest;
}

/**
 *
 */
export class ExtractionApi extends runtime.BaseAPI {
  /**
   * Create Extraction
   */
  async createExtractionRaw(
    this: ExtractionApi,
    requestParameters: CreateExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionFetchResponse>> {
    if (
      requestParameters.extractionCreateRequest === null ||
      requestParameters.extractionCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionCreateRequest",
        "Required parameter requestParameters.extractionCreateRequest was null or undefined when calling createExtraction."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ExtractionCreateRequestToJSON(
          requestParameters.extractionCreateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Extraction
   */
  async createExtraction(
    this: ExtractionApi,
    requestParameters: CreateExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionFetchResponse> {
    const response = await this.createExtractionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Extraction Presigned Url
   */
  async createExtractionPresignedUrlRaw(
    this: ExtractionApi,
    requestParameters: CreateExtractionPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<CreatePresignedURLResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling createExtractionPresignedUrl."
      );
    }

    if (
      requestParameters.createPresignedURLRequest === null ||
      requestParameters.createPresignedURLRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "createPresignedURLRequest",
        "Required parameter requestParameters.createPresignedURLRequest was null or undefined when calling createExtractionPresignedUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/presignedUrls`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePresignedURLRequestToJSON(
          requestParameters.createPresignedURLRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CreatePresignedURLResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Extraction Presigned Url
   */
  async createExtractionPresignedUrl(
    this: ExtractionApi,
    requestParameters: CreateExtractionPresignedUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<CreatePresignedURLResponse> {
    const response = await this.createExtractionPresignedUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create Extraction Topic
   */
  async createExtractionTopicRaw(
    this: ExtractionApi,
    requestParameters: CreateExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionTopicFetchResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling createExtractionTopic."
      );
    }

    if (
      requestParameters.extractionTopicCreateRequest === null ||
      requestParameters.extractionTopicCreateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionTopicCreateRequest",
        "Required parameter requestParameters.extractionTopicCreateRequest was null or undefined when calling createExtractionTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/topics`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ExtractionTopicCreateRequestToJSON(
          requestParameters.extractionTopicCreateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionTopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create Extraction Topic
   */
  async createExtractionTopic(
    this: ExtractionApi,
    requestParameters: CreateExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionTopicFetchResponse> {
    const response = await this.createExtractionTopicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Required role: [owner, admin]
   * Delete Extraction
   */
  async deleteExtractionRaw(
    this: ExtractionApi,
    requestParameters: DeleteExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling deleteExtraction."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [owner, admin]
   * Delete Extraction
   */
  async deleteExtraction(
    this: ExtractionApi,
    requestParameters: DeleteExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteExtractionRaw(requestParameters, initOverrides);
  }

  /**
   * Required role: [editor, owner, admin]  Note: Can only be deleted when parent extraction has \"ready\" status
   * Delete Extraction Topic
   */
  async deleteExtractionTopicRaw(
    this: ExtractionApi,
    requestParameters: DeleteExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling deleteExtractionTopic."
      );
    }

    if (
      requestParameters.extractionTopicId === null ||
      requestParameters.extractionTopicId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionTopicId",
        "Required parameter requestParameters.extractionTopicId was null or undefined when calling deleteExtractionTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/topics/{extraction_topic_id}`
          .replace(
            `{${"extraction_id"}}`,
            encodeURIComponent(String(requestParameters.extractionId))
          )
          .replace(
            `{${"extraction_topic_id"}}`,
            encodeURIComponent(String(requestParameters.extractionTopicId))
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Required role: [editor, owner, admin]  Note: Can only be deleted when parent extraction has \"ready\" status
   * Delete Extraction Topic
   */
  async deleteExtractionTopic(
    this: ExtractionApi,
    requestParameters: DeleteExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<void> {
    await this.deleteExtractionTopicRaw(requestParameters, initOverrides);
  }

  /**
   * Get Extraction
   */
  async getExtractionRaw(
    this: ExtractionApi,
    requestParameters: GetExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionFetchResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling getExtraction."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Extraction
   */
  async getExtraction(
    this: ExtractionApi,
    requestParameters: GetExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionFetchResponse> {
    const response = await this.getExtractionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Extraction Topic
   */
  async getExtractionTopicRaw(
    this: ExtractionApi,
    requestParameters: GetExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionTopicFetchResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling getExtractionTopic."
      );
    }

    if (
      requestParameters.extractionTopicId === null ||
      requestParameters.extractionTopicId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionTopicId",
        "Required parameter requestParameters.extractionTopicId was null or undefined when calling getExtractionTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/topics/{extraction_topic_id}`
          .replace(
            `{${"extraction_id"}}`,
            encodeURIComponent(String(requestParameters.extractionId))
          )
          .replace(
            `{${"extraction_topic_id"}}`,
            encodeURIComponent(String(requestParameters.extractionTopicId))
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionTopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Extraction Topic
   */
  async getExtractionTopic(
    this: ExtractionApi,
    requestParameters: GetExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionTopicFetchResponse> {
    const response = await this.getExtractionTopicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Extraction Topics
   */
  async listExtractionTopicsRaw(
    this: ExtractionApi,
    requestParameters: ListExtractionTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionTopicListResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling listExtractionTopics."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.topicId !== undefined) {
      queryParameters["topic_id"] = requestParameters.topicId;
    }

    if (requestParameters.startTimeNull !== undefined) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeLte !== undefined) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.startTimeGte !== undefined) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.endTimeNull !== undefined) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeLte !== undefined) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.endTimeGte !== undefined) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/topics`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionTopicListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Extraction Topics
   */
  async listExtractionTopics(
    this: ExtractionApi,
    requestParameters: ListExtractionTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionTopicListResponse> {
    const response = await this.listExtractionTopicsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Extractions
   */
  async listExtractionsRaw(
    this: ExtractionApi,
    requestParameters: ListExtractionsRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionListResponse>> {
    const queryParameters: any = {};

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.logId !== undefined) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.groupId !== undefined) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.processing !== undefined) {
      queryParameters["processing"] = requestParameters.processing;
    }

    if (requestParameters.queued !== undefined) {
      queryParameters["queued"] = requestParameters.queued;
    }

    if (requestParameters.cancelled !== undefined) {
      queryParameters["cancelled"] = requestParameters.cancelled;
    }

    if (requestParameters.errored !== undefined) {
      queryParameters["errored"] = requestParameters.errored;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    if (requestParameters.completed !== undefined) {
      queryParameters["completed"] = requestParameters.completed;
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike !== undefined) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.format !== undefined) {
      queryParameters["format"] = requestParameters.format;
    }

    if (requestParameters.s3Bucket !== undefined) {
      queryParameters["s3_bucket"] = requestParameters.s3Bucket;
    }

    if (requestParameters.s3Key !== undefined) {
      queryParameters["s3_key"] = requestParameters.s3Key;
    }

    if (requestParameters.s3KeyPrefix !== undefined) {
      queryParameters["s3_key_prefix"] = requestParameters.s3KeyPrefix;
    }

    if (requestParameters.sizeNull !== undefined) {
      queryParameters["size_null"] = requestParameters.sizeNull;
    }

    if (requestParameters.sizeGte !== undefined) {
      queryParameters["size_gte"] = requestParameters.sizeGte;
    }

    if (requestParameters.sizeLte !== undefined) {
      queryParameters["size_lte"] = requestParameters.sizeLte;
    }

    if (requestParameters.progressNull !== undefined) {
      queryParameters["progress_null"] = requestParameters.progressNull;
    }

    if (requestParameters.progressGte !== undefined) {
      queryParameters["progress_gte"] = requestParameters.progressGte;
    }

    if (requestParameters.progressLte !== undefined) {
      queryParameters["progress_lte"] = requestParameters.progressLte;
    }

    if (requestParameters.errorLike !== undefined) {
      queryParameters["error_like"] = requestParameters.errorLike;
    }

    if (requestParameters.noteLike !== undefined) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter !== undefined) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.createdBy !== undefined) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy !== undefined) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy !== undefined) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull !== undefined) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull !== undefined) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull !== undefined) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull !== undefined) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte !== undefined) {
      queryParameters["created_at_lte"] = (
        requestParameters.createdAtLte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtLte !== undefined) {
      queryParameters["updated_at_lte"] = (
        requestParameters.updatedAtLte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtLte !== undefined) {
      queryParameters["deleted_at_lte"] = (
        requestParameters.deletedAtLte as any
      ).toISOString();
    }

    if (requestParameters.createdAtGte !== undefined) {
      queryParameters["created_at_gte"] = (
        requestParameters.createdAtGte as any
      ).toISOString();
    }

    if (requestParameters.updatedAtGte !== undefined) {
      queryParameters["updated_at_gte"] = (
        requestParameters.updatedAtGte as any
      ).toISOString();
    }

    if (requestParameters.deletedAtGte !== undefined) {
      queryParameters["deleted_at_gte"] = (
        requestParameters.deletedAtGte as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionListResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Extractions
   */
  async listExtractions(
    this: ExtractionApi,
    requestParameters: ListExtractionsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionListResponse> {
    const response = await this.listExtractionsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Extraction
   */
  async updateExtractionRaw(
    this: ExtractionApi,
    requestParameters: UpdateExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionFetchResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling updateExtraction."
      );
    }

    if (
      requestParameters.extractionUpdateRequest === null ||
      requestParameters.extractionUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionUpdateRequest",
        "Required parameter requestParameters.extractionUpdateRequest was null or undefined when calling updateExtraction."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}`.replace(
          `{${"extraction_id"}}`,
          encodeURIComponent(String(requestParameters.extractionId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ExtractionUpdateRequestToJSON(
          requestParameters.extractionUpdateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Extraction
   */
  async updateExtraction(
    this: ExtractionApi,
    requestParameters: UpdateExtractionRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionFetchResponse> {
    const response = await this.updateExtractionRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Extraction Topic
   */
  async updateExtractionTopicRaw(
    this: ExtractionApi,
    requestParameters: UpdateExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExtractionTopicFetchResponse>> {
    if (
      requestParameters.extractionId === null ||
      requestParameters.extractionId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionId",
        "Required parameter requestParameters.extractionId was null or undefined when calling updateExtractionTopic."
      );
    }

    if (
      requestParameters.extractionTopicId === null ||
      requestParameters.extractionTopicId === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionTopicId",
        "Required parameter requestParameters.extractionTopicId was null or undefined when calling updateExtractionTopic."
      );
    }

    if (
      requestParameters.extractionTopicUpdateRequest === null ||
      requestParameters.extractionTopicUpdateRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "extractionTopicUpdateRequest",
        "Required parameter requestParameters.extractionTopicUpdateRequest was null or undefined when calling updateExtractionTopic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token();

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request(
      {
        path: `/api/extractions/{extraction_id}/topics/{extraction_topic_id}`
          .replace(
            `{${"extraction_id"}}`,
            encodeURIComponent(String(requestParameters.extractionId))
          )
          .replace(
            `{${"extraction_topic_id"}}`,
            encodeURIComponent(String(requestParameters.extractionTopicId))
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ExtractionTopicUpdateRequestToJSON(
          requestParameters.extractionTopicUpdateRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ExtractionTopicFetchResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Extraction Topic
   */
  async updateExtractionTopic(
    this: ExtractionApi,
    requestParameters: UpdateExtractionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExtractionTopicFetchResponse> {
    const response = await this.updateExtractionTopicRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
