import type { QueryParamConfig } from "use-query-params";
import { decodeNumber, encodeNumber } from "use-query-params";
import { z } from "zod";
import type { Maybe } from "./types";

export function makeLimitParam(
  options: number[]
): QueryParamConfig<number, number | null> {
  return {
    encode: encodeNumber,
    decode(value) {
      const numValue = decodeNumber(value);

      if (numValue == null) {
        return null;
      }

      if (!options.includes(numValue)) {
        return null;
      }

      return numValue;
    },
  };
}

export const OffsetParam: QueryParamConfig<number, Maybe<number>> = {
  encode: encodeNumber,
  decode(value) {
    const numValue = decodeNumber(value);

    const result = z.number().int().nonnegative().nullish().safeParse(numValue);

    if (result.success) {
      return result.data;
    } else {
      return null;
    }
  },
};
