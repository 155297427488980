import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { startOfToday } from "date-fns";
import { maxBy, sumBy } from "lodash";
import type { StrictOmit } from "ts-essentials";
import type { Group } from "../../services/datastore";
import type {
  FetchMetricsRequest,
  FetchMetricsResponse,
  Metric,
} from "../../services/minibot";
import { fetchMetrics, ReportDuration } from "../../services/minibot";
import { convertToNewPeriod, createReportHelper } from "./utils";

export type ApiMetricName = keyof Pick<
  Metric,
  | "bot_metrics_count_max"
  | "delivery_times_count_sum"
  | "pick_times_count_sum"
  | "total_scratches_count_sum"
  | "total_onboard_halts_count_sum"
>;

export interface SiteMetrics {
  groupId: Group["id"];
  startDate: Date;
  duration: ReportDuration;
  maxActiveBotsCount: number;
  totalDeliveriesCount: number;
  totalPicksCount: number;
  totalScratchesCount: number;
  totalOnboardHaltsCount: number;
  xAxisLabel: string;
  chartTicks: string[];
  periods: Array<{
    startDate: Date;
    label: string;
    value: number;
    cumulativeValue: number | undefined;
  }>;
}

export type MetricName = keyof StrictOmit<
  SiteMetrics,
  "groupId" | "startDate" | "duration" | "xAxisLabel" | "chartTicks" | "periods"
>;

export function useSiteMetrics() {
  const [groupId, setGroupId] = useState("");
  const [date, setDate] = useState(() => {
    const today = startOfToday();
    // Need date to be in UTC, not local timezone
    today.setUTCHours(0, 0, 0, 0);
    return today;
  });
  const [duration, setDuration] = useState<ReportDuration>(ReportDuration.Day);

  const [activeMetric, setActiveMetric] =
    useState<MetricName>("maxActiveBotsCount");

  const select = useCallback(
    (response: FetchMetricsResponse) => {
      return processApiMetrics(response, date, duration, activeMetric);
    },
    [date, duration, activeMetric]
  );

  const request: FetchMetricsRequest = {
    groupId: groupId || undefined,
    date,
    duration,
  };

  const query = useQuery({
    queryKey: ["metrics", request],
    queryFn() {
      return fetchMetrics(request);
    },
    select,
    keepPreviousData: true,
  });

  return {
    groupId: groupId || (query.data?.groupId ?? ""),
    selectGroup(groupId: Group["id"]) {
      setGroupId(groupId);
    },
    date,
    showPreviousPeriod() {
      setDate(createReportHelper(duration).calculatePreviousPeriodStart(date));
    },
    showNextPeriod() {
      setDate(createReportHelper(duration).calculateNextPeriodStart(date));
    },
    selectGraphedPeriod(startDate: Date, currentDuration: ReportDuration) {
      if (currentDuration === "hour") {
        return;
      }

      setDuration(
        currentDuration === "month"
          ? "week"
          : currentDuration === "week"
          ? "day"
          : "hour"
      );
      setDate(startDate);
    },
    reportDuration: duration,
    selectReportDuration(newDuration: ReportDuration) {
      setDuration(newDuration);
      setDate(convertToNewPeriod(date, newDuration));
    },
    activeMetric,
    selectMetric(metricName: MetricName) {
      setActiveMetric(metricName);
    },
    query,
  };
}

// Utilities

const metricNameMap: Record<MetricName, ApiMetricName> = {
  maxActiveBotsCount: "bot_metrics_count_max",
  totalDeliveriesCount: "delivery_times_count_sum",
  totalPicksCount: "pick_times_count_sum",
  totalScratchesCount: "total_scratches_count_sum",
  totalOnboardHaltsCount: "total_onboard_halts_count_sum",
};

function processApiMetrics(
  response: FetchMetricsResponse,
  date: Date,
  duration: ReportDuration,
  activeMetric: MetricName
): SiteMetrics {
  const metrics: SiteMetrics["periods"] = [];
  response.data.forEach((apiMetric, index) => {
    const value = apiMetric[metricNameMap[activeMetric]];

    let cumulativeValue: number | undefined = undefined;
    if (activeMetric !== "maxActiveBotsCount") {
      const previousHour = metrics.at(index - 1);
      const previousCumulativeValue = previousHour?.cumulativeValue ?? 0;
      cumulativeValue = value + previousCumulativeValue;
    }

    const startDate = new Date(apiMetric.period * 1_000);

    metrics.push({
      startDate,
      label: getLabel(startDate, duration),
      value,
      cumulativeValue,
    });
  });

  return {
    groupId: response.groupId,
    startDate: date,
    duration,
    maxActiveBotsCount:
      maxBy(response.data, (apiMetric) => apiMetric.bot_metrics_count_max)
        ?.bot_metrics_count_max ?? 0,
    totalDeliveriesCount: sumBy(
      response.data,
      (apiMetric) => apiMetric.delivery_times_count_sum
    ),
    totalPicksCount: sumBy(
      response.data,
      (apiMetric) => apiMetric.pick_times_count_sum
    ),
    totalScratchesCount: sumBy(
      response.data,
      (apiMetric) => apiMetric.total_scratches_count_sum
    ),
    totalOnboardHaltsCount: sumBy(
      response.data,
      (apiMetric) => apiMetric.total_onboard_halts_count_sum
    ),
    xAxisLabel: response.period,
    chartTicks: calculateTicks(duration, metrics),
    periods: metrics,
  };
}

function getLabel(periodStart: Date, duration: ReportDuration): string {
  return createReportHelper(duration).formatBinLabel(periodStart);
}

function calculateTicks(
  duration: ReportDuration,
  periods: SiteMetrics["periods"]
): string[] {
  switch (duration) {
    case "month": {
      return periods.map((period) => period.label);
    }
    case "week": {
      return periods.map((period) => period.label);
    }
    case "day": {
      return periods.flatMap((period, index) => {
        if (index % 4 === 0) {
          return period.label;
        } else {
          return [];
        }
      });
    }
    case "hour": {
      return periods.flatMap((period, index) => {
        if (index % 5 === 0) {
          return period.label;
        } else {
          return [];
        }
      });
    }
  }
}
