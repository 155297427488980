/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";

/**
 *
 * @export
 * @interface MessageTypeUpdateRequest
 */
export interface MessageTypeUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof MessageTypeUpdateRequest
   */
  groupId?: string;
  /**
   *
   * @type {string}
   * @memberof MessageTypeUpdateRequest
   */
  note?: string;
  /**
   *
   * @type {object}
   * @memberof MessageTypeUpdateRequest
   */
  context?: object;
  /**
   *
   * @type {Date}
   * @memberof MessageTypeUpdateRequest
   */
  deletedAt?: Date;
}

export function MessageTypeUpdateRequestFromJSON(
  json: any
): MessageTypeUpdateRequest {
  return MessageTypeUpdateRequestFromJSONTyped(json, false);
}

export function MessageTypeUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MessageTypeUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    note: !exists(json, "note") ? undefined : json["note"],
    context: !exists(json, "context") ? undefined : json["context"],
    deletedAt: !exists(json, "deleted_at")
      ? undefined
      : new Date(json["deleted_at"]),
  };
}

export function MessageTypeUpdateRequestToJSON(
  value?: MessageTypeUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    group_id: value.groupId,
    note: value.note,
    context: value.context,
    deleted_at:
      value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
  };
}
