import type { UseQueryOptions } from "@tanstack/react-query";
import { map } from "lodash";

export interface ListResponse<TData> {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number;
  data: TData[];
}

export function mergeEnabledOption(
  options: UseQueryOptions<any, any, any, any> | undefined,
  enabledOverride: boolean
): boolean {
  return enabledOverride && (options?.enabled ?? true);
}

export async function circumventPagination<
  TRequest extends { limit?: number | undefined; offset?: number | undefined },
  TData
>(
  listItems: (
    request: TRequest,
    init: RequestInit
  ) => Promise<ListResponse<TData>>,
  maxLimit: number,
  request: TRequest,
  init: RequestInit
): Promise<ListResponse<TData>> {
  const firstPageRequest = { ...request, limit: maxLimit, offset: 0 };
  const firstPageResponse = await listItems(firstPageRequest, init);

  if (firstPageResponse.count <= firstPageResponse.limit) {
    // No additional pages to fetch
    return {
      ...firstPageResponse,
      limit: -1,
    };
  }

  // More than `maxLimit` items exist for this request so the remaining
  // pages need to be fetched. Now that the exact count is known they
  // can be fetched in parallel
  const remainingPagesCount = Math.ceil(
    (firstPageResponse.count - firstPageRequest.limit) / firstPageRequest.limit
  );
  const remainingPageRequests: Array<typeof firstPageRequest> = [];
  for (let page = 1; page <= remainingPagesCount; page++) {
    remainingPageRequests.push({
      ...firstPageRequest,
      offset: firstPageRequest.limit * page,
    });
  }

  const remainingPageResponses = await Promise.all(
    remainingPageRequests.map((pageRequest) => listItems(pageRequest, init))
  );

  return {
    ...firstPageResponse,
    limit: -1,
    data: firstPageResponse.data.concat(...map(remainingPageResponses, "data")),
  };
}
