import { SidebarSwitch } from "../../../../../../components/Layout";
import SettingsDrawer from "../../../../../../components/SettingsDrawer";
import { usePlaybackSource } from "../../../../PlaybackProvider";
import { usePlayerTopics } from "../../../../hooks";
import { usePanelLayoutContext } from "../../../../panels";
import ExtractionDrawer, {
  useDraftExtraction,
  useExtractionFinalizer,
} from "./ExtractionDrawer";
import LogDrawer from "./LogDrawer";
import PlayerSettings from "./PlayerSettings";

export default function PlayerSidebar() {
  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const { layout } = usePanelLayoutContext();

  const draftExtraction = useDraftExtraction({
    playerTopics: topicsQuery.data,
    playerRange: playbackSource.rangeMs,
    layout,
  });

  const { createExtraction, form, onSubmit } = useExtractionFinalizer(
    draftExtraction.topics
  );

  return (
    <SidebarSwitch
      config={[
        { id: "logs", element: <LogDrawer /> },
        {
          id: "extractions",
          element: (
            <ExtractionDrawer
              draftExtraction={draftExtraction}
              createExtraction={createExtraction}
              form={form}
              onSubmit={onSubmit}
            />
          ),
        },
        {
          id: "settings",
          element: (
            <SettingsDrawer>
              <PlayerSettings />
            </SettingsDrawer>
          ),
        },
      ]}
    />
  );
}
