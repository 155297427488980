import { get } from "lodash";
import type { Topic } from "../../../../../services/datastore";

export default function lookupFieldUnit(
  messageTypeName: Topic["messageTypeName"],
  path: string
): string | null {
  return get(rosTopics, `${messageTypeName}.${path}`, null);
}

const SECONDS = "s";
const NANOSECONDS = "ns";

const PIXELS = "px";
const FRAMES_PER_SECOND = "fps";

const DEGREES = "°";

const CELSIUS = "°C";

const METERS = "m";
const METERS_PER_SECOND_SQUARED = "m/s²";
const RADIANS_PER_SECOND = "rad/sec";

const TESLA = "T";

const header = {
  stamp: {
    secs: SECONDS,
    nsecs: NANOSECONDS,
  },
};

const rosTopics = {
  "sensor_msgs/Image": {
    height: PIXELS,
    width: PIXELS,
    header,
  },
  "sensor_msgs/CameraInfo": {
    height: PIXELS,
    width: PIXELS,
    roi: {
      x_offset: PIXELS,
      y_offset: PIXELS,
      height: PIXELS,
      width: PIXELS,
    },
    header,
  },
  "dynamic_reconfigure/Config": {
    header,
  },
  "dynamic_reconfigure/ConfigDescription": {
    header,
  },
  "bond/Status": {
    heartbeat_timeout: SECONDS,
    heartbeat_period: SECONDS,
    header,
  },
  "std_msgs/String": {
    header,
  },
  "multisense_ros/Histogram": {
    height: PIXELS,
    width: PIXELS,
    fps: FRAMES_PER_SECOND,
    exposure_time: SECONDS,
    header,
  },
  "sensor_msgs/Imu": {
    linear_acceleration: {
      x: METERS_PER_SECOND_SQUARED,
      y: METERS_PER_SECOND_SQUARED,
      z: METERS_PER_SECOND_SQUARED,
    },
    angular_velocity: {
      x: RADIANS_PER_SECOND,
      y: RADIANS_PER_SECOND,
      z: RADIANS_PER_SECOND,
    },
    header,
  },
  "geometry_msgs/QuaternionStamped": {
    header,
  },
  "racer_msg/CanFrame": {
    header,
  },
  "sensor_msgs/NavSatFix": {
    latitude: DEGREES,
    longitude: DEGREES,
    altitude: METERS,
    header,
  },
  "sensor_msgs/MagneticField": {
    magnetic_field: TESLA,
    header,
  },
  "sensor_msgs/Temperature": {
    temperature: CELSIUS,
    header,
  },
  "geometry_msgs/Vector3Stamped": {
    header,
  },
  "sensor_msgs/TimeReference": {
    header,
  },
  "diagnostic_msgs/DiagnosticArray": {
    header,
  },
  "tf2_msgs/TFMessage": {
    header,
  },
  "rosgraph_msgs/Log": {
    header,
  },
  "diagnostic_msgs/DiagnosticStatus": {
    header,
  },
  "racer_msg/PowerStatusArray": {
    header,
  },
  "racer_msg/PpsStatus": {
    header,
  },
  "nav_msgs/Odometry": {
    header,
  },
  "dvs_msgs/EventArray": {
    header,
  },
  "sensor_msgs/PointCloud2": {
    header,
  },
  "std_msgs/Time": {
    header,
  },
  "velodyne_msgs/VelodyneScan": {
    header,
  },
  "racer_msg/ChronySourceStatus": {
    header,
  },
  "racer_msg/RacerLogStats": {
    header,
  },
  "racer_msg/VehicleSteer": {
    header,
  },
  "racer_msg/VehicleSpeed": {
    header,
  },
  "phoenix_vpp_interface/VehicleState": {
    header,
  },
  "can_msgs/Frame": {
    header,
  },
  "racer_msg/VehicleBrake": {
    header,
  },
};
