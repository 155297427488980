/* tslint:disable */

/* eslint-disable */

/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from "../runtime";
import {
  LogFormat,
  LogFormatFromJSON,
  LogFormatFromJSONTyped,
  LogFormatToJSON,
} from "./LogFormat";

/**
 *
 * @export
 * @interface Record
 */
export interface Record {
  /**
   *
   * @type {number}
   * @memberof Record
   */
  timestamp: number;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  topicId: string;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  logId: string;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  messageTypeId: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  ingestionId: string | null;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  length: number;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  dataOffset: number;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  dataLength: number;
  /**
   *
   * @type {boolean}
   * @memberof Record
   */
  errored: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Record
   */
  archived: boolean;
  /**
   *
   * @type {any}
   * @memberof Record
   */
  error: any | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  note: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  chunkCompression: string | null;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  chunkOffset: number | null;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  chunkLength: number | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  s3Bucket: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  s3Key: string | null;
  /**
   *
   * @type {LogFormat}
   * @memberof Record
   */
  format: LogFormat | null;
  /**
   *
   * @type {any}
   * @memberof Record
   */
  messageData: any | null;
  /**
   *
   * @type {any}
   * @memberof Record
   */
  context: any | null;
  /**
   *
   * @type {number}
   * @memberof Record
   */
  nanosecond: number | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  imageUrl: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  bytesUrl: string | null;
  /**
   *
   * @type {Date}
   * @memberof Record
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Record
   */
  updatedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Record
   */
  deletedAt: Date | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  createdBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  updatedBy: string | null;
  /**
   *
   * @type {string}
   * @memberof Record
   */
  deletedBy: string | null;
}

export function RecordFromJSON(json: any): Record {
  return RecordFromJSONTyped(json, false);
}

export function RecordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Record {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    timestamp: json["timestamp"],
    topicId: json["topic_id"],
    logId: json["log_id"],
    messageTypeId: json["message_type_id"],
    ingestionId: json["ingestion_id"],
    offset: json["offset"],
    length: json["length"],
    dataOffset: json["data_offset"],
    dataLength: json["data_length"],
    errored: json["errored"],
    archived: json["archived"],
    error: json["error"],
    note: json["note"],
    chunkCompression: json["chunk_compression"],
    chunkOffset: json["chunk_offset"],
    chunkLength: json["chunk_length"],
    s3Bucket: json["s3_bucket"],
    s3Key: json["s3_key"],
    format: LogFormatFromJSON(json["format"]),
    messageData: json["message_data"],
    context: json["context"],
    nanosecond: json["nanosecond"],
    imageUrl: json["image_url"],
    bytesUrl: json["bytes_url"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
  };
}

export function RecordToJSON(value?: Record | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    timestamp: value.timestamp,
    topic_id: value.topicId,
    log_id: value.logId,
    message_type_id: value.messageTypeId,
    ingestion_id: value.ingestionId,
    offset: value.offset,
    length: value.length,
    data_offset: value.dataOffset,
    data_length: value.dataLength,
    errored: value.errored,
    archived: value.archived,
    error: value.error,
    note: value.note,
    chunk_compression: value.chunkCompression,
    chunk_offset: value.chunkOffset,
    chunk_length: value.chunkLength,
    s3_bucket: value.s3Bucket,
    s3_key: value.s3Key,
    format: LogFormatToJSON(value.format),
    message_data: value.messageData,
    context: value.context,
    nanosecond: value.nanosecond,
    image_url: value.imageUrl,
    bytes_url: value.bytesUrl,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
  };
}
