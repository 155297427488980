import React from "react";
import type { AutocompleteProps } from "@mui/material";
import { Autocomplete, TextField } from "@mui/material";
import { useGroups } from "../queries";
import type { Group } from "../services/datastore";

export interface GroupSelectProps
  extends Pick<
    AutocompleteProps<Group, boolean, false, false>,
    "fullWidth" | "sx"
  > {
  label: string;
  value: Group["id"];
  onChange: (groupId: Group["id"]) => void;
  disableClearable?: boolean;
  loading?: boolean;
}

export default function GroupSelect({
  label,
  value,
  onChange,
  disableClearable,
  loading,
  ...props
}: GroupSelectProps) {
  const groupsQuery = useGroups(
    {
      limit: -1,
      sort: "asc",
      order: "name",
    },
    {
      select(response) {
        return response.data.map((group) => ({
          id: group.id,
          name: group.name,
        }));
      },
    }
  );

  return (
    <Autocomplete
      value={groupsQuery.data?.find((group) => group.id === value) ?? null}
      onChange={(e, group) => onChange(group?.id ?? "")}
      disableClearable={disableClearable}
      options={groupsQuery.data ?? []}
      getOptionLabel={(group) => group.name}
      loading={groupsQuery.isLoading || loading}
      renderInput={(props) => <TextField {...props} label={label} />}
      {...props}
    />
  );
}
